.dashboard{
  width: 100%;
  height: 100vh;
  *{
    font-family: 'Roboto', sans-serif;
  }
  .dashboard_menu{
    width: 250px;
    height: 100vh;
    background-color: #F9F9F9;
    padding: 0 20px;
    position: fixed;
    top: 0;
    transition: all 0.2s;
    z-index: 300;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 0px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 0px;
    }
    .logo{
      margin: 0 auto;
      display: block;
      width: fit-content;
      padding: 45px 0;
      img{
        width: 120px;
      }
    }
    .links{
      margin: 0;
      padding: 0;
      min-height: calc(100% - 380px);
      padding-bottom: 25px;
      li{
        &:not(:last-of-type){
          margin-bottom: 7px;
        }
        a{
          color: #99A399;
          
          font-size: 15px;
          font-weight: 400;
          user-select: none;
          display: flex;
          width: 100%;
          padding: 11px 15px;
          border-radius: 10px;
          cursor: pointer;
          transition: all 0.2s;
          &:hover{
            color: #5CC159;
            svg path{
              fill: #5CC159;
            }
          }
          svg{
            width: 20px;
            height: 20px;
            margin-right: 15px;
            path{
              transition: all .2s;
            }
          }
          &.active{
            background: #5CC159;
            color: #FFF;
            svg path{
              fill: #FFF;
            }
          }
        }
      }
    }
    .lst_prt{
      width: 100%;
      padding: 30px 20px;
      .social{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px 0;
        max-width: 220px;
        margin: 0 auto;
        width: 100%;
        a{
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.2s;
          border-radius: 14.917px;
          background: rgb(92 193 89 / 18%);
          backdrop-filter: blur(10.654827117919922px);
          margin: 0 auto;
          &:hover{
              background-color: #2AC533;
              svg path{
                fill: #FFF;
              }
          }
          svg{
              width: 20px;
              height: 20px;
              path{
                fill: #5CC159;
                transition: all .2s;
              }
          }
        }
      }
      h3{
        text-align: center;
        margin: 0;
        margin-top: 20px;
        color: #99A399;
        
        font-size: 15px;
        font-weight: 400;
        margin-top: 35px;
      }
    }
    .close{
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
      display: none;
      &:hover svg path{
        stroke: #2AC533;
      }
      svg{
        width: 27px;
        height: 27px;
        path{
          transition: all .2s;
        }
        
      }
    }
  }
  .dashboard_content-section{
    width: calc(100% - 250px);
    float: right;
    transition: all .2s;
    .dash_nav{
      width: 100%;
      height: 100px;
      padding: 25px 40px;
      .lef-sec{
        float: left;
        display: flex;
        .open_menu{
          cursor: pointer;
          height: fit-content;
          margin: 7px 0;
          margin-right: 15px;
          display: none;
          &:hover svg path{
            fill: #2AC533;
          }
          svg{
            width: 30px;
            height: 31px;
            path{
              transition: all .2s;
            }
          }
        }
        .welcome{
          h2{
            color: #000;
            font-weight: 500;
            font-size: 20px;
            margin-bottom: 5px;
          }
          p{
            margin: 0;
            color: #99A399;
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-weight: 400;
          }
        }
      }
      .righ_sec{
        float: right;
        display: flex;
        .notification{
          padding-right: 30px;
          position: relative;
          &:after{
            content: "";
            position: absolute;
            right: 0;
            height: 85%;
            width: 1px;
            background: #d6e1d6b3;
            top: 50%;
            transform: translateY(-50%);
          }
          .top_prt{
            border-radius: 105.556px;
            border: 1.056px solid #D6E1D6;
            background: #FFF;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            cursor: pointer;
            position: relative;
            transition: all .2s;
            &:hover{
              border-color: #5CC159;
            }
            &:after{
              content: "";
              position: absolute;
              top: 12px;
              right: 15px;
              width: 9px;
              height: 9px;
              background: #5CC159;
              border-radius: 50%;
              border: 1px solid #FFF;
              display: none;
            }
            svg{
              width: 24px;
              height: 24px;
              path{
                transition: all .2s;
              }
            }
            &.notif::after{
              display: block;
            }
          }
        }
        .user-profile{
          position: relative;
          user-select: none;
          &.active{
            .top_prof{
              .use_info h3{
                color: #2AC533;
              }
              .down_icon path{
                fill:#2AC533;
              }
            }
            .down_prof{
              opacity:1;
              visibility: visible;
              transform: translateY(0%);
            }
          }
          .top_prof{
            display: flex;
            margin-left: 30px;
            cursor: pointer;
            position: relative;
            padding-right: 25px;
            &:after{
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 10;
            }
            &:hover{
              .use_info h3{
                color: #2AC533;
              }
              .down_icon path{
                fill:#2AC533;
              }
            }
            img{
              width: 50px;
              height: 50px;
              border-radius: 50%;
            }
            .use_info{
              margin-left: 10px;
              padding-top: 4px;
              h3{
                color: #000;
                
                font-size: 16px;
                font-weight: 600;
                margin: 0;
                transition: all .2s;
              }
              p{
                color: #99A399;
                
                font-size: 13px;
                font-weight: 400;
                margin: 0;
                margin-top: 2px;
              }
            }
            .down_icon{
              position: absolute;
              right: 0;
              top: 5px;
              width: 19px;
              height: 20px;
              transform: rotate(180deg);
              path{
                transition: all .2s;
              }
            }
          }
          .down_prof{
            position: absolute;
            border-radius: 11.774px;
            border: 2.355px solid #DAE7DA;
            background: #FFF;
            box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.1);
            width: 260px;
            z-index: 9999;
            top: 60px;
            padding: 20px 15px;
            opacity: 0;
            visibility: hidden;
            transform: translateY(-10%);
            transition: all 0.3s ease;
            right: 0;
            .user_info{
              display: flex;
              flex-wrap: wrap;
              img{
                margin: 0 auto;
                width: 50px;
                height: 50px;
                border-radius: 50%;
              }
              .use_info{
                width: 100%;
                margin-top: 11px;
                text-align: center;
                h3{
                  color: #000;
                  
                  font-size: 17px;
                  font-weight: 600;
                  margin: 0;
                  transition: all 0.2s;
                }
                p{
                  color: #5CC159;
                  
                  font-size: 15px;
                  font-weight: 400;
                  margin: 0;
                  margin-top: 2px;
                }
              }
            }
            .mode_btns{
              width: 100%;
              border-radius: 7.612px;
              border: 1.522px solid #DAE7DA;
              background: #FFF;
              display: flex;
              padding: 3px;
              margin-top: 20px;
              button{
                border-radius: 3.558px;
                outline: none;
                border: 0;
                width: 50%;
                color: #99A399;
                text-align: center;
                background: none;
                
                font-size: 12px;
                font-weight: 400;
                padding: 6px 0;
                transition: all .2s;
                svg{
                  width: 16px;
                  height: 16px;
                  margin-right: 3px;
                  position: relative;
                  top: -1px;
                  path{
                    fill: #99A399;
                    transition: all .2s;
                  }
                }
                &:hover{
                  color: #5CC159;
                  svg path{
                    fill: #5CC159;
                  }
                }
                &.active{
                  color: #FFF;
                  background-color: #5CC159;
                  svg path{
                    fill: #FFF;
                  }
                }
              }
            }
            .links{
              padding: 0;
              margin: 0;
              margin-top: 25px;
              li{
                width: 100%;
                &:not(:last-of-type){
                  margin-bottom: 15px;
                }
                &:last-of-type a{
                  color: #E72E4F;
                }
                a{
                  color: #99A399;
                  
                  font-size: 15px;
                  font-weight: 400;
                  display: flex;
                  width: 100%;
                  transition: all 0.2s;
                  cursor: pointer;
                  &:hover{
                    color: #5CC159;
                    svg path{
                      fill: #5CC159;
                    }
                  }
                  svg{
                    width: 20px;
                    height: 20px;
                    margin-right: 6px;
                    position: relative;
                    top: 1px;
                    path{
                      transition: all .2s;
                    }
                  }
                }
                .lang{
                  .top{
                    color: #99A399;
                    
                    font-size: 15px;
                    font-weight: 400;
                    display: flex;
                    width: 100%;
                    transition: all 0.2s;
                    cursor: pointer;
                    &:hover{
                      color: #5CC159;
                      svg path{
                        fill: #5CC159;
                      }
                    }
                    svg{
                      width: 20px;
                      height: 20px;
                      margin-right: 6px;
                      position: relative;
                      top: 1px;
                      path{
                        transition: all .2s;
                      }
                    }
                    .arrow{
                      width: 15px;
                      height: 14px;
                      position: relative;
                      top: 4px;
                      margin-left: 7px;
                    }
                  }
                  .down_list{
                    padding: 7px 0 7px 3px;
                    transition: all .2s;
                    display: none;
                    &.active{
                      display: block;
                    }
                    p{
                      color: #000;
                      
                      font-size: 14px;
                      font-weight: 600;
                      width: 100%;
                      transition: all 0.2s;
                      cursor: pointer;
                      margin: 0;
                      &:hover{
                        color: #5CC159;
                      }
                    }
                    img{
                      width: 17px;
                      margin-top: -3px;
                      margin-right: 5px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .pages_content{
      padding: 40px;
    }
  }
  .select_filter{
    border-radius: 7px;
    border: 1px solid #99a39994;
    color: #99A399;
    font-size: 13px;
    font-weight: 400;
    height: 38px;
    position: relative;
    z-index: 60;
    outline: none;
    margin-left: 10px;
    .MuiOutlinedInput-notchedOutline{
      border: unset;
    }
    .MuiSelect-select{
      padding-right: 25px !important;
      padding-left: 10px !important;
    }
    svg{
      fill: #99A399;
    }
  }
  .filter{
    .MuiSelect-select{
      width: 90px;
      padding-bottom: 15px !important;
    }
  }
  .MuiSelect-select{
    padding-right: 30px !important;
    padding-left: 10px !important;
  }
  .pop-up{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.30);
    z-index: 365;
    transition: all .3s ease;
    padding: 25px 20px;
    .pop-up-content{
      border-radius: 6px;
      border: 1px solid #DAE7DA;
      background: #FFF;
      padding: 50px 30px 40px;
      overflow: hidden;
      overflow-y: auto;
      max-height: 100%;
      overflow-x: auto;
      margin: 0 auto;
      display: block;
      position: relative;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background: #EEE;
        border-radius: 20px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #5CC159;
        border-radius: 20px;
      }
      .pop-up-title{
        margin-bottom: 40px;
        h1{
          color: #000;
          font-size: 24px;
          font-weight: 500;
          margin: 0;
        }
        .close{
          position: absolute;
          top: 20px;
          right: 25px;
          cursor: pointer;
          &:hover svg path{
            stroke: #2AC533;
          }
          svg{
            width: 28px;
            height: 27px;
            path{
              transition: all .2s;
            }
            
          }
        }
      }
    }
    &.active{
      visibility: visible;
      opacity: 1;
    }
  }
  .wrong-input{
    position: relative;
    border-radius: 5px;
    border: 1.25px solid #E72E4F;
    background: #FFF8F9;
    display: flex;
    padding: 6px 10px 6px 10px;
    margin-top: 10px;
    p{
      margin: 0;
      color: #E72E4F;
      
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      padding: 2px 0;
      margin-bottom: 0;
      margin-left: 6px;
    }
    svg{
      width: 23px;
      height: 22px;
    }
  }
  .congratulations-pop-up,.problem-pop-up{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #eeeeee36;
    transition: all 0.3s;
    z-index: 9999999999;
    .content{
      width: 570px;
      border-radius: 6px;
      background: #FFF;
      box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.1098039216);
      padding: 50px 60px 60px;
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-wrap: wrap;
      svg{
        margin: 0 auto;
        display: block;
        width: 100px;
      }
      h1{
        color: #000;
        font-size: 23px;
        font-weight: 600;
        width: 100%;
        text-align: center;
        margin-top: 10px;
      }
      p{
        color: #99A399;
        font-size: 14px;
        font-weight: 500;
        margin-top: 0;
        width: 100%;
        text-align: center;
      }
      button, a{
        font-size: 15px;
        font-weight: 400;
        user-select: none;
        padding: 9px 14px;
        cursor: pointer;
        text-align: center;
        color: #FFF;
        border: 0;
        transition: all 0.2s;
        position: relative;
        border-radius: 5px;
        background: #5CC159;
        width: 310px;
        margin: 0 auto;
        display: block;
        margin-top: 35px;
        &:hover{
          background-color: #2AC533;
        }
      }
      .close{
        position: absolute;
        top: 20px;
        right: 25px;
        cursor: pointer;
        &:hover svg path{
          stroke: #2AC533;
        }
        svg{
          width: 28px;
          height: 27px;
          path{
            transition: all .2s;
          }
          
        }
      }
    }
  }
  .problem-pop-up .btns{
    display: flex;
    margin-top: 35px;
    grid-gap: 10px;
    width: 100%;
    button, a{
      margin: 0 !important;
      &:last-of-type{
        border: 2px solid #E72E4F;
        background: #FFF;
        color: #E72E4F;
        &:hover{
          background-color: #E72E4F;
          color: #FFF;
        }
      }
      &:first-of-type{
        background: #5CC159;
        &:hover{
          background-color: #2AC533;
        }
      }
    }
  }
  .empty-table{
    min-height: 250px;
    width: 100%;
    padding-top: 30px !important;
    padding-bottom: 40px !important;
    p{
      margin: 0;
      color: #99A399;
      font-size: 15px !important;
      font-weight: 400;
      text-transform: initial;
      text-align: center;
      padding: 0;
    }
    a{
      font-size: 13px;
      font-weight: 400;
      user-select: none;
      padding: 8px 20px;
      cursor: pointer;
      border-radius: 5.015px;
      background: #5CC159;
      text-align: center;
      color: #FFF;
      border: 0;
      transition: all 0.2s;
      position: relative;
      display: flex;
      width: fit-content;
      margin: 0 auto;
      margin-top: 10px;
      &:hover{
        background-color: #2AC533;
        color: #FFF;
      }
    }
  }
  input, textarea{
    &::placeholder{
      color: #99A399;
    }
  }
  .title{
    margin-bottom: 45px;
    h1{
      color: #000;
      
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 25px;
    }
    .links{
      display: flex;
      margin: 0;
      li{
        margin-right: 10px;
        a{
          color: #000;
          font-size: 15px;
          font-weight: 500;
          cursor: pointer;
          padding: 0px 10px 10px;
          transition: all .2s;
          border-bottom: 2px solid transparent;
          &.active, &.default-active{
            color: #2AC533;
            border-bottom-color: #2AC533;
          }
          &:hover{
            color: #2AC533;
          }
        }
      }
    }
  }
  .table-bor{
    margin-top: 35px;
    margin-bottom: 0;
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      background: #EEE;
      border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #5CC159;
      border-radius: 20px;
    }
    .table{
      margin-bottom: 0;
      min-width: 850px;
      thead tr{
        overflow: hidden;
        border-radius: 15px;
        th{
          background: #EEF4EE;
          color: #5CC159;
          font-size: 13px;
          font-weight: 400;
          font-family: 'Poppins';
          padding-top: 12px;
          padding-bottom: 12px;
          border: 0;
          text-align: center;
          &:first-of-type{
            border-radius: 8px 0 0 8px;
          }
          &:last-of-type{
            border-radius: 0 8px 8px 0;
          }
        }
      }
      tbody tr{
        &:first-of-type td{
          padding-top: 30px;
        }
        td{
          color: #000;
          font-family: 'Poppins';
          font-size: 14px;
          font-weight: 500;
          border: 0;
          padding-top: 15px;
          text-align: center;
          padding-bottom: 15px;
          p{
            margin: 0;
            font-family: 'Poppins';
            font-size: 12px;
            font-weight: 400;
            padding: 5px 2px;
            border-radius: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            top: -2px;
            &.sell{
              background: rgba(231, 46, 79, 0.15);
              color: #E72E4F;
            }
            &.buy, &.signal-origin{
              color: #5CC159;
              background: rgba(92, 193, 89, 0.15);
            }
          }
        }
      }
    }
  }
  .done, .error{
    position: relative;
    border-radius: 6px;
    border: 1.25px solid #5CC159;
    background: #ECFAEC;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    padding: 6px 45px 6px 35px;
    width: -moz-fit-content;
    width: fit-content;
    width: 100%;
    margin: 0 0 10px 0;
    p{
      margin: 0;
      color: #5CC159;
      font-size: 14px;
      font-weight: 500;
      padding: 6px 0;
      margin-bottom: 0;
      margin-left: 10px;
      position: relative;
      top: 1px;
    }
    svg{
      width: 26px;
      position: absolute;
      top: 8px;
      left: 10px;
      height: 26px;
    }
    .close_alert{
      position: absolute;
      top: 50%;
      right: 10px !important;
      cursor: pointer;
      transform: translateY(-50%);
      svg{
        position: unset;
        width: 20px !important;
        height: 20px !important;
      }
    }
    &.error{
      border: 1.25px solid #E72E4F;
      background: rgba(231, 46, 79, 0.15);
      position: relative !important;
      p{
        color: #E72E4F;
        font-size: 14px;
      }
    }
    &.done{
      border: 1.25px solid #5CC159;
      background: rgb(92 193 89 / 15%);
      width: fit-content;
      p{
        color: #5CC159;
        font-size: 14px;
      }
    }
  }
  form{
    max-width: unset;
  }
  @media (max-width: 1400px){
    .dashboard_menu{
      left: -250px;
      box-shadow: 0 0 4px 3px #00000029;
      .close{
        display: block;
      }
      &.active{
        left: 0;
      }
    }
    .dashboard_content-section{
      width: 100%;
      .dash_nav{
        padding: 25px 20px;
        .lef-sec .open_menu{
          display: block;
        }
      } 
      .pages_content {
        padding: 10px 20px 40px !important;
      }
    }
  }
  @media (max-width: 767px){
    .dashboard_menu{
      width: 100%;
      left: -100%;
      transition: all .4s;
      &.active{
        left: 0;
      }
    }
    .congratulations-pop-up,.problem-pop-up{
      padding: 20px;
      padding-top: 80px;
      .content{
        position: unset;
        transform: translate(0);
        padding: 50px 20px 60px;
        margin: 0 auto;
        width: 100%;
        button{
          width: 100%;
        }
      }
      
    }
    .problem-pop-up .btns{
      display: flex;
      flex-wrap: wrap;
      button, a{
        width: 100% !important;
        margin: 0 !important;
      }
    }
    .dashboard_content-section .dash_nav{
      .lef-sec .welcome {
        padding: 3px 0;
        h2 {
          font-size: 16px;
          margin-bottom: 3px;
        }
        p{
          font-size: 12px;
        }
      }
      .righ_sec{
        .notification {
          padding-right: 20px;
        }
        .user-profile{
          .top_prof{
            padding-right: 0;
            margin-left: 20px;
            .use_info, svg{
              display: none;
            }
          }
          .down_prof{
            width: 240px;
            right: 0;
          }
        } 
      } 
    }
    .pop-up{
      padding: 20px;
      .pop-up-content{
        width: 100% !important;
      }
    } 
  }
}
// Home
.dashboard-home{
  .cards{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    .card{
      padding: 10px;
      border-radius: 30px;
      background: #2B523D;
      position: relative;
      padding: 35px 25px;
      overflow: hidden;
      img{
        width: 200px;
        height: 200px;
        position: absolute;
        right: -75px;
        top: -75px;
        z-index: 3;
      }
      .top_sec{
        position: relative;
        z-index: 60;
        .card_head{
          display: flex;
          float: left;
          .icon{
            border-radius: 10px;
            background: #FFF;
            width: 45px;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            svg{
              width: 27px;
              height: 27px;
            }
          }
          .sec_title{
            margin-left: 10px;
            padding-top: 2px;
            h4{
              color: #FFF;
              
              font-size: 15px;
              font-weight: 400;
              margin: 0;
            }
            p{
              color: #FFF;
              
              font-size: 12px;
              font-weight: 300;
              margin: 0;
              margin-top: 2px;
            }
          }
        }
        .select_card {
          float: right;
          border-radius: 7px;
          border: 1px solid #FFF;
          color: #FFF;
          font-size: 11px;
          margin: 0;
          font-weight: 400;
          height: 31px;
          outline: none;
          .MuiOutlinedInput-notchedOutline{
            border: unset;
            width: 45px;
          }
          svg{
            fill: #FFF;
            width: 18px;
          }
          .MuiSelect-select{
            width: 45px;
            padding-bottom: 15px !important;
          }
        }
      }
      .btm_sec{
        margin-top: 40px;
        width: 100%;
        overflow: hidden;
        .num{
          float: left;
          color: #FFF;
          
          width: fit-content;
          font-size: 22px;
          font-weight: 500;
          margin: 1px 0;
        }
        .new_acc{
          overflow: hidden;
          .btm_connect{
            float: right;
            border-radius: 5px;
            border: 1px solid #FFF;
            color: #FFF;
            text-align: center;
            
            font-size: 14px;
            font-weight: 400;
            padding: 8px 0;
            transition: all 0.2s;
            cursor: pointer;
            width: 230px;
            &:hover{
              border-color: #2AC533;
              color: #2AC533;
              svg path{
                stroke: #2AC533;
              }
            }
            svg{
                width: 20px;
                height: 18px;
                margin-left: 8px;
                position: relative;
                path{
                    transition: all .2s;
                }
            }
          }
        }
        .statistics{
          overflow: hidden;
          width: 100%;
          .num span{
            color: #FFF;
            
            font-size: 15px;
            font-weight: 500;
            margin: 0 0 0 15px;
            position: relative;
            top: -2px;
          }
          svg{
            float: right;
            width: 115px;
          }
        }
      }
      //TODO: HIDE THIS CODE AND RESET IT ONCE WE HAVE THREE CARDS AGAIN
      // &:nth-of-type(2),&:last-of-type{
      //   position: relative;
      //   overflow: hidden;
      //   background: #5CC159;
      //   .card-overlay {
      //     position: absolute;
      //     top: 0;
      //     left: 0;
      //     z-index: 200;
      //     bottom: 0;
      //     right: 0;
      //     background: black;
      //     opacity: 0.7;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;
      //     color: white;
      //     font-size: 30px;
      //     font-weight: bold;
      //   }
      //   .btm_sec .new_acc .btm_connect:hover{
      //     border-color: #FFF;
      //     color: #FFF;
      //     svg path{
      //       stroke: #FFF;
      //     }
      //   }
      // }
      // &:last-of-type{
      //   background: #59C195;
      // }
    }
  }
  .trades_progress{
    width: 100%;
    border-radius: 20px;
    border: 2px solid #DAE7DA;
    background: #FFF;
    margin-top: 55px;
    padding: 25px 20px;
    position: relative;
    .title_sec{
      overflow: hidden;
      width: 100%;
      h2{
        color: #000;
        
        font-size: 19px;
        font-weight: 600;
        margin: 0;
        width: 100%;
        text-align: left;
        padding: 6px 0px;
      }
      .total_unrealized{
        color: #000;
        
        font-size: 15px;
        font-weight: 600;
        margin-top: 10px;
        span{
          color: #5CC159;
          font-weight: 600;
          margin-left: 10px;
        }
      }
    }
    .filter{
      position: absolute;
      top: 25px;
      right: 20px;
      .MuiSelect-select{
        width: 90px;
        padding-bottom: 15px !important;
      }
    }
  }
  @media (max-width: 1400px){
    .cards{
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (max-width: 991px){
    .cards{
      grid-template-columns: repeat(1, 1fr);
    }
    .trades_progress{
      .title_sec{
        text-align: center;
        h2 {
          font-size: 19px;
          text-align: center;
        }
        .total_unrealized {
          font-size: 15px;
          margin: 0;
        }
      } 
      .filter{
        position: unset;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 15px;
        .select_filter {
          font-size: 12px;
          height: 35px;
        }
      }
      .trades_table {
        margin-top: 20px;
      }
    }
  }
  @media (max-width: 550px){
    .cards{
      .card{
        padding: 45px 25px 35px;
        .top_sec{
          .card_head{
            float: none;
            width: 100%;
            flex-wrap: wrap;
            .icon{
              margin: 0 auto;
              margin-bottom: 10px;
            }
            .sec_title{
              text-align: center;
              width: 100%;
              margin-left: 0;
              h4{
                font-size: 16px;
              }
            }
          }
          .MuiInputBase-root{
            position: absolute;
            top: -32px;
            right: 0;
          }
        } 
        .btm_sec{
          margin-top: 25px;
          .new_acc, .statistics{
            &.new_acc .num{
              width: 100%;
              float: none;
              text-align: center;
              display: block;
              margin-bottom: 10px;
            }
            .btm_connect{
              margin: 0 auto;
              display: block;
              float: none;
            }
            &.statistics svg{
              width: 100px;
            }
          }
        }
        
      }
    }
  }
}
// Analytics
.dashboard-analytics{
  border-radius: 20px;
  border: 2px solid #DAE7DA;
  background: #FFF;
  width: 100%;
  padding: 35px 25px 45px;
  .title_sec{
    overflow: hidden;
    width: 100%;
    margin-bottom: 20px;
    h2{
      color: #000;
      
      font-size: 21px;
      padding: 6px 0px;
      font-weight: 600;
      margin: 0;
      float: left;
    }
    .filter{
      float: right;
    }
  }
  .top_boxes{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .infos{
      width: 60%;
      border-radius: 20px;
      border: 2px solid #DFE7DA;
      background: #FFF;
      padding: 30px;
      p{
        color: #99A399;
        
        font-size: 15px;
        font-weight: 500;
        margin: 0;
        display: flex;
        &:not(:last-of-type){
          margin-bottom: 15px;
        }
        span:first-of-type{
          width: 200px;
          display: flex;
        }
        span:last-of-type{
          color: #000;
          font-weight: 600;
        }
      }
    }
    .followers_progress{
      border-radius: 20px;
      border: 2px solid #DFE7DA;
      background: #FFF;
      padding: 25px 30px;
      width: 38%;
      .title_sec h2{
        font-size: 17px;
        padding: 9px 0px;
      }
      .recharts-responsive-container{
        height: 200px !important;
        font-size: 12px;
        font-weight: 400;
        line{
          stroke: #FFF !important;
        }
      }
    }
  }
  .stats{
    width: 100%;
    border-radius: 20px;
    border: 2px solid #DFE7DA;
    background: #FFF;
    padding: 20px;
    margin-top: 40px;
    .chart{
      margin-top: 30px;
      .recharts-responsive-container{
        
        font-size: 12px;
        height: 300px !important;
        font-weight: 500;
        .recharts-xAxis,.recharts-yAxis{
          line{
            stroke: #FFF !important;
          }
        }
      }
    }
  }
  .statistics{
    width: 100%;
    border-radius: 20px;
    border: 2px solid #DFE7DA;
    background: #FFF;
    padding: 20px;
    margin-top: 40px;
    // height: 300px;
    .statistics_table{
      overflow-x: auto;
      &::-webkit-scrollbar {
        height: 8px;
      }
      &::-webkit-scrollbar-track {
        background: #EEE;
        border-radius: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #5CC159;
        border-radius: 20px;
      }
      .table{
        margin-bottom: 0;
        min-width: 850px;
        thead tr{
          overflow: hidden;
          border-radius: 15px;
          th{
            color: #99A399;
            
            font-size: 14px;
            font-weight: 400;
            padding-top: 12px;
            padding-bottom: 12px;
            border: 0;
            text-align: left;
          }
        }
        tbody tr{
          td{
            color: #000;
            
            font-size: 14px;
            font-weight: 600;
            border: 0;
            padding-top: 15px;
            text-align: left;
            padding-bottom: 15px;
            font-weight: 600;
            &:first-of-type{
              font-weight: 700;
            }
            span.plus{
              color: #5CC159;
            }
            span.min{
              color: #E72E4F;
            }
          }
        }
      }
    }
  }
  .trades_history{
    width: 100%;
    border-radius: 20px;
    border: 2px solid #DFE7DA;
    background: #FFF;
    padding: 20px;
    margin-top: 40px;
    .trades_history_table{
      margin-top: 35px;
      overflow-x: auto;
      &::-webkit-scrollbar {
        height: 8px;
      }
      &::-webkit-scrollbar-track {
        background: #EEE;
        border-radius: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #5CC159;
        border-radius: 20px;
      }
      .table{
        margin-bottom: 0;
        min-width: 850px;
        thead tr{
          overflow: hidden;
          border-radius: 15px;
          th{
            background: #EEF4EE;
            color: #5CC159;
            
            font-size: 13px;
            font-weight: 400;
            padding-top: 12px;
            padding-bottom: 12px;
            border: 0;
            text-align: center;
            &:first-of-type{
              border-radius: 8px 0 0 8px;
            }
            &:last-of-type{
              border-radius: 0 8px 8px 0;
            }
          }
        }
        tbody tr{
          &:first-of-type td{
            padding-top: 30px;
          }
          td{
            color: #000;
            
            font-size: 14px;
            font-weight: 600;
            border: 0;
            padding-top: 15px;
            text-align: center;
            padding-bottom: 15px;
            p{
              margin: 0;
              font-size: 12px;
              font-weight: 400;
              padding: 5px 2px;
              border-radius: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              top: -2px;
              &.sell,&.status-failed{
                background: rgba(231, 46, 79, 0.15);
                color: #E72E4F;
              }
              &.buy, &.status-success{
                color: #5CC159;
                background: rgba(92, 193, 89, 0.15);
              }
            }
          }
        }
      }
      
    }
  }
  @media (max-width: 991px){
    .top_boxes{
      .infos{
        width: 100%;
        margin-bottom: 25px;
      }
      .followers_progress{
        width: 100%;
      }
    }
    .stats,.statistics, .trades_history{
      margin-top: 25px;
    }
  }
  @media(max-width: 767px){
    .trades_history,.stats, .top_boxes .followers_progress {
      padding: 15px 30px;
      .title_sec{
        margin: 0;
        h2{
          text-align: center;
          width: 100%;
          float: none;
        }
        .filter{
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 7px;
        }
      }
      .trades_history_table {
        margin-top: 25px;
      }
    } 
    .top_boxes .infos p{
      flex-wrap: wrap;
      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
      span:last-of-type {
        width: 100%;
      }
    }
  }
}
// Marketplace
.dashboard-marketplace{
  .filter{
    width: 100%;
    display: flex;
    justify-content: end;
    margin-bottom: 25px;
    padding: 0 12px;
  }
  .top_traders{
    width: 100%;
    &.row{
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    .item{
      border-radius: 11.48px;
      border: 1px solid #DAE7DA;
      background: #FFF;
      padding: 20px 15px;
      position: relative;
      transition: all .2s;
      user-select: none;
      cursor: context-menu;
      margin-bottom: 25px;
      &:hover{
          border-color: #2AC533;
      }
      .picture{
          position: relative;
          width: fit-content;
          margin: 0 auto;
          img{
              width: 65px;
              height: 65px;
          }
          svg{
              width: 26px;
              position: absolute;
              right: -3px;
              bottom: -3px;
          }
      }
      .info{
          margin-top: 10px;
          h1{
              color: #000;
              text-align: center;
              
              font-size: 19px;
              font-weight: 600;
          }
          .nums{
            display: flex;
            width: 100%;
            margin-top: 20px;
            justify-content: space-between;
            // .num{
            //   display: flex;
            //   flex-wrap: wrap;
            //   text-align: center;
            //   width: 50%;
            //   .icon{
            //     border-radius: 10px;
            //     background: #EAF9EA;
            //     display: flex;
            //     width: 35px;
            //     height: 35px;
            //     justify-content: center;
            //     align-items: center;
            //     margin: 0 auto;
            //     margin-bottom: 10px;
            //       svg{
            //         width: 20px;
            //         height: 20px;
            //       }
            //   }
            //   .txt{
            //       width: 100%;
            //       p{
            //         margin: 0;
            //         color: #000;
                    
            //         font-size: 15px;
            //         font-weight: 600;
            //         line-height: 0.9;
            //         padding-top: 0;
            //       }
            //       span{
            //           color: #99A399;
                      
            //           font-size: 13px;
            //           font-weight: 400;
            //       }
            //   }
            // }
            .num{
                display: flex;
                flex-wrap: wrap;
                &:first-of-type{
                    margin-right: 20px;
                }
                .icon{
                  border-radius: 10px;
                  background: #EAF9EA;
                  display: flex;
                  width: 32px;
                  height: 32px;
                  justify-content: center;
                  align-items: center;
                    svg{
                      width: 18px;
                      height: 18px;
                    }
                }
                .txt{
                    margin-left: 5px;
                    position: relative;
                    top: 3px;
                    padding-top: 2px;
                    p{
                      margin: 0;
                      color: #000;
                      font-size: 15px;
                      font-weight: 600;
                      line-height: .5;
                      padding-top: 0;
                    }
                    span{
                      color: #99A399;
                      font-size: 11px;
                      font-weight: 400;
                    }
                }
            }
          }
      }
      .roi{
          position: relative;
          width: 100%;
          margin: 0 auto;
          display: block;
          margin-top: 20px;
          height: 55px;
          .roi_info{
            p{
              color: #99A399;
              
              font-size: 15px;
              font-weight: 400;
              margin: 0;
              text-transform: uppercase;
            }
            h3{
              margin: 0;
              color: #5CC159;
              
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              margin-top: 5px;
            }
          }
          svg{
              width: 220px;
              height: fit-content;
              border-radius: 3px;
              overflow: hidden;
          }
          .recharts-responsive-container{
              height: 55px !important;
              border-radius: 3px;
              width: 125px !important;
              position: absolute;
              right: 0;
              top: 0;
              .recharts-wrapper{
                width: 124px !important;
                height: 55px !important;
                display: flex;
                justify-content: end;
                svg{
                  width: 120px !important;
                }
              }
          }
      }
      button{
        overflow: hidden;
        font-size: 15px;
        font-weight: 500;
        user-select: none;
        width: 100%;
        padding: 9px 15px;
        cursor: pointer;
        border-radius: 5.015px;
        background: #2AC533;
        text-align: center;
        color: #FFF;
        border: 0;
        margin-top: 20px;
        transition: all 0.2s;
        position: relative;
        &:hover{
          background-color: #5CC159;
        }
        &.follow{
          cursor: context-menu;
          &:after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #ffffff7d;
          }
          &:hover{
            background-color: #2AC533;
          }
        }
      }
    }
  }
  .pagination{
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 0 12px;
    button{
      
      font-size: 15px;
      font-weight: 500;
      user-select: none;
      cursor: pointer;
      border-radius: 9px;
      background: #5CC159;
      text-align: center;
      color: #FFF;
      border: 0;
      transition: all 0.2s;
      position: relative;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:not(:last-of-type){
        margin-right: 10px;
      }
      &:hover{
        background-color: #2AC533;
      }
      &.active{
        background: rgba(92, 193, 89, 0.20);
        color: #5CC159;
      }
    }
  }
  .payment-pop-up{
    .pop-up-content{
      width: 900px;
      .payment_content{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        min-height: 576px;
        .prt_one,.prt_two{
          width: 50%;
          position: relative;
          &.prt_one{
            padding-right: 25px;
            border-right: 1px solid #DAE7DA;
          }
          &.prt_two{
            padding-left: 25px;
          }
          .inpt{
            &:not(:last-of-type){
              margin-bottom: 25px;
            }
            label{
              color: #000;
              text-align: left;
              
              font-size: 16px;
              font-weight: 600;
              margin-bottom: 7px;
              display: block;
            }
            .MuiInputBase-root{
              width: 100%;
              margin-left: 0;
            }
            .check_inpt{
              user-select: none;
              display: flex;
              margin-top: 15px;
              p{
                margin: 0;
                &:first-of-type{
                  margin-right: 25px;
                }
                [type="radio"]:checked,
                [type="radio"]:not(:checked) {
                    position: absolute;
                    left: -9999px;
                }
                [type="radio"]:checked + label,
                [type="radio"]:not(:checked) + label
                {
                  position: relative;
                  padding-left: 32px;
                  cursor: pointer;
                  color: #000;
                  
                  font-size: 15px;
                  font-weight: 400;
                  margin: 0;
                }
                [type="radio"]:checked + label:before,
                [type="radio"]:not(:checked) + label:before {
                  content: "";
                  position: absolute;
                  left: 0;
                  top: -2px;
                  width: 25px;
                  height: 25px;
                  background: #FFF;
                  border-radius: 100%;
                  border: 6px solid #EDF5ED;
                }
                [type="radio"]:checked + label:after,
                [type="radio"]:not(:checked) + label:after {
                  content: "";
                  width: 13px;
                  height: 13px;
                  background: #5CC159;
                  position: absolute;
                  top: 4px;
                  left: 6px;
                  border-radius: 100%;
                  transition: all 0.2s ease;
                }
                [type="radio"]:not(:checked) + label:after {
                    opacity: 0;
                    -webkit-transform: scale(0);
                    transform: scale(0);
                }
                [type="radio"]:checked + label:after {
                    opacity: 1;
                    -webkit-transform: scale(1);
                    transform: scale(1);
                }
              }
              
            }
            .check_btns{
              width: 100%;
              border-radius: 7.612px;
              border: 1.522px solid #DAE7DA;
              background: #FFF;
              display: flex;
              padding: 3px;
              margin-top: 10px;
              p{
                margin: 0;
                width: 50%;
                position: relative;
                &:hover label{
                  color: #5CC159;
                }
                input{
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  cursor: pointer;
                  opacity: 0;
                  height: 100%;
                }
                label{
                  width: 100%;
                  padding: 7px 0;
                  color: #99A399;
                  transition: all 0.2s;
                  
                  font-size: 13px;
                  font-weight: 400;
                  border-radius: 5px;
                  outline: none;
                  background: none;
                  margin: 0;
                  text-align: center;
                }
                &:hover{
                  color: #5CC159;
                  svg path{
                    fill: #5CC159;
                  }
                }
                input[type="radio"]:checked + label{
                  color: #FFF;
                  background-color: #5CC159;
                }
              }
            }
            .items{
              .item{
                width: 100%;
                border-radius: 10px;
                border: 2px solid #DAE7DA;
                background: #FFF;
                padding: 15px 65px 15px 15px;
                display: flex;
                position: relative;
                margin-bottom: 15px;
                svg{
                  width: 39px;
                  height: 39px;
                  margin: 4px 0;
                }
                .info{
                  padding-left: 10px;
                  span{
                    color: #99A399;
                    text-align: center;
                    
                    font-size: 14px;
                    font-weight: 400;
                  }
                  p{
                    color: #000;
                    text-align: center;
                    
                    font-size: 16px;
                    font-weight: 600;
                    margin: 0;
                  }
                }
                .options{
                  position: absolute;
                  right: 5px;
                  top: 50%;
                  transform: translateY(-50%);
                  button{
                    border: 0;
                    background: none;
                    outline: none;
                    padding: 0 5px;
                    &:hover svg path{
                      fill: #5CC159;
                    }
                    svg{
                      width: 20px;
                      height: 20px;
                      path{
                        transition: all .2s;
                      }
                    }
                  }
                }
              }
            }
          }
          .amount{
            overflow: hidden;
            p{
              margin: 0;
              &:first-of-type{
                float: left;
                color: #99A399;
                
                font-size: 15px;
                font-weight: 500;
                padding: 5px 0
              }
              &:last-of-type{
                float: right;
                color: #000;
                
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
              }
            }
          }
          .btn_submit{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding-left: 25px;
            .pr_tu{
              margin-top: 10px;
              color: #000;
              
              font-size: 15px;
              font-weight: 500;
              line-height: normal;
              padding-left: 30px;
              position: relative;
              width: fit-content;
              a{
                color: #5CC159;
                text-decoration-line: underline;
                margin-left: 3px;
                cursor: pointer;
                transition: all .2s;
                z-index: 70;
                width: fit-content;
                position: relative;
                &:hover{
                  color: #2AC533;
                }
              }
              .box{
                content: "";
                position: absolute;
                top: 0;
                left: 3px;
                height: 19px;
                width: 19px;
                border-radius: 3.209px;
                border: 2.139px solid #DAE7DA;
                transition: all .2s;
                display: flex;
                justify-content: center;
                align-items: center;
                svg{
                  width: 13px;
                  height: 13px;
                  transition: all .2s;
                  opacity: 0;
                }
              }
              input{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                z-index: 60;
                height: 100%;
                cursor: pointer;
                opacity: 0;
              }
              input[type="checkbox"]:checked + .box{
                border-color: #5CC159;
                svg{
                  opacity: 1;
                }
              }
            }
            button{
              
              font-size: 16px;
              font-weight: 300;
              user-select: none;
              width: 100%;
              padding: 9px 15px;
              cursor: pointer;
              border-radius: 5.015px;
              background: #5CC159;
              text-align: center;
              color: #FFF;
              border: 0;
              margin-top: 15px;
              transition: all 0.2s;
              position: relative;
              &:hover{
                background: #2AC533;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 991px){
    .payment-pop-up .pop-up-content{
      .payment_content{
        .prt_one{
          width: 100%;
          padding: 0 !important;
          margin-bottom: 20px;
          border: 0 !important;
        }
        .prt_two{
          width: 100%;
          padding: 0 !important;
          .btn_submit {
            position: unset;
            width: 100%;
            padding-left: 0;
            margin-top: 25px;
          }
        }
      }
    }
  }
  @media (max-width: 767px){
    .top_traders{
      justify-content: center;
    }
  }
  @media (max-width: 500px){
    .top_traders{
      .item{
        width: 100%;
      }
    }
    .pagination {
      justify-content: center;
    }
  }
}
// Subscription
.dashboard-subscription{
  border-radius: 20px;
  border: 2px solid #DAE7DA;
  background: #FFF;
  width: 100%;
  padding: 25px 25px 45px;
  .title{
    h1{
      color: #000;
      
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 25px;
    }
  }
  .subscription-pricing{
    margin-top: 40px;
    h2{
      text-align: center;
      color: #000;
      
      font-size: 24px;
      font-weight: 500;
    }
    .date_btns{
      max-width: 260px;
      border-radius: 7.612px;
      border: 1.522px solid #DAE7DA;
      background: #FFF;
      display: flex;
      padding: 3px;
      margin: 0 auto;
      margin-top: 20px;
      button{
        border-radius: 3.558px;
        outline: none;
        border: 0;
        width: 50%;
        color: #99A399;
        text-align: center;
        background: none;
        
        font-size: 13px;
        font-weight: 400;
        padding: 6px 0;
        transition: all 0.2s;
        svg{
          width: 16px;
          height: 16px;
          margin-right: 3px;
          position: relative;
          top: -1px;
          path{
            fill: #99A399;
            transition: all .2s;
          }
        }
        &:hover{
          color: #5CC159;
          svg path{
            fill: #5CC159;
          }
        }
        &.active{
          color: #FFF;
          background-color: #5CC159;
          svg path{
            fill: #FFF;
          }
        }
      }
    }
    .plans{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 35px;
      max-width: 1130px;
      margin: 0 auto;
      margin-top: 45px;
      .plan{
          border-radius: 15px;
          border: 2px solid #DAE7DA;
          background: #FFF;
          padding: 25px;
          position: relative;
          overflow: hidden;
          transition: all .2s;
          &:hover{
              border-color: #2AC533;
          }
          &:nth-of-type(2){
              border: 1.142px solid #DAE7DA;
              background: #5CC159;
              color: #FFF;
              .top_sec-plan{
                  h4{
                      color: #FFF;
                  }
                  h5{
                      margin: 0;
                      color: #FFF;
                      
                      font-size: 13px;
                      font-weight: 500;
                      width: fit-content;
                      position: absolute;
                      top: 28px;
                      left: 137px;
                      svg{
                          position: relative;
                          top: -1px;
                          margin-right: 5px;
                      }
                  }
                  h3{
                      color: #FFF;
                      .discount{
                        color: #FFF;
                      }
                      span{
                          color: #FFF;
                      }
                  }
              }
              .list{
                  li{
                      position: relative;
                      .icon{
                          background: rgb(255 255 255 / 33%);
                          svg path{
                              fill: #FFF;
                          }
                      }
                      p{
                          color: #FFF;
                      }
                  }
              }
              a{
                  color: #5CC159;
                  background-color: #FFF;
                  &:hover{
                      background-color: #FFF;
                      color: #5CC159;
                  }
              }
              img{
                  position: absolute;
                  top: -190px;
                  right: -190px;
              }
          }
          .top_sec-plan{
              border-bottom: 2px solid #EBF4EB;
              padding-bottom: 20px;
              h4{
                  color: #99A399;
                  
                  font-size: 16px;
                  font-weight: 500;
              }
              h3{
                  color: #000;
                  
                  font-size: 28px;
                  font-weight: 700;
                  margin-top: 15px;
                  margin-bottom: 0;
                  display: flex;
                  .discount{
                    color: #5CC159;
                    
                    font-size: 18px;
                    font-weight: 500;
                    text-decoration: line-through;
                    margin: 0 8px 0 0;
                    padding: 6px 0;
                  }
                  span{
                    color: #99A399;
                    font-size: 14px;
                    font-weight: 500;
                    margin-left: 5px;
                    position: relative;
                    top: 11px;
                  }
              }
              p{
                  color: #99A399;
                  
                  font-size: 13px;
                  font-weight: 400;
                  margin-top: 10px;
                  margin-bottom: 0;
              }
          }
          .list{
              margin: 0;
              padding: 30px 0 60px;
              width: 100%;
              li{
                  display: flex;
                  margin-bottom: 20px;
                  position: relative;
                  .icon{
                      position: absolute;
                      width: 19px;
                      height: 19px;
                      border-radius: 50%;
                      background: rgba(92, 193, 89, 0.4392156863);
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      top: 1px;
                      left: 0;
                      svg{
                        width: 11px;
                        height: 12px;
                      }
                  }
                  p{
                    margin: 0;
                    
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 1.3;
                    padding-left: 27px;
                  }
              }
          }
          a{
              color: #FFF;
              padding: 9px 0;
              font-size: 15px;
              width: 90%;
              display: flex;
              justify-content: center;
              border-radius: 7px;
              background: #5CC159;
              
              transition: all 0.2s;
              position: absolute;
              bottom: 25px;
              left: 50%;
              transform: translateX(-50%);
              &:hover{
                  background-color: #2AC533;
              }
          }
      }
    }
  }
  .subscription-billing{
    margin-top: 40px;
    .billings_table{
      margin-top: 35px;
      margin-bottom: 0;
      overflow-x: auto;
      &::-webkit-scrollbar {
        height: 8px;
      }
      &::-webkit-scrollbar-track {
        background: #EEE;
        border-radius: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #5CC159;
        border-radius: 20px;
      }
      .table{
        margin-bottom: 0;
        min-width: 730px;
        thead tr{
          overflow: hidden;
          border-radius: 15px;
          th{
            background: #EEF4EE;
            color: #5CC159;
            
            font-size: 13px;
            font-weight: 400;
            padding-top: 12px;
            padding-bottom: 12px;
            border: 0;
            text-align: left;
            &:first-of-type{
              border-radius: 8px 0 0 8px;
            }
            &:last-of-type{
              border-radius: 0 8px 8px 0;
            }
          }
        }
        tbody tr{
          td{
            color: #000;
            
            font-size: 14px;
            font-weight: 600;
            border: 0;
            padding-top: 15px;
            text-align: left;
            padding-bottom: 15px;
            p{
              margin: 0;
              
              font-size: 12px;
              font-weight: 400;
              padding: 5px 2px;
              border-radius: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              top: -2px;
              &.not-active{
                background: rgba(231, 46, 79, 0.15);
                color: #E72E4F;
              }
              &.active{
                color: #5CC159;
                background: rgba(92, 193, 89, 0.15);
              }
            }
            .download{
              cursor: pointer;
              width: fit-content;
              &:hover svg path{
                fill: #2AC533;
              }
              svg path{
                transition: all .2ss;
              }
            }
          }
        }
      }
    }
    .payment_methods{
      width: 100%;
      margin-top: 40px;
      h2{
        margin: 0;
        color: #000;
        
        font-size: 19px;
        font-weight: 500;
      }
      .add_box,.methods_added{
        margin-top: 20px;
        .item{
          max-width: 480px;
          border-radius: 5px;
          border: 2px solid #DAE7DA;
          background: #FFF;
          padding: 12px 65px 12px 15px;
          display: flex;
          position: relative;
          margin-bottom: 15px;
          transition: all .2s;
          &:hover{
            border-color: #2AC533;
          }
          &:not(:last-of-type){
            margin-bottom: 15px;
          }
          .icon{
            svg{
              width: 28px;
              height: 28px;
              margin: 10px 0;
            }
          }
          .info{
            padding-left: 10px;
            span{
              color: #99A399;
              text-align: left;
              
              font-size: 14px;
              font-weight: 400;
            }
            p{
              color: #000;
              text-align: left;
              
              font-size: 16px;
              font-weight: 500;
              margin: 0;
            }
          }
          .options{
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
            button{
              border: 0;
              background: none;
              outline: none;
              padding: 0 5px;
              &:hover svg path{
                fill: #5CC159;
              }
              svg{
                width: 20px;
                height: 20px;
                path{
                  transition: all .2s;
                }
              }
            }
          }
          .add{
            padding: 9px 15px;
            border-radius: 5px;
            border: 2px solid #DAE7DA;
            background: #FFF;
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
            color: #99A399;
            text-align: center;
            
            font-size: 12px;
            font-weight: 400;
            text-transform: uppercase;
            transition: all 0.2s;
            &:hover{
              color: #2AC533;
              border-color: #2AC533;
            }
          }
        }
        &.add_box .item {
          .icon svg path{
            fill: #99A399;
          }
        }
      }
    }
  }
  .subscription-promo-codes{
    .code_btn{
      margin-top: 40px;
      display: flex;
      .inpt{
        width: fit-content;
        position: relative;
        height: fit-content;
        width: 320px;
        input{
          border-radius: 7px;
          border: 2px solid #DAE7DA;
          background: #FFF;
          color: #000;
          
          font-size: 15px;
          font-weight: 400;
          padding: 9px 12px;
          outline: none;
          width: 100%;
          &::placeholder{
            color: #99A399;
          }
        }
        button{
          padding: 0 20px;
          border-radius: 5px;
          background: #5CC159;
          position: absolute;
          right: 5px;
          top: 50%;
          transform: translateY(-50%);
          color: #FFF;
          text-align: center;
          
          font-size: 13px;
          font-weight: 400;
          transition: all 0.2s;
          border: 0;
          height: 34px;
        }
      }
      .done, .error{
        width: fit-content;
        margin: 0;
        margin-left: 10px;
      }
    }
    .codes_table{
      margin-top: 35px;
      margin-bottom: 0;
      overflow-x: auto;
      &::-webkit-scrollbar {
        height: 8px;
      }
      &::-webkit-scrollbar-track {
        background: #EEE;
        border-radius: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #5CC159;
        border-radius: 20px;
      }
      .table{
        margin-bottom: 0;
        min-width: 650px;
        thead tr{
          overflow: hidden;
          border-radius: 15px;
          th{
            background: #EEF4EE;
            color: #5CC159;
            
            font-size: 13px;
            font-weight: 400;
            padding-top: 12px;
            padding-bottom: 12px;
            border: 0;
            text-align: left;
            &:first-of-type{
              border-radius: 8px 0 0 8px;
            }
            &:last-of-type{
              border-radius: 0 8px 8px 0;
            }
          }
        }
        tbody tr{
          &:first-of-type td{
            padding-top: 30px;
          }
          td{
            color: #000;
            
            font-size: 14px;
            font-weight: 600;
            border: 0;
            padding-top: 15px;
            text-align: left;
            padding-bottom: 15px;
            p{
              margin: 0;
              
              font-size: 12px;
              font-weight: 400;
              padding: 5px 2px;
              border-radius: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              top: -2px;
              &.expired{
                background: rgba(231, 46, 79, 0.15);
                color: #E72E4F;
              }
              &.active{
                color: #5CC159;
                background: rgba(92, 193, 89, 0.15);
              }
              &.used{
                color: #F58B10;
                background: rgba(245, 139, 16, 0.15);
              }
            }
            .download{
              cursor: pointer;
              width: fit-content;
              &:hover svg path{
                fill: #2AC533;
              }
              svg path{
                transition: all .2ss;
              }
            }
          }
        }
      }
    }
    .social{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px 0;
        max-width: 220px;
        margin: 0 auto;
        width: 100%;
        margin-top: 15px;
        a{
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.2s;
          border-radius: 14.917px;
          background: rgb(92 193 89 / 18%);
          backdrop-filter: blur(10.654827117919922px);
          margin: 0 auto;
          padding: 0;
          &:hover{
              background-color: #2AC533;
              svg path{
                fill: #FFF;
              }
          }
          svg{
              width: 20px;
              height: 20px;
              path{
                fill: #5CC159;
                transition: all .2s;
              }
          }
        }
    }
  }
  .payment-pop-up{
    .pop-up-content{
      width: 500px;
      .pop-up-title{
        p{
          color: #5CC159;
          
          font-size: 13px;
          font-weight: 400;
          margin: 0;
          margin-top: 5px
        }
      }
      form{
        max-width: 100% !important;
        padding: 0;
        margin: 0;
        .inpt{
          margin-bottom: 25px;
          label{
            color: #000;
            text-align: left;
            
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 7px;
            display: block;
          }
          .check_inpt{
            user-select: none;
            margin-top: 15px;
            p{
              margin: 0;
              display: block;
              &:first-of-type{
                margin-bottom: 15px;
              }
              [type="radio"]:checked,
              [type="radio"]:not(:checked) {
                  position: absolute;
                  left: -9999px;
              }
              [type="radio"]:checked + label,
              [type="radio"]:not(:checked) + label
              {
                position: relative;
                padding-left: 35px;
                cursor: pointer;
                color: #000;
                
                font-size: 14px;
                font-weight: 500;
                margin: 0;
              }
              [type="radio"]:checked + label:before,
              [type="radio"]:not(:checked) + label:before {
                content: "";
                position: absolute;
                left: 0;
                top: -2px;
                width: 25px;
                height: 25px;
                background: #FFF;
                border-radius: 100%;
                border: 6px solid #EDF5ED;
              }
              [type="radio"]:checked + label:after,
              [type="radio"]:not(:checked) + label:after {
                content: "";
                width: 13px;
                height: 13px;
                background: #5CC159;
                position: absolute;
                top: 4px;
                left: 6px;
                border-radius: 100%;
                transition: all 0.2s ease;
              }
              [type="radio"]:not(:checked) + label:after {
                  opacity: 0;
                  -webkit-transform: scale(0);
                  transform: scale(0);
              }
              [type="radio"]:checked + label:after {
                  opacity: 1;
                  -webkit-transform: scale(1);
                  transform: scale(1);
              }
              svg{
                width: 22px;
                height: 22px;
                margin-right: 5px;
              }
            }
          }
          input{
            border-radius: 7px;
            border: 2px solid #DAE7DA;
            background: #FFF;
            display: flex;
            padding: 0 8px;
            align-items: center;
            width: 100%;
            color: #222;
            font-size: 14px;
            
            font-weight: 400;
            outline: none;
            height: 43px;
            margin-top: 8px;
            text-indent: 7px;
            transition: all 0.2s;
            &:focus{
              border-color: #5CC159;
            }
          }
        }
        .inpts{
          display: flex;
          grid-gap: 15px;
          .inpt{
            width: 50%;
          }
        }
        .submit{
          
          font-size: 15px;
          font-weight: 400;
          user-select: none;
          width: 100%;
          padding: 9px 15px;
          cursor: pointer;
          border-radius: 5.015px;
          background: #5CC159;
          text-align: center;
          color: #FFF;
          border: 0;
          margin-top: 30px;
          transition: all 0.2s;
          position: relative;
          &:hover{
            background: #2AC533;
          }
        }
        .item{
          max-width: 100%;
          border-radius: 5px;
          border: 2px solid #DAE7DA;
          background: #FFF;
          padding: 12px 65px 12px 15px;
          display: flex;
          position: relative;
          transition: all .2s;
          margin-top: 30px;
          margin-bottom: 60px;
          &:hover{
            border-color: #2AC533;
          }
          &:not(:last-of-type){
            margin-bottom: 15px;
          }
          .icon{
            svg{
              width: 28px;
              height: 28px;
              margin: 10px 0;
            }
          }
          .info{
            padding-left: 10px;
            span{
              color: #99A399;
              text-align: left;
              
              font-size: 14px;
              font-weight: 400;
            }
            p{
              color: #000;
              text-align: left;
              
              font-size: 16px;
              font-weight: 500;
              margin: 0;
            }
          }
          .options{
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
            button{
              border: 0;
              background: none;
              outline: none;
              padding: 0 5px;
              &:hover svg path{
                fill: #5CC159;
              }
              svg{
                width: 20px;
                height: 20px;
                path{
                  transition: all .2s;
                }
              }
            }
          }
          .add{
            padding: 9px 15px;
            border-radius: 5px;
            border: 2px solid #DAE7DA;
            background: #FFF;
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
            color: #99A399;
            text-align: center;
            
            font-size: 12px;
            font-weight: 400;
            text-transform: uppercase;
            transition: all 0.2s;
            &:hover{
              color: #2AC533;
              border-color: #2AC533;
            }
          }
        }
        .btns{
          display: flex;
          margin-top: 35px;
          grid-gap: 10px;
          button{
            
            font-size: 15px;
            font-weight: 400;
            user-select: none;
            width: 100%;
            padding: 9px 15px;
            cursor: pointer;
            border-radius: 5.015px;
            background: #5CC159;
            text-align: center;
            color: #FFF;
            border: 0;
            margin-top: 30px;
            transition: all 0.2s;
            width: 50%;
            position: relative;
            &:hover{
              background: #2AC533;
            }
            &:last-of-type{
              border: 2px solid #E72E4F;
              background: #FFF;
              color: #E72E4F;
              &:hover{
                background-color: #E72E4F;
                color: #FFF;
              }
            }
            &:first-of-type{
              background: #5CC159;
              &:hover{
                background-color: #2AC533;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 991px){
    .subscription-pricing {
      h2{
        font-size: 22px;
      }
      .plans{
        display: block;
        .plan{
          max-width: 370px;
          margin: 0 auto;
          margin-bottom: 30px;
        }
      }
    }
  }
  @media (max-width: 767px){
    .subscription-promo-codes .code_btn{
      flex-wrap: wrap;
      .inpt{
        width: 100%;
      }
      .done, .error{
        margin-top: 10px;
        width: 100%;
        margin-left: 0;
      }
    }
    .subscription-billing .payment_methods .add_box.add_box .item{
      flex-wrap: wrap;
      padding: 12px 15px 12px 15px;
      .info{
        width: calc(100% - 28px);
      }
      button{
        position: unset;
        display: block;
        transform: translate(0);
        margin: 0 auto;
        margin-top: 16px;
      }
    }
  }
}
// Settings
.dashboard-settings{
  border-radius: 20px;
  border: 2px solid #DAE7DA;
  background: #FFF;
  width: 100%;
  padding: 35px 25px 45px;
  .profile-settings, .security-settings{
    .settings_form{
      max-width: 100% !important;
      padding: 0;
      margin: 0;
      margin-top: 50px;
      position: relative;
      min-height: 500px;
      .profilePhoto{
        margin-bottom: 20px;
        label{
          color: #000;
          text-align: left;
          font-size: 15px;
          font-weight: 600;
          margin-bottom: 7px;
          display: block;
          width: 100%;
        }
        .photoBox{
          width: fit-content;
          position: relative;
          span{
            position: absolute;
            right: -4px;
            top: -3px;
            width: 25px;
            height: 25px;
            background: #2AC533;
            border-radius: 50%;
            overflow: hidden;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            svg{
              width: 13px;
              fill: #FFF;
            }
            input{
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              cursor: pointer;
              opacity: 0;
            }
          }
          img{
            width: 110px;
            overflow: hidden;
            height: 110px;
            border-radius: 4px;
          }
        }
      }
      .inpt{
        margin-bottom: 30px;
        width: 500px;
        label{
          color: #000;
          text-align: left;
          font-size: 15px;
          font-weight: 600;
          margin-bottom: 7px;
          display: block;
          width: 100%;
        }
        input, textarea{
          border-radius: 7px;
          border: 2px solid #DAE7DA;
          background: #FFF;
          display: flex;
          padding: 0 8px;
          align-items: center;
          width: 100%;
          color: #222;
          font-size: 15px;
          outline: none;
          font-weight: 500;
          height: 43px;
          margin-top: 8px;
          text-indent: 7px;
          transition: all 0.2s;
          &::placeholder{
            font-weight: 400;
            font-size: 13px;
          }
          &:focus{
            border-color: #5CC159;
          }
        }
        textarea{
          padding: 10px 7px;
          height: 125px;
          resize: none;
        }
        .phone{
          display: flex;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 10px;
          .code{
            .code_country{
              border: 2.139px solid #DAE7DA;
              background: #FFF;
              display: flex;
              height: 43px;
              border-radius: 7px;
              justify-content: center;
              align-items: center;
              width: 99px;
              padding: 10px 0;
              .MuiOutlinedInput-notchedOutline{
                border: unset;
              }
              .MuiSelect-select{
                padding: 0 10px !important;
                color: #000;
                
                font-size: 15px;
                font-weight: 500;
                img{
                  position: relative;
                  top: -1px;
                  margin-right: 4px;
                }
              }
              svg{
                display: none;
              }
            }
          }
          .field{
            width: calc(100% - 89px);
            position: relative;
            input{
              margin: 0;
            }
            button{
              padding: 0 20px;
              border-radius: 5px;
              background: #5CC159;
              position: absolute;
              right: 5px;
              top: 50%;
              transform: translateY(-50%);
              color: #FFF;
              text-align: center;
              
              font-size: 13px;
              font-weight: 400;
              transition: all 0.2s;
              border: 0;
              height: 34px;
              &:hover{
                background: #2AC533;
              }
            }
          }
        }
        .btx{
          position: relative;
          .show_pwd{
            position: absolute;
            right: 10px;
            bottom: 9px;
            cursor: pointer;
            &:hover svg path{
              fill: #5CC159;
            }
            svg{
              width: 19px;
              height: 19px;
              path{
                transition: all .2s;
              }
            } 
          }
        }
      }
      .profile_img{
        width: fit-content;
        .up_img{
          display: flex;
          margin-top: 15px;
          .img{
            width: 175px;
            position: relative;
            margin-right: 25px;
            img{
              width: 100%;
              border-radius: 10px;
              height: -webkit-fill-available;
            }
            .remove{
              position: absolute;
              top: -12px;
              right: -10px;
              cursor: pointer;
            }
          }
          .up_box{
            border-radius: 10px;
            border: 2px dashed rgba(153, 163, 153, 0.5607843137);
            background: #FFF;
            text-align: center;
            padding: 25px 60px;
            position: relative;
            transition: all .2s;
            &:hover{
              border-color: #2AC533;
            }
            p{
              color: #5CC159;
              text-align: center;
              
              font-size: 13px;
              font-weight: 500;
              margin: 0;
              margin-top: 11px;
            }
            span{
              color: #99A399;
              text-align: center;
              
              font-size: 12px;
              font-weight: 400;
              width: 280px;
              display: flex;
            }
            svg{
              width: 45px;
              height: auto;
            }
            input{
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              cursor: pointer;
              opacity: 0;
              margin: 0;
            }
          }
        }
      }
      .btns{
        display: flex;
        margin-top: 40px;
        grid-gap: 10px;
        width: 500px;
        button{
          
          font-size: 14px;
          font-weight: 500;
          user-select: none;
          padding: 8px 15px;
          cursor: pointer;
          border-radius: 7px;
          text-align: center;
          color: #FFF;
          border: 0;
          margin-top: 30px;
          transition: all 0.2s;
          width: 50%;
          position: relative;
          &:hover{
            background: #2AC533;
          }
          &:last-of-type{
            border: 2px solid #E72E4F;
            background: #FFF;
            color: #E72E4F;
            &:hover{
              background-color: #E72E4F;
              color: #FFF;
            }
          }
          &:first-of-type{
            background: #5CC159;
            &:hover{
              background-color: #2AC533;
            }
          }
        }
      }
      .switch{
        .check{
          display: flex;
          margin-top: 10px;
          input[type="checkbox"] {
            position: relative;
            width: 70px;
            height: 30px;
            -webkit-appearance: none;
            appearance: none;
            outline: none;
            border-radius: 178.259px;
            background: #EDF5ED;
            cursor: pointer;
            border: 0;
            transition: 0.5s;
            margin: 0;
          }
          input[type="checkbox"]::before {
            content: "";
            width: 24px;
            height: 24px;
            border-radius: 50%;
            position: absolute;
            top: 3px;
            left: 3px;
            transition: 0.5s;
            background: #FFF;
          }
          input[type="checkbox"]:checked::before {
            left: 43px;
            background: #fff;
          }
          input[type="checkbox"]:checked {
            background: #5CC159;
          }
          p{
            color: #000;
            text-align: center;
            
            font-size: 15px;
            font-weight: 500;
            margin: 0;
            margin-left: 10px;
            padding: 3px 0;
          }
        }
        .hide_box{
          margin-top: 30px;
          .check_inpt{
            user-select: none;
            display: flex;
            p{
              margin: 0;
              &:first-of-type{
                margin-right: 25px;
              }
              [type="radio"]:checked,
              [type="radio"]:not(:checked) {
                  position: absolute;
                  left: -9999px;
              }
              [type="radio"]:checked + label,
              [type="radio"]:not(:checked) + label
              {
                position: relative;
                padding-left: 32px;
                cursor: pointer;
                color: #000;
                
                font-size: 15px;
                font-weight: 500;
                margin: 0;
              }
              [type="radio"]:checked + label:before,
              [type="radio"]:not(:checked) + label:before {
                content: "";
                position: absolute;
                left: 0;
                top: -2px;
                width: 25px;
                height: 25px;
                background: #FFF;
                border-radius: 100%;
                border: 6px solid #EDF5ED;
              }
              [type="radio"]:checked + label:after,
              [type="radio"]:not(:checked) + label:after {
                content: "";
                width: 13px;
                height: 13px;
                background: #5CC159;
                position: absolute;
                top: 4px;
                left: 6px;
                border-radius: 100%;
                transition: all 0.2s ease;
              }
              [type="radio"]:not(:checked) + label:after {
                  opacity: 0;
                  -webkit-transform: scale(0);
                  transform: scale(0);
              }
              [type="radio"]:checked + label:after {
                  opacity: 1;
                  -webkit-transform: scale(1);
                  transform: scale(1);
              }
            }
          }
          .inpt{
            position: relative;
            input{
              margin-top: 15px;
              text-indent: 25px;
            }
            p{
              color: #5CC159;
              
              font-size: 13px;
              font-weight: 400;
              margin: 0;
              margin-top: 7px
            }
            span{
              color: #99A399;
              
              font-size: 17px;
              font-weight: 400;
              position: absolute;
              top: 8px;
              height: 30px;
              width: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              left: 4px;
              background: #FFF;
            }
          }
        }
        .qr_box{
          margin-top: 30px;
          p{
            color: #000;
            
            font-size: 15px;
            font-weight: 500;
            text-align: left;
            margin: 0;
            margin-bottom: 5px;
          }
          .qr img{
            width: 320px;
          }
          .field{
            width: 100%;
            position: relative;
            margin-top: 15px;
            input{
              margin: 0;
            }
            button{
              padding: 0 20px;
              border-radius: 5px;
              background: #5CC159;
              position: absolute;
              right: 5px;
              top: 50%;
              transform: translateY(-50%);
              color: #FFF;
              text-align: center;
              
              font-size: 13px;
              font-weight: 400;
              transition: all 0.2s;
              border: 0;
              height: 34px;
              &:hover{
                background: #2AC533;
              }
            }
          }
        }
      }
      .select_filter{
        border-radius: 7px;
        border: 2px solid #DAE7DA;
        color: #222;
        height: 43px;
        margin-left: 0;
        font-size: 14px;
        font-weight: 500;
        width: 100%;
        .MuiOutlinedInput-notchedOutline{
          border: unset;
        }
        .MuiSelect-select{
          padding-right: 40px !important;
          padding-left: 9px;
        }
        svg{
          fill: #99A399;
        }
      }
      .save{
        
        font-size: 14px;
        font-weight: 500;
        user-select: none;
        padding: 8px 15px;
        cursor: pointer;
        border-radius: 7px;
        text-align: center;
        color: #FFF;
        border: 0;
        margin-top: 30px;
        transition: all 0.2s;
        width: 500px;
        background: #5CC159;
        margin-top: 200px;
        &:hover{
          background: #2AC533;
        }
      }
    }
    .verify-number-popup{
      .pop-up-content{
        width: 525px;
        .pop-up-title{
          margin: 0;
        }
        form{
          margin: 0;
          .otp_section{
            width: 100%;
            margin: 45px 0 0;
            &.error{
              div input{
                border-color: #E72E4F !important;
              }
            }
            div{
              justify-content: space-between;
              input{
                width: 60px !important;
                border-radius: 10.695px;
                border: 2.139px solid #DAE7DA;
                background: #FFF;
                height: 55px;
                
                font-weight: 500;
                font-size: 19px;
                transition: all .2s;
                outline: none;
                &::placeholder{
                  color: #DAE7DA;
                }
                &:focus{
                  border-color: #5CC159 !important;
                }
                @media (max-width: 500px){
                  &{
                    width: 50px !important;
                    height: 50px !important;
                  }
                }
              }
            }
          }
        }
        @media (max-width: 991px){
          &{
            width: 100%;
          }
        }
      }
    }
    .css-1jqq78o-placeholder{
      // font-size: 13px;
    }
    .css-t3ipsp-control{
      box-shadow: none;
      border-width: 2px;
      &:hover{
        border-color: #5cc159;
      }
    }
    .css-13cymwt-control {
      border: 2px solid #DAE7DA;
  }
    .css-qbdosj-Input{
      // height: 34px;
    }
  }
  @media (max-width: 767px){
    form{
      .btns, .inpt, .save{
        width: 100% !important;
      }
      .profile_img .up_img{
        flex-wrap: wrap;
        .img{
          margin: 0 auto !important;
        }
        .up_box{
          width: 100%;
          margin-top: 15px;
          span{
            width: 100% !important;
            text-align: center;
            display: block !important;
          }
        }
      }
      .switch .qr_box .qr img {
        width: 245px !important;
        margin: 0 auto;
        display: block;
      }
    } 
  }
  .react-tel-input .country-list{
    position: absolute !important;
  }
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root img{
  margin-right: 5px;
}
// Exchange
.dashboard-exchange{
  border-radius: 20px;
  border: 2px solid #DAE7DA;
  background: #FFF;
  width: 100%;
  padding: 35px 25px 45px;
  .inpt{
    label{
      color: #000;
      text-align: left;
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 7px;
      display: block;
      width: 100%;
    }
    input{
      border-radius: 7px;
      border: 2px solid #DAE7DA;
      background: #FFF;
      display: flex;
      padding: 0 8px;
      align-items: center;
      width: 100%;
      color: #222;
      font-size: 15px;
      
      outline: none;
      font-weight: 500;
      height: 43px;
      margin-top: 8px;
      text-indent: 7px;
      transition: all 0.2s;
      &::placeholder{
        font-weight: 400;
        font-size: 13px;
      }
      &:focus{
        border-color: #5CC159;
      }
    }
  }
  form{
    padding: 0;
    margin: 0;
    // margin-top: 50px;
    position: relative;
    display: flex;
    width: 100%;
    max-width: none;
    .cont{
      display: flex;
      .inpt{
        label{
          color: #000;
          text-align: left;
          font-size: 15px;
          font-weight: 500;
          margin-bottom: 7px;
          display: block;
          width: 100%;
        }
        input{
          border-radius: 7px;
          border: 2px solid #DAE7DA;
          background: #FFF;
          display: flex;
          padding: 0 8px;
          align-items: center;
          width: 100%;
          color: #222;
          font-size: 15px;
          outline: none;
          font-weight: 500;
          height: 43px;
          margin-top: 8px;
          text-indent: 7px;
          transition: all 0.2s;
          &::placeholder{
            font-weight: 400;
            font-size: 13px;
          }
          &:focus{
            border-color: #5CC159;
          }
        }
      }
      .select_filter{
        border-radius: 7px;
        border: 2px solid #DAE7DA;
        color: #222;
        height: 43px;
        margin-left: 0;
        font-size: 14px;
        font-weight: 500;
        width: 250px;
        .MuiOutlinedInput-notchedOutline{
          border: unset;
        }
        .MuiSelect-select{
          padding-right: 40px !important;
          text-transform: uppercase;
          font-weight: 400;
        }
        svg{
          fill: #99A399;
        }
      }
      button{
        font-size: 14px;
        font-weight: 400;
        user-select: none;
        padding: 0px 25px;
        cursor: pointer;
        text-align: center;
        color: #FFF;
        border: 0;
        transition: all 0.2s;
        height: 43px;
        background: #5CC159;
        // margin-top: 29px;
        border-radius: 7px;
        // margin-left: 15px;
        svg{
          margin-right: 4px;
          width: 17px;
          height: 17px;
        }
        &:hover{
          background: #2AC533;
        }
      }
    }
    .done{
      height: 43px;
      margin-top: 29px;
      border-radius: 7px;
      margin-left: 15px;
      padding-right: 40px;
      p{
        margin: 0;
        color: #5CC159;
        font-size: 13.5px;
        font-weight: 400;
        line-height: normal;
        padding: 6px 0;
        margin-bottom: 0;
        margin-left: 10px;
      }
    }
  }
  .exchange-table{
    margin-top: 35px;
    .table{
      margin-bottom: 0;
      min-width: 850px;
      thead tr{
        th{
          font-size: 14px;
          padding-top: 13px;
          padding-bottom: 13px;
          text-align: left;
        }
      }
      tbody tr{
        &:first-of-type td{
          padding-top: 25px;
        }
        td{
          text-align: left;
          .logo{
            border-radius: 30px;
            background: #F7F7F7;
            display: flex;
            padding: 5px 20px 5px 5px;
            transition: all 0.2s;
            width: fit-content;
            img{
              width: 30px;
              height: 30px;
            }
            p{
              margin: 0;
              padding: 0;
              transition: all 0.2s;
              font-size: 14px;
              color: #000;
              text-align: center;
              font-weight: 500;
              margin-left: 6px;
              position: relative;
              top: 0px;
            }
          }
          .switch{
            display: flex;
            margin: 10px 0;
            input[type="checkbox"] {
              position: relative;
              width: 55px;
              height: 20px;
              appearance: none;
              outline: none;
              border-radius: 178.259px;
              background: rgba(92, 193, 89, 0.15);
              cursor: pointer;
              border: 0;
              transition: 0.5s;
              margin: 0;
            }
            input[type="checkbox"]::before {
              content: "";
              width: 16px;
              height: 16px;
              border-radius: 50%;
              position: absolute;
              top: 2px;
              left: 2px;
              transition: 0.5s;
              background: #FFF;
            }
            input[type="checkbox"]:checked::before {
              left: 37px;
              background: #fff;
            }
            input[type="checkbox"]:checked {
              background: #5CC159;
            }
            p{
              color: #000;
              text-align: center;
              
              font-size: 15px;
              font-weight: 500;
              margin: 0;
              margin-left: 10px;
              padding: 3px 0;
            }
          }
          .options{
            margin: 9px 0;
            button{
              border: 0;
              background: none;
              outline: none;
              padding: 0 5px;
              &:hover svg path{
                fill: #5CC159;
              }
              svg{
                width: 20px;
                height: 19px;
                path{
                  transition: all .2s;
                }
              }
            }
          }
          p{
            color: #000;
            font-size: 14px;
            font-weight: 600;
            border: 0;
            justify-content: start;
            width: 100%;
            padding: 0;
            margin: 0;
            padding-top: 13px;
          }
          .empty-table{
            min-height: 250px;
            width: 100%;
            padding-top: 0 !important;
            padding-bottom: 40px !important;
            p{
              margin: 0;
              color: #99A399;
              font-size: 14px !important;
              font-weight: 400;
              text-transform: initial;
              text-align: center;
              width: 100%;
              padding: 0;
              display: block;
            }
            button{
              font-size: 13px;
              font-weight: 400;
              user-select: none;
              padding: 8px 20px;
              cursor: pointer;
              border-radius: 5.015px;
              background: #5CC159;
              text-align: center;
              color: #FFF;
              border: 0;
              transition: all 0.2s;
              position: relative;
              display: flex;
              width: fit-content;
              margin: 0 auto;
              margin-top: 10px;
              &:hover{
                background-color: #2AC533;
                color: #FFF;
              }
            }
          }
        }
      }
    }
  }
  .exchange-pop-up{
    .pop-up-content{
      width: 630px;
      padding: 75px 30px 40px;
      .pop-up-title{
        p{
          color: #99A399;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 8px 0 0;
        }
      }
      .exchange_content{
        margin-top: 40px;
        position: relative;
        .links{
          display: flex;
          margin: 0;
          margin-bottom: 30px;
          li{
            margin-right: 10px;
            color: #000;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
            padding: 0px 10px 10px;
            transition: all 0.2s;
            border-bottom: 2px solid #FFF;
            &.active{
              color: #5CC159;
              border-bottom-color: #5CC159;
            }
            &:hover{
              color: #5CC159;
            }
          }
        }
        .spot, .usdt-m{
          width: 100%;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 20px;
          .item{
            position: relative;
            &:hover .spot_item {
              border-color: #5CC159;
              p{
                color: #5CC159;
              }
            }
            input{
              position: absolute;
              top:0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              cursor: pointer;
            }
            .spot_item{
              display: flex;
              padding: 10px 20px;
              cursor: pointer;
              border-radius: 5.619px;
              background: #F7F7F7;
              transition: all 0.2s;
              border: 1px solid #F7F7F7;
              img{
                width: 30px;
                height: 30px;
              }
              p{
                margin: 0;
                padding: 4px 0;
                color: #000;
                transition: all 0.2s;
                font-size: 15px;
                font-weight: 500;
                margin-left: 6px;
              }
            }
            input[type="radio"]:checked + .spot_item{
              background: #5CC159;
              p{
                color: #FFF;
              }
            }
          }
        }
        .next{
          font-size: 15px;
          font-weight: 400;
          user-select: none;
          width: 100%;
          padding: 9px 15px;
          cursor: pointer;
          border-radius: 5.015px;
          background: #5CC159;
          text-align: center;
          color: #FFF;
          border: 0;
          margin-top: 110px;
          transition: all 0.2s;
          position: relative;
          pointer-events: none;
          opacity: 0.7;
          &.active{
            pointer-events: visible;
            opacity: 1;
          }
          &:hover{
            background: #2AC533;
          }
        }
        .key{
          p{
            color: #000;
            text-align: left;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            margin-bottom: 10px;
          }
          .steps{
            border-radius: 10.7px;
            border: 2px solid #DAE7DA;
            background: #FFF;
            padding: 25px;
            .step{
              &:not(:last-of-type){
                margin-bottom: 15px;
              }
              p{
                color: #99A399;
                font-size: 15px;
                font-weight: 400;
                margin: 0;
                display: flex;
                span{
                  margin-right: 8px;
                  height: 100%;
                  display: flex;
                  width: fit-content;
                }
              }
              .api{
                display: block;
                border-radius: 10.7px;
                background: #EEF4EE;
                width: 100%;
                position: relative;
                padding: 12px 50px 12px 23px;
                margin-top: 10px;
                p{
                  color: #5CC159;
                  font-size: 15px;
                  font-weight: 500;
                  text-align: left;
                  margin: 0;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis "[..]";
                }
                .copy{
                  position: absolute;
                  right: 21px;
                  top: 50%;
                  transform: translateY(-50%);
                  cursor: pointer;
                  &:hover svg path{
                    fill: #2AC533;
                  }
                  svg{
                    width: 25px;
                    path{
                      transition: all .2s;
                    }
                  }
                }
              }
            }
          }
          a{
            display: flex;
            width: fit-content;
            color: #5CC159;
            font-size: 15px;
            text-decoration-line: underline;
            font-weight: 400;
            transition: all .2s;
            &:first-of-type{
              margin-bottom: 5px;
              margin-top: 10px;
            }
            &:hover{
              color: #2AC533;
            }
          }
        }
        form{
          width: 100%;
          flex-wrap: wrap;
          margin-top: 30px;
          .inpt{
            width: 100%;
            margin-bottom: 15px;
            label{
              color: #000;
              text-align: left;
              font-size: 15px;
              font-weight: 500;
              margin-bottom: 5px;
              display: block;
              width: 100%;
            }
            input{
              border-radius: 7px;
              border: 2px solid #DAE7DA;
              background: #FFF;
              display: flex;
              padding: 0 8px;
              align-items: center;
              width: 100%;
              color: #222;
              font-size: 15px;
              margin-top: 0;
              outline: none;
              font-weight: 500;
              height: 43px;
              text-indent: 7px;
              transition: all 0.2s;
              &::placeholder{
                font-weight: 400;
                font-size: 13px;
              }
              &:focus{
                border-color: #5CC159;
              }
            }
          }
          button{
            font-size: 16px;
            font-weight: 400;
            user-select: none;
            width: 100%;
            padding: 10px 15px;
            cursor: pointer;
            border-radius: 5px;
            background: #5CC159;
            text-align: center;
            color: #FFF;
            border: 0;
            transition: all 0.2s;
            position: relative;
            margin: 0;
            opacity: 0.7;
            margin-top: 45px;
            cursor: default;
            height: auto;
            &:hover{
              background: #2AC533;
            }
          }
        }
      }
      .back_to{
        position: absolute;
        color: #5CC159;
        font-size: 15px;
        font-weight: 500;
        top: 17px;
        left: 25px;
        cursor: pointer;
        &:hover{
          color: #2AC533;
          svg path{
            stroke: #2AC533;
          }
        }
        svg{
          width: 27px;
          path{
            transition: all .2s;
          }
        }
      }
    }
  }
  .editAPI-pop-up{
    .pop-up-content{
      width: 530px;
      padding-top: 23px;
      .pop-up-title h1 {
        font-size: 21px;
      }
      form{
        width: 100%;
        flex-wrap: wrap;
        margin-top: 30px;
        .inpt{
          width: 100%;
          margin-bottom: 15px;
          label{
            margin-bottom: 5px;
          }
          input{
            margin-top: 0;
          }
        }
        .btns{
          display: flex;
          margin-top: 35px;
          width: 100%;
          grid-gap: 10px;
          button{
            font-size: 14px;
            font-weight: 400;
            user-select: none;
            padding: 0px 25px;
            cursor: pointer;
            text-align: center;
            color: #FFF;
            border: 0;
            transition: all 0.2s;
            height: 43px;
            background: #5CC159;
            border-radius: 7px;
            margin-left: 15px;
            width: 50%;
            margin: 0 !important;
            &:last-of-type{
              border: 2px solid #E72E4F;
              background: #FFF;
              color: #E72E4F;
              &:hover{
                background-color: #E72E4F;
                color: #FFF;
              }
            }
            &:first-of-type{
              background: #5CC159;
              &:hover{
                background-color: #2AC533;
              }
            }
          }
        }
      }
    }
  }
  .api_copy{
    display: flex;
    cursor: pointer;
    width: fit-content;
    .copy{
      position: relative;
      top: 5px;
      margin-left: 10px;
      svg{
        width: 22px;
      }
    }
  }
  @media (max-width: 991px){
    form{
      flex-wrap: wrap;
      .cont{
        width: 100%;
      }
      .done {
        display: block;
        margin-top: 15px;
        margin-left: 0;
      }
    } 
  }
  @media (max-width: 767px){
    form{
      .cont{
        width: 100%;
        flex-wrap: wrap;
        .inpt{
          width: 100%;
          .select_filter{
            width: 100%;
          }
        }
        button{
          margin-left: 0;
          margin-top: 10px;
          width: 100%;
        }
      }
      .done {
        width: 100%;
      }
    } 
    .spot, .usdt-m {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }
  @media (max-width: 500px){
    .exchange-pop-up .pop-up-content .exchange_content {
      .spot,.usdt-m{
        grid-template-columns: repeat(1, 1fr) !important;
      }
    } 
  }
}
// Telegram Bot
.dashboard-telegram-bot{
  // border-radius: 20px;
  // border: 2px solid #DAE7DA;
  // background: #FFF;
  // padding: 35px 25px 45px;
  width: 100%;
  .title{
    margin-bottom: 35px;
  }
  .links {
    display: flex;
    margin: 0;
    margin-bottom: 15px;
    li{
      margin-right: 10px;
      border-bottom: 2px solid transparent;
      color: #000;
      cursor: pointer;
      font-size: 15px;
      font-weight: 500;
      padding: 0 10px 10px;
      transition: all .2s;
      &:hover{
        color: #2ac533;
      }
      &.active {
        border-bottom-color: #2ac533;
        color: #2ac533;
      }
    }
  }
  .added_channels{
    border-radius: 20px 20px 7px 7px;
    padding: 15px 15px 0px;
    background: #FFF;
    border: 2px solid #DAE7DA;
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #5CC159;
      border-radius: 20px;
    }
    .table{
      min-width: 400px;
      thead tr th {
        background: #eef4ee;
        border: 0;
        color: #5cc159;
        font-family: Poppins;
        font-size: 13px;
        font-weight: 400;
        padding-bottom: 12px;
        padding-top: 12px;
        text-align: center;
        &:last-of-type {
          border-radius: 0 8px 8px 0;
        }
        &:first-of-type {
          border-radius: 8px 0 0 8px;
          text-align: left;
        }
      }
      tbody tr{
        &:last-of-type{
          border-bottom: #FFF;
        }
        &:hover td{
          background: #eef4ee;
        }
        td{
          border: 0;
          color: #000;
          font-family: Poppins;
          font-size: 13px;
          font-weight: 500;
          padding-bottom: 15px;
          padding-top: 15px;
          text-align: center;
          text-transform: capitalize;
          transition: all .2s;
          &:first-of-type{
            text-align: left;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          &.status{
            p{
              align-items: center;
              border-radius: 30px;
              display: flex;
              font-family: Poppins;
              font-size: 11px;
              font-weight: 400;
              justify-content: center;
              margin: 0;
              padding: 5px 10px;
              position: relative;
              top: -2px;
              &.active{
                background: rgba(92, 193, 89, .15);
                color: #5cc159;
              }
              &.not-active{
                background: rgba(231, 46, 79, .15);
                color: #e72e4f;
              }
            }
          }
          &.btns{
            padding: 12px 0px;
            display: flex;
            grid-gap: 5px;
            button{
              background: #2ac533;
              border: 0;
              border-radius: 3px;
              color: #fff;
              cursor: pointer;
              font-size: 12px;
              font-weight: 500;
              overflow: hidden;
              padding: 7px 11px;
              position: relative;
              text-align: center;
              transition: all 0.2s;
              user-select: none;
              width: 100%;
            }
            .delete{
              background-color: #e72e4f;
            }
          }
        }
      }
    } 
  }
  .add_new-channel{
    border-radius: 20px;
    padding: 35px 25px 45px;
    background: #FFF;
    border: 2px solid #DAE7DA;
    .progress_bot{
      width: 100%;
      display: flex;
      margin: 0 auto;
      width: fit-content;
      li{
        position: relative;
        width: 200px;
        &:not(:first-of-type){
          &:after{
            content: "";
            position: absolute;
            top: 13px;
            left: -87px;
            width: 174px;
            height: 3px;
            background: #D9D9D9;
            transition: all .2s;
          }
        }
        p{
          color: #D9D9D9;
          font-size: 14px;
          z-index: 5;
          font-weight: 400;
          position: relative;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          border: 3px solid #D9D9D9;
          margin: 0 auto;
          transition: all .2s;
          overflow: hidden;
          svg{
            position: absolute;
            z-index: 3;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: all .2s;
          }
        }
        span{
          color: #99A399;
          font-size: 16px;
          font-weight: 400;
          display: flex;
          margin-top: 15px;
          justify-content: center;
          transition: all .2s;
        }
        &.prt_done{
          &:after{
            background: #5CC159;
          }
          p{
            color: #5CC159;
            border-color: #5CC159;
            background: #5CC159;
            svg{
              opacity: 1;
            }
          }
          span{
            color: #5CC159;
          }
        }
        &.prt_now{
          &:after{
            background: #5CC159;
          }
          p{
            border-color: #5CC159;
            color: #5CC159;
          }
          span{
            color: #5CC159;
          }
        }
      }
    }
    .help{
      border-radius: 20px;
      background: #5CC159;
      max-width: 525px;
      margin: 50px auto;
      overflow: hidden;
      position: relative;
      .txt{
        width: fit-content;
        padding: 55px 0 55px 25px;
        position: relative;
        z-index: 10;
        h3{
          color: #FFF;
          font-size: 21px;
          font-weight: 500;
          margin-bottom: 8px;
        }
        p{
          color: #FFF;
          font-size: 17px;
          font-weight: 300;
          margin: 0;
          width: 190px;
        }
      }
      .img{
        position: absolute;
        top: 55%;
        right: -165px;
        transform: translateY(-50%);
      }
      .person{
        position: absolute;
        top: 5px;
        right: 60px;
      }
      .play{
        position: absolute;
        top: 46%;
        left: 51%;
        transform: translate(-50%, -50%);
        z-index: 11;
        width: 65px;
        height: 65px;
        border-radius: 50%;
        box-shadow: 0 0px 2px 1px rgba(255, 255, 255, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(90deg, rgb(126, 205, 105) 0%, rgb(177, 220, 102) 69%);
        cursor: pointer;
        transition: all 0.2s;
        &:hover{
          box-shadow: 0 0px 4px 2px rgba(255, 255, 255, 0.8);
        }
        svg{
          width: 17px;
          height: 17px;
          position: relative;
          left: 2px;
          top: 1px;
        }
      }
    }
    .bot_steps{
      max-width: 630px;
      margin: 0 auto;
      .step{
        width: 100%;
        border-radius: 10px;
        border: 2.139px solid #DAE7DA;
        background: #FFF;
        padding: 10px 35px 10px 60px;
        position: relative;
        display: flex;
        margin-bottom: 20px;
        cursor: pointer;
        &:hover{
          p{
            color: #5CC159;
          }
          .down path{
            stroke: #5CC159;
          }
          span{
            border-color: #5CC159;
            color: #5CC159;
          }
        }
        span{
          color: #D9D9D9;
          font-size: 14px;
          z-index: 5;
          font-weight: 400;
          width: 29px;
          height: 29px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          border: 2px solid #D9D9D9;
          transition: all 0.2s;
          overflow: hidden;
          margin-right: 15px;
          position: absolute;
          left: 20px;
          top: 8px;
          svg{
            position: absolute;
            z-index: 3;
            top: 49%;
            left: 49%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: all .2s;
          }
        }
        p{
          color: #000;
          font-size: 16px;
          font-weight: 500;
          margin: 0;
          text-align: left;
          position: relative;
          transition: all 0.2s;
          top: 2px;
        }
        .down{
          position: absolute;
          right: 14px;
          width: 19px;
          top: 50%;
          transform: translateY(-50%);
          path{
            transition: all .2s;
          }
        }
        &.step_complete{
          span{
            background: #5CC159;
            color: #5CC159;
            border-color: #5CC159;
            svg{
              opacity: 1;
            }
          }
        }
        &.step_now{
          span{
            background: #5CC159;
            color: #FFF;
            border-color: #5CC159;
          }
        }
      }
    }
    .telegram-bot-pop-up{
      .pop-up-content{
        width: 700px;
        min-height: 400px;
        padding: 55px 30px 40px;
        overflow-x: hidden;
        .close{
          position: absolute;
          top: 20px;
          right: 25px;
          cursor: pointer;
          &:hover svg path{
            stroke: #2AC533;
          }
          svg{
            width: 28px;
            height: 27px;
            path{
              transition: all .2s;
            }
            
          }
        }
        .progress_popup{
          width: 100%;
          display: flex;
          margin: 0 auto;
          width: fit-content;
          margin-bottom: 35px;
          li{
            position: relative;
            width: 100px;
            &:not(:first-of-type){
              &:after{
                content: "";
                position: absolute;
                top: 13px;
                left: -36px;
                width: 73px;
                height: 3px;
                background: #D9D9D9;
                transition: all 0.2s;
              }
            }
            p{
              color: #D9D9D9;
              font-size: 14px;
              z-index: 5;
              font-weight: 400;
              position: relative;
              width: 30px;
              height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              border: 3px solid #D9D9D9;
              margin: 0 auto;
              transition: all .2s;
              overflow: hidden;
              svg{
                position: absolute;
                z-index: 3;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
                transition: all .2s;
              }
            }
            &.prt_done{
              &:after{
                background: #5CC159;
              }
              p{
                color: #5CC159;
                border-color: #5CC159;
                background: #5CC159;
                svg{
                  opacity: 1;
                }
              }
            }
            &.prt_now{
              &:after{
                background: #5CC159;
              }
              p{
                border-color: #5CC159;
                color: #5CC159;
              }
            }
          }
        }
        .pop-up-title{
          margin-bottom: 40px;
          h1{
            color: #000;
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 10px;
            text-align: center;
          }
          p{
            color: #99A399;
            text-align: center;
            font-size: 15px;
            font-weight: 400;
            width: 390px;
            margin: 0 auto;
          }
        }
        .confirm{
          font-size: 15px;
          font-weight: 400;
          user-select: none;
          padding: 10px 0;
          cursor: pointer;
          text-align: center;
          color: #FFF;
          width: 100%;
          border: 0;
          transition: all 0.2s;
          border-radius: 7.681px;
          background: #5CC159;
          margin-top: 50px;
          opacity: .8;
          pointer-events:none;
          &.active{
            opacity: 1;
            pointer-events: visible;
          }
          &:hover{
            background: #2AC533;
          }
        }
        .loginMethods{
          font-weight: 500;
          color: #2AC533;
          text-decoration: underline;
          font-size: 15px;
          text-align: center;
          cursor: pointer;
          width: fit-content;
          margin: 20px auto 0;
        }
        .step1{
          .qrcode{
            width: 100%;
            margin: 0 auto;
            text-align: center;
            svg{
              width: 160px !important;
              max-width: unset !important;
              border-radius: 5px;
            }
            .stps{
              width: fit-content;
              margin: 0 auto;
              h3{
                margin: 0;
                font-weight: 500;
                text-align: left;
                font-size: 17px;
                text-transform: capitalize;
                margin-bottom: 6px;
                margin-top: 15px;
              }
              ul{
                width: fit-content;
                text-align: left;
                margin: 0 auto;
                li{
                  font-size: 15px;
                  font-weight: 400;
                  span{
                    font-weight: 500;
                  }
                }
              }
            }
          }
          .btns{
            display: flex;
            width: fit-content;
            margin: 0 auto;
            grid-gap: 10px;      
            .btn{
              color: #FFF;
              padding: 6px 0;
              font-size: 15px;
              width: 120px;
              display: flex;
              justify-content: center;
              transition: all 0.2s;
              height: fit-content;
              border-radius: 5px;
              background: #2AC533;
              margin: 0 auto;
              align-items: center;
              margin-top: 15px;
              &:hover{
                background: #5CC159;
              }
              svg{
                width: 24px;
                margin-right: 10px;
              }
            }
          }
          .Step1btn{
            color: #FFF;
            padding: 8px 0;
            font-size: 15px;
            width: 110px;
            display: flex;
            justify-content: center;
            transition: all 0.2s;
            height: fit-content;
            border-radius: 5px;
            background: #2AC533;
            margin: 0 auto;
            align-items: center;
            margin-top: 20px;
            border: 0;
            outline: none;
            &:hover{
              background: #5CC159;
            }
            svg{
              width: 24px;
              margin-right: 10px;
            }
          }
          .inpt{
            width: 360px;
            margin: 0 auto;
            .itm{
              &:not(:last-of-type){
                margin-bottom: 10px;
              }
              label{
                color: #000;
                font-size: 14px;
                font-weight: 600;
                display: block;
                text-align: left;
              }
              .form-control{
                text-indent: 50px;
              }
              input{
                border-radius: 7px;
                border: 2px solid #DAE7DA;
                background: #FFF;
                display: flex;
                padding: 9px;
                align-items: center;
                width: 100%;
                color: #222;
                font-size: 13px;
                font-weight: 400;
                outline: none;
                height: 43px;
                margin-top: 6px;
                text-indent: 7px;
                transition: all 0.2s;
                &:focus{
                  border-color: #5CC159;
                }
              }
            }
          }
          .connected{
            text-align: center;
            font-size: 26px;
            margin: 0 0 15px 0;
            margin-top: 75px;
            svg{
              width: 20px;
              margin-left: 7px;
              position: relative;
              top: -1px;
              fill: #5CC159;
              height: auto;
            }
          }
          .Error{
            text-align: center;
            margin: 0;
            font-size: 14px;
            color: #F00;
            margin-top: 7px;
          }
        }
        .step2{
          .group-list{
            h3{
              color: #000;
              font-size: 20px;
              font-weight: 500;
              margin-bottom: 20px;
            }
            .groups{
              overflow-y: auto;
              padding-right: 10px;
              &::-webkit-scrollbar {
                width: 8px;
              }
              &::-webkit-scrollbar-track {
                background: #EEE;
                border-radius: 20px;
              }
              &::-webkit-scrollbar-thumb {
                background-color: #5CC159;
                border-radius: 20px;
              }
              .group{
                width: 100%;
                border-radius: 7px;
                border: 1.9px solid #DAE7DA;
                background: #FFF;
                padding: 15px 20px;
                display: flex;
                position: relative;
                &:not(:last-of-type){
                  margin-bottom: 15px;
                }
                .info{
                  display: flex;
                  .img{
                    margin-right: 10px;
                    border-radius: 50%;
                    width: 55px;
                    overflow: hidden;
                    img{
                      width: 55px;
                      height: 55px;
                    }
                  }
                  .inf{
                    padding-top: 2px;
                    p{
                      width: 370px;
                      color: #000;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 600;
                      margin: 14px 0;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;
                    }
                    img{
                      width: 25px;
                      position: relative;
                      top: -2px;
                    }
                    svg{
                      position: relative;
                      top: -1px;
                    }
                    svg, img{
                      margin-right: 7px;
                    }
                  }
                }
                .num{
                  width: fit-content;
                  display: flex;
                  margin-left: 60px;
                  align-items: center;
                  .icon{
                    border-radius: 10px;
                    background: #EAF9EA;
                    display: flex;
                    width: 40px;
                    height: 40px;
                    justify-content: center;
                    align-items: center;
                    margin: 0 auto;
                    margin-right: 10px;
                      svg{
                        width: 20px;
                        height: 20px;
                      }
                  }
                  .txt{
                    padding-top: 5px;
                      p{
                        margin: 0;
                        color: #000;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 0.9;
                        padding-top: 0;
                      }
                      span{
                        color: #99A399;
                        font-size: 14px;
                        font-weight: 400;
                      }
                  }
                }
                button{
                  font-size: 15px;
                  font-weight: 400;
                  user-select: none;
                  padding: 9px 0;
                  cursor: pointer;
                  text-align: center;
                  width: 120px;
                  color: #FFF;
                  border: 0;
                  transition: all 0.2s;
                  border-radius: 7.681px;
                  background: #5CC159;
                  position: absolute;
                  right: 20px;
                  top: 50%;
                  transform: translateY(-50%);
                  svg{
                    margin-right: 4px;
                    width: 19px;
                    height: 18px;
                    position: relative;
                    top: -1px;
                  }
                  &:hover{
                    background: #2AC533;
                  }
                  &.follow{
                    background: rgba(92, 193, 89, 0.15);
                    color: #5CC159;
                    svg{
                      display: none;
                    }
                  }
                }
              }
              .error{
                width: 100%;
                margin: 15px 0;
              }
            }
            .search_box{
              width: 100%;
              margin-bottom: 15px;
              position: relative;
              input{
                border-radius: 7px;
                border: 2px solid #DAE7DA;
                background: #FFF;
                padding: 0;
                width: 100%;
                color: #222;
                font-size: 13px;
                outline: none;
                font-weight: 400;
                height: 40px;
                text-indent: 9px;
                transition: all 0.2s;
              }
              button{
                color: #FFF;
                text-align: center;
                font-size: 13px;
                font-weight: 400;
                display: block;
                background-color: #2AC533;
                transition: all 0.2s;
                cursor: pointer;
                width: 95px;
                border-radius: 3.744px;
                border: 0.7px solid #5CC159;
                position: absolute;
                top: 3px;
                right: 3px;
                height: 34px;
              }
            }
          }
        }
        .step3{
          .field{
            width: 100%;
            margin-top: 50px;
            border-radius: 10.695px;
            border: 2.139px solid #DAE7DA;
            background: #FFF;
            overflow: hidden;
            padding-bottom: 15px;
            textarea{
              border: 0;
              width: 100%;
              outline: none;
              padding: 10px 15px;
              height: 100px;
              border-radius: 10.695px;
              font-size: 15px;
              min-height: 100px;
              resize: none;
              font-weight: 400;
              &::placeholder{
                font-weight: 400;
                font-size: 15px;
              }
            }
            .msg{
              padding: 0 15px;
              font-size: 15px;
              font-weight: 400;
              &.valid{
                color: #5CC159;
                line-height: 27px;
              }
              &.notvalid{
                color: #E72E4F;
              }
              table{
                width: 100%;
                background: #EEF4EE;
                border-radius: 8px;
                padding: 5px;
                // overflow: hidden;
                // border-radius: 5px;
                border: 1px solid #5CC159;
                thead tr{
                  th{
                    color: #5CC159;
                    font-weight: 400;
                    font-size: 13px;
                    text-align: center;
                    border: 1px solid #5cc15982;
                    &:first-of-type{
                      border-radius: 8px 0 8px 0;
                    }
                    &:last-of-type{
                      border-radius: 0 8px 0 0;
                    }
                  }
                }
                tbody tr{
                  td{
                    text-align: center;
                    font-size: 14px;
                    border: 0;
                    border: 1px solid #5cc15982;
                  }
                }
              }
            }
          }
        }
        .step4{
          form{
            padding-top: 20px;
            .inpt{
              width: 100%;
              margin-bottom: 30px;
              position: relative;
              label{
                color: #000;
                text-align: left;
                font-size: 15px;
                font-weight: 500;
                margin-bottom: 7px;
                display: block;
                width: 100%;
              }
              input{
                border-radius: 7px;
                border: 2px solid #DAE7DA;
                background: #FFF;
                display: flex;
                padding: 0 8px;
                align-items: center;
                width: 100%;
                color: #222;
                font-size: 14px;
                outline: none;
                font-weight: 500;
                height: 43px;
                margin-top: 8px;
                text-indent: 25px;
                transition: all 0.2s;
                &::placeholder{
                  font-weight: 400;
                  font-size: 14px;
                }
                &:focus{
                  border-color: #5CC159;
                }
              }
              p{
                color: #5CC159;
                font-size: 13px;
                font-weight: 400;
                margin: 0;
                margin-top: 7px;
              }
              span{
                color: #5CC159;
                font-size: 15px;
                font-weight: 600;
                position: absolute;
                top: 36px;
                height: 30px;
                width: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                left: 4px;
                background: #FFF;
              }
              .check_inpt{
                user-select: none;
                display: flex;
                margin-top: 15px;
                p{
                  margin: 0;
                  &:first-of-type{
                    margin-right: 25px;
                  }
                  [type="radio"]:checked,
                  [type="radio"]:not(:checked) {
                      position: absolute;
                      left: -9999px;
                  }
                  [type="radio"]:checked + label,
                  [type="radio"]:not(:checked) + label
                  {
                    position: relative;
                    padding-left: 32px;
                    cursor: pointer;
                    color: #000;
                    
                    font-size: 15px;
                    font-weight: 500;
                    margin: 0;
                  }
                  [type="radio"]:checked + label:before,
                  [type="radio"]:not(:checked) + label:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: -2px;
                    width: 25px;
                    height: 25px;
                    background: #FFF;
                    border-radius: 100%;
                    border: 6px solid #EDF5ED;
                  }
                  [type="radio"]:checked + label:after,
                  [type="radio"]:not(:checked) + label:after {
                    content: "";
                    width: 13px;
                    height: 13px;
                    background: #5CC159;
                    position: absolute;
                    top: 4px;
                    left: 6px;
                    border-radius: 100%;
                    transition: all 0.2s ease;
                  }
                  [type="radio"]:not(:checked) + label:after {
                      opacity: 0;
                      -webkit-transform: scale(0);
                      transform: scale(0);
                  }
                  [type="radio"]:checked + label:after {
                      opacity: 1;
                      -webkit-transform: scale(1);
                      transform: scale(1);
                  }
                }
              }
              .check_btns{
                width: 100%;
                border-radius: 7.612px;
                border: 1.522px solid #DAE7DA;
                background: #FFF;
                display: flex;
                padding: 3px;
                margin-top: 10px;
                p{
                  margin: 0;
                  width: 50%;
                  position: relative;
                  &:hover label{
                    color: #5CC159;
                  }
                  input{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    cursor: pointer;
                    opacity: 0;
                    height: 100%;
                  }
                  label{
                    width: 100%;
                    padding: 7px 0;
                    color: #99A399;
                    transition: all 0.2s;
                    
                    font-size: 13px;
                    font-weight: 400;
                    border-radius: 5px;
                    outline: none;
                    background: none;
                    margin: 0;
                    text-align: center;
                  }
                  &:hover{
                    color: #5CC159;
                    svg path{
                      fill: #5CC159;
                    }
                  }
                  input[type="radio"]:checked + label{
                    color: #FFF;
                    background-color: #5CC159;
                  }
                }
              }
            }
            button{
              margin-top: 20px;
            }
          }
        }
        .step5{
          .broker{
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px;
            margin-top: 60px;
            .item{
              position: relative;
              &:hover .spot_item {
                border-color: #5CC159;
                p{
                  color: #5CC159;
                }
              }
              input{
                position: absolute;
                top:0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
              }
              .spot_item{
                display: flex;
                padding: 10px 20px;
                cursor: pointer;
                border-radius: 5.619px;
                background: #F7F7F7;
                transition: all 0.2s;
                border: 1px solid #F7F7F7;
                img{
                  width: 30px;
                  height: 30px;
                }
                p{
                  margin: 0;
                  padding: 4px 0;
                  color: #000;
                  transition: all 0.2s;
                  font-size: 15px;
                  font-weight: 500;
                  margin-left: 6px;
                }
              }
              input[type="radio"]:checked + .spot_item{
                background: #5CC159;
                p{
                  color: #FFF;
                }
              }
            }
          }
        }
      }
    }
    .problem-pop-up .content .btns{
      button{
        border: 2px solid #E72E4F;
        background: #FFF;
        color: #E72E4F;
        &:hover{
          background-color: #E72E4F;
          color: #FFF;
        }
      }
      a{
        background: #5CC159;
        border-color: #5CC159;
        color: #FFF;
        &:hover{
          background-color: #2AC533;
        }
      }
    }
    .Telegram_login_btn{
      display: flex;
      margin-top: 50px;
      justify-content: center;
    }
    .CompleteStatus{
      width: 100%;
      height: 240px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      .sa {
        padding: 0;
        background-color: #fff;
        transform: scale(2);
        margin: 0 auto;
        width: fit-content;
        height: fit-content;
        &-success {
          border-radius: 50%;
          border: 4px solid #5cc159;
          box-sizing: content-box;
          height: 80px;
          padding: 0;
          position: relative;
          background-color: #fff;
          width: 80px;
          &:after, &:before {
            background: #fff;
            content: '';
            height: 120px;
            position: absolute;
            transform: rotate(45deg);
            width: 60px;
          }
          &:before {
            border-radius: 40px 0 0 40px;
            width: 26px;
            height: 80px;
            top: -17px;
            left: 5px;
            transform-origin: 60px 60px;
            transform: rotate(-45deg);
          }
          &:after {
            border-radius: 0 120px 120px 0;
            left: 30px;
            top: -11px;
            transform-origin: 0 60px;
            transform: rotate(-45deg);
            animation: rotatePlaceholder 4.25s ease-in;
          }
          &-placeholder {
            border-radius: 50%;
            border: 4px solid rgb(92 192 89 / 60%);
            box-sizing: content-box;
            height: 80px;
            left: -4px;
            position: absolute;
            top: -4px;
            width: 80px;
            z-index: 2;
          }
          &-fix {
            background-color: #fff;
            height: 90px;
            left: 28px;
            position: absolute;
            top: 8px;
            transform: rotate(-45deg);
            width: 5px;
            z-index: 1;
          }
          &-tip, &-long {
            background-color: #5cc159;
            border-radius: 2px;
            height: 5px;
            position: absolute;
            z-index: 2;
          }
          &-tip {
            left: 14px;
            top: 46px;
            transform: rotate(45deg);
            width: 25px;
            animation: animateSuccessTip .75s;
          }
          &-long {
            right: 8px;
            top: 38px;
            transform: rotate(-45deg);
            width: 47px;
            animation: animateSuccessLong .75s;
          }
        }
      }
    }
    @keyframes animateSuccessTip {
      0%, 54% {
        width: 0;
        left: 1px;
        top: 19px;
      }
      70% {
        width: 50px;
        left: -8px;
        top: 37px;
      }
      84% {
        width: 17px;
        left: 21px;
        top: 48px;
      }
      100% {
        width: 25px;
        left: 14px;
        top: 45px;
      }
    }
    @keyframes animateSuccessLong {
      0%, 65% {
        width: 0;
        right: 46px;
        top: 54px;
      }
      84% {
        width: 55px;
        right: 0;
        top: 35px;
      }
      100% {
        width: 47px;
        right: 8px;
        top: 38px;
      }
    }
    @keyframes rotatePlaceholder {
      0%, 5% {
        transform: rotate(-45deg);
      }
      100%, 12% {
        transform: rotate(-405deg);
      }
    }
  }
  @media (max-width: 991px){
    .progress_bot li, .telegram-bot-pop-up .pop-up-content .progress_popup li {
      width: 120px;
      &:not(:first-of-type):after {
        left: -47px;
        width: 94px;
      }
      span{
        font-size: 15px;
      }
    }
  }
  @media (max-width: 767px){
    .progress_bot li, .telegram-bot-pop-up .pop-up-content .progress_popup li {
      width: 55px;
      &:not(:first-of-type):after {
        left: -15px;
        width: 28px;
      }
      span{
        display: none;
      }
    }
    .telegram-bot-pop-up .pop-up-content{
      .step1 a {
        width: 100%;
      }
      .pop-up-title p {
        width: 100%;
      }
      .step2 .group-list .groups .group {
        display: block;
        .info{
          width: 100%;
          flex-wrap: wrap;
          .img{
            width: 100%;
            margin: 0;
            text-align: center;
          }
          .inf{
            padding-top: 10px;
            margin: 0 auto;
            display: flex;
            gap: 10px;
            p:first-of-type{
              margin-right: 0;
            }
          }
        }
        .num{
          justify-content: center;
          margin: 0 auto;
          width: fit-content;
          margin-top: 7px;
        }
        button{
          position: unset;
          transform: unset;
          margin: 0 auto;
          display: block;
          margin-top: 30px;
        }
      }
      .step5 .broker {
        grid-template-columns: repeat(2, 1fr);
      }
    } 
    .help{
      .img {
        right: -225px;
      }
      .person {
        right: 0;
      }
      .play {
        top: 2px;
        transform: translate(-50%, 0);
        z-index: 11;
        width: 50px;
        height: 50px;
      }
    } 
  }
  @media (max-width: 500px){
    .telegram-bot-pop-up .pop-up-content{
      .step5 .broker {
        grid-template-columns: repeat(1, 1fr);
      }
    } 
  }
}
// Trading View Bot
.dashboard-trading-view-bot{
  border-radius: 20px;
  border: 2px solid #DAE7DA;
  background: #FFF;
  width: 100%;
  padding: 35px 25px 45px;
  .webhook{
    background: #5CC159;
    max-width: 480px;
    margin: 50px 0;
    border-radius: 30px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    .txt{
      width: fit-content;
      padding: 55px 0 55px 25px;
      position: relative;
      z-index: 10;
      h3{
        color: #FFF;
        font-size: 21px;
        font-weight: 500;
        margin-bottom: 8px;
      }
      p{
        color: #FFF;
        font-size: 17px;
        font-weight: 300;
        margin: 0;
        width: 225px;
      }
    }
    .person{
      position: absolute;
      top: 10px;
      right: 20px;
    }
    .img{
      position: absolute;
      top: -110px;
      right: -110px;
    }
  }
  .trading-view-table{
    margin-top: 35px;
    .table{
      margin-bottom: 0;
      min-width: 1260px;
      thead tr{
        overflow: hidden;
        border-radius: 15px;
        th{
          background: #EEF4EE;
          color: #5CC159;
          font-weight: 400;
          border: 0;
          font-size: 14px;
          padding-top: 13px;
          padding-bottom: 13px;
          text-align: left;
          &:first-of-type{
            border-radius: 8px 0 0 8px;
            padding-left: 25px;
          }
          &:last-of-type{
            border-radius: 0 8px 8px 0;
            padding-right: 25px;
          }
        }
      }
      tbody tr{
        &:first-of-type td{
          padding-top: 25px;
        }
        td{
          text-align: left;
          border: 0;
          .logo{
            border-radius: 30px;
            background: #F7F7F7;
            display: flex;
            padding: 5px 20px 5px 5px;
            transition: all 0.2s;
            width: fit-content;
            img{
              width: 30px;
              height: 30px;
            }
            p{
              margin: 0;
              transition: all 0.2s;
              font-size: 14px;
              color: #000;
              text-align: center;
              font-weight: 500;
              margin-left: 6px;
              padding: 5px 0 0;
              font-family: "Roboto", sans-serif;
            }
          }
          .supported{
            border-radius: 3.74px;
            color: #FFA338;
            background: #FFF2E9;
            margin: 0;
            padding: 8px 20px;
            font-size: 12px;
            margin: 4px 0 0;
            width: fit-content;
          }
          .status{
            color: #5CC159;
            margin: 0;
            padding: 8px 20px;
            font-size: 12px;
            margin: 4px 0 0;
            width: fit-content;
            border-radius: 100px;
            background: rgba(92, 193, 89, 0.15);
            &.connected{
              color: #5CC159;
            }
            &.NotConnected{
              background: rgba(231, 46, 79, 0.15);
              color: #E72E4F;
            }
          }
          .instr{
            display: flex;
            grid-gap: 10px;
            a, button{
              color: #5CC159;
              text-align: center;
              font-size: 13px;
              font-weight: 400;
              padding: 8px 0;
              display: block;
              transition: all 0.2s;
              cursor: pointer;
              width: 180px;
              border-radius: 3.744px;
              border: 0.749px solid #5CC159;
              background: #FFF;
              &:hover{
                  background-color: #2AC533;
                  color: #FFF;
                  svg path{
                      stroke: #FFF;
                  }
              }
              svg{
                  width: 16px;
                  height: 17px;
                  margin-left: 8px;
                  path{
                      transition: all .2s;
                  }
              }
            }
          }
        }
      }
    }
  }
  .instructions-trading-view{
    .back_to{
      color: #5CC159;
      font-size: 15px;
      font-weight: 500;
      cursor: pointer;
      margin-bottom: 30px;
      &:hover{
        color: #2AC533;
        svg path{
          stroke: #2AC533;
        }
      }
      svg{
        width: 27px;
        path{
          transition: all .2s;
        }
      }
    }
    .title{
      h1{
        margin-bottom: 10px;
      }
      p{
        color: #99A399;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
      }
    }
    .inst_steps{
      width: 100%;
      margin-top: 15px;
      .inst_step{
        width: 100%;
        &:not(:last-of-type){
          margin-bottom: 40px;
        }
        h3{
          color: #000;
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 15px;
        }
        img{
          width: 700px;
        }
      }
    }
  }
  .message-generator-bot-pop-up{
    .pop-up-content{
      width: 600px;
      .JSON-msg{
        padding: 20px 30px;
        border-radius: 3px;
        background: #EEF4EE;
        pre{
          margin: 0;
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 14px;
        }
      }
      .btns{
        display: flex;
        margin-top: 35px;
        grid-gap: 10px;
        width: 100%;
        button{
          margin: 0 !important;
          font-size: 15px;
          font-weight: 400;
          user-select: none;
          padding: 9px 14px;
          cursor: pointer;
          text-align: center;
          color: #FFF;
          border: 0;
          transition: all 0.2s;
          position: relative;
          border-radius: 5px;
          background: #5CC159;
          width: 310px;
          display: block;
          &:last-of-type{
            border: 2px solid #E72E4F;
            background: #FFF;
            color: #E72E4F;
            &:hover{
              background-color: #E72E4F;
              color: #FFF;
            }
          }
          &:first-of-type{
            background: #5CC159;
            &:hover{
              background-color: #2AC533;
            }
          }
          &.copied{
            background: #DAE7DA;
            pointer-events: none;
          }
        }
      }
    }
  }
  .problem-pop-up .content .btns{
    button{
      border: 2px solid #E72E4F;
      background: #FFF;
      color: #E72E4F;
      &:hover{
        background-color: #E72E4F;
        color: #FFF;
      }
    }
    a{
      background: #5CC159;
      border-color: #5CC159;
      color: #FFF;
      &:hover{
        background-color: #2AC533;
      }
    }
  }
  @media (max-width: 767px){
    .inst_steps .inst_step img{
      width: 100% !important;
    }
  }
}
// Referral
.dashboard-referral{
  .top_ref{
    border-radius: 20px;
    border: 2px solid #DAE7DA;
    background: #FFF;
    width: 100%;
    padding: 35px 25px 45px;
    .title{
      margin-bottom: 20px;
      h1{
        margin-bottom: 10px;
      }
      p{
        color: #99A399;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
      }
    }
    .paid{
      display: flex;
      grid-gap: 40px;
      margin-bottom: 30px;
      p{
        margin: 0;
        color: #000;
        font-size: 17px;
        font-weight: 500;
        span{
          font-weight: 500;
          font-size: 17px;
          margin-left: 5px
        }
        &:first-of-type span{
          color: #5CC159;
        }
        &:last-of-type span{
          color: #FFA338;
        }
      }
    }
    .referral-steps{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 950px;
      margin: 0 auto;
      margin-top: 80px;
      overflow: hidden;
      .ref-step{
        width: 210px;
        .icon{
          border-radius: 50%;
          background: #EAF9EA;
          width: 85px;
          height: 85px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          svg{
            width: 40px;
            height: 40px;
          }
        }
        .info{
          margin-top: 23px;
          h2{
            margin-bottom: 7px;
            color: #000;
            font-size: 20px;
            text-align: center;
          }
          p{
            color: #99A399;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            width: 195px;
            margin: 0 auto;
          }
        }
      }
      .img_space{
        position: relative;
        top: 80px;
        &:first-of-type{
          svg{}
        }
        &.lst{
          svg{
            transform: rotate(180deg);
          }
        }
        svg{
          width: 140px;
        }
      }
    }
    .field-copy{
      display: flex;
      flex-wrap: wrap;
      max-width: 950px;
      border-radius: 10px;
      border: 2.741px solid #DAE7DA;
      background: #FFF;
      margin: 0 auto;
      margin-top: 80px;
      padding: 5px;
      position: relative;
      p{
        margin: 0;
        color: #99A399;
        font-size: 15px;
        font-weight: 400;
        padding: 3px 0 3px 15px;
        white-space: nowrap;
        overflow: hidden;
      }
      button{
        padding: 0 20px;
        border-radius: 5px;
        background: #5CC159;
        position: absolute;
        right: 3px;
        top: 50%;
        transform: translateY(-50%);
        color: #FFF;
        text-align: center;
        font-size: 13px;
        font-weight: 400;
        transition: all 0.2s;
        border: 0;
        height: 88%;
        &.copied{
          background: #DAE7DA;
          pointer-events: none;
        }
        &:hover{
          background: #2AC533;
        }
      }
    }
    .referral-table{
      margin-top: 50px;
      .table {
        min-width: 500px;
        thead tr{
          overflow: hidden;
          border-radius: 15px;
          th{
            background: #EEF4EE;
            color: #5CC159;
            font-weight: 400;
            border: 0;
            font-size: 14px;
            padding-top: 13px;
            padding-bottom: 13px;
            text-align: left;
            &:first-of-type{
              border-radius: 8px 0 0 8px;
              padding-left: 25px;
            }
            &:last-of-type{
              border-radius: 0 8px 8px 0;
              padding-right: 25px;
            }
          }
        }
        tbody tr{
          &:first-of-type td{
            padding-top: 25px;
          }
          td{
            text-align: left;
            font-weight: 600;
            border: 0;
            &:first-of-type{
              padding-left: 25px;
            }
            &:last-of-type{
              padding-right: 25px;
            }
          }
        }
      }
    }
  }
  .referral-rules{
    margin-top: 50px;
    border-radius: 20px;
    border: 2px solid #DAE7DA;
    background: #FFF;
    width: 100%;
    padding: 30px 25px 45px;
    h2{
      color: #000;
      font-size: 21px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    .rules{
      p{
        margin: 0;
        position: relative;
        padding-left: 15px;
        color: #000;
        font-size: 15px;
        font-weight: 500;
        &:not(:last-of-type){
          margin-bottom: 17px;
        }
        &:after{
          content: "";
          position: absolute;
          background-color: #5CC159;
          top: 9px;
          left: 0;
          width: 5px;
          height: 5px;
          border-radius: 50%;
        }
      }
    }
  }
  @media (max-width: 991px){
    .top_ref{
      .referral-steps{
    grid-gap: 30px;
    .img_space{
      display: none;
    }
    .ref-step{
      width: 100%;
    }
      }
      .field-copy{
      margin-top: 60px;
      }
    }
  }
}
// Withdrawal
.dashboard-withdrawal{
  .withdrawal-content{
    border-radius: 20px;
    border: 2px solid #DAE7DA;
    background: #FFF;
    width: 100%;
    padding: 35px 25px 45px;
    .title {
      margin-bottom: 0;
    }
    .balance{
      margin-bottom: 30px;
      margin: 0;
      color: #000;
      font-size: 17px;
      font-weight: 500;
      span{
        font-weight: 500;
        font-size: 17px;
        margin-left: 15px;
        color: #5CC159;
      }
    }
    form{
      padding-top: 45px;
      .inpt{
        width: 100%;
        margin-bottom: 20px;
        position: relative;
        label{
          color: #000;
          text-align: left;
          font-size: 15px;
          font-weight: 500;
          margin-bottom: 7px;
          display: block;
          width: 100%;
        }
        input{
          border-radius: 7px;
          border: 2px solid #DAE7DA;
          background: #FFF;
          display: flex;
          padding: 0 8px;
          align-items: center;
          width: 100%;
          color: #222;
          font-size: 14px;
          outline: none;
          font-weight: 500;
          height: 43px;
          margin-top: 8px;
          text-indent: 4px;
          transition: all 0.2s;
          &::placeholder{
            font-weight: 400;
            font-size: 14px;
          }
          &:focus{
            border-color: #5CC159;
          }
        }
        &.am input{
          text-indent: 25px;
        }
        p{
          color: #5CC159;
          font-size: 13px;
          font-weight: 400;
          margin: 0;
          margin-top: 7px;
        }
        span{
          color: #5CC159;
          font-size: 15px;
          font-weight: 600;
          position: absolute;
          top: 37px;
          height: 30px;
          width: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          left: 4px;
          background: #FFF;
        }
      }
      button{
        margin-top: 20px;
      }
      .rules{
        margin-top: 40px;
        p{
          margin: 0;
          position: relative;
          padding-left: 15px;
          color: #000;
          font-size: 15px;
          font-weight: 500;
          &:not(:last-of-type){
            margin-bottom: 17px;
          }
          &:after{
            content: "";
            position: absolute;
            background-color: #5CC159;
            top: 9px;
            left: 0;
            width: 5px;
            height: 5px;
            border-radius: 50%;
          }
        }
      }
      button{
        font-size: 15px;
        font-weight: 400;
        user-select: none;
        padding: 10px 100px;
        cursor: pointer;
        text-align: center;
        color: #FFF;
        border: 0;
        transition: all 0.2s;
        border-radius: 7.681px;
        background: #5CC159;
        opacity: 0.8;
        pointer-events: none;
        width: fit-content;
        margin-top: 80px;
        &.active{
          opacity: 1;
          pointer-events: visible;
        }
        &:hover{
          background: #2AC533;
        }
      }
    }
  }
  .withdrawal-table{
    border-radius: 20px;
    border: 2px solid #DAE7DA;
    background: #FFF;
    width: 100%;
    padding: 35px 25px 45px;
    margin-top: 30px;
    .table {
      min-width: 500px;
      thead tr{
        overflow: hidden;
        border-radius: 15px;
        th{
          background: #EEF4EE;
          color: #5CC159;
          font-weight: 400;
          border: 0;
          font-size: 14px;
          padding-top: 13px;
          padding-bottom: 13px;
          text-align: left;
          &:first-of-type{
            border-radius: 8px 0 0 8px;
            padding-left: 25px;
          }
          &:last-of-type{
            border-radius: 0 8px 8px 0;
            padding-right: 25px;
          }
        }
      }
      tbody tr{
        &:first-of-type td{
          padding-top: 25px;
        }
        td{
          text-align: left;
          border: 0;
          font-weight: 600;
          &:first-of-type{
            padding-left: 25px;
          }
          &:last-of-type{
            padding-right: 25px;
          }
          .status{
            margin: 0;
            font-size: 12px;
            font-weight: 400;
            padding: 5px 2px;
            border-radius: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: auto;
            position: relative;
            top: 0;
            width: 115px;
            &.approved{
              color: #5CC159;
              background: rgba(92, 193, 89, 0.15);
            }
            &.progress{
              color: #FFA338;
              background: #FFF2E9;
            }
            &.declined{
              background: rgba(231, 46, 79, 0.15);
              color: #E72E4F;
            }
          }
        }
      }
    }
  }
}

button.tgme_widget_login_button{
  background: #5CC159 !important;
}



.react-tel-input .form-control:focus {
  border-color: #2AC533 !important;
  box-shadow: none !important;
}
.react-tel-input .selected-flag:focus:before, .react-tel-input .selected-flag.open:before {
  border-color: transparent !important;
  box-shadow: none !important;
}
.react-tel-input{
  .flag-dropdown.open {
    z-index: 500 !important;
  }
}
.country-list .search-box{
  padding: 0 !important;
  text-indent: 7px !important;
}
.dashboard-settings .profile-settings .settings_form .inpt .phone .field input{
  padding: 0 45px;
}

button.active{
  opacity: 1 !important;
  pointer-events: visible;
  cursor: pointer !important;
}


button:disabled {
  background: #6de173a3 !important;
  cursor:not-allowed !important; 
}
// Phone input
.country-list{
  position: fixed !important;
  border-radius: 4px !important;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #EEE;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #5CC159;
    border-radius: 20px;
  }
  .search{
    padding: 10px !important;
    .search-box{
      margin: 0 !important;
      height: 35px !important;
      text-transform: capitalize !important;
      font-size: 13px !important;
      border-radius: 3px !important;
    }
  } 
} 
.react-tel-input .selected-flag {
  background: #d9d9d92e !important;
  border-radius: 7px 0 0 5px !important;
}

.lodaingSVG{
  width: 18px;
  height: 18px;
  margin-left: 10px;
  position: relative;
  top: -1px;
}