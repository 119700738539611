.back{
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  border: 0;
  background: none;
  &:hover svg path{
    stroke: #5CC159;
  }
  svg path{
    transition: all .2s;
  }
}
.right_cov{
  min-height: calc(100vh - 50px);
  border-radius: 20px;
  background: #5CC159;
  padding: 80px 90px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  .content{
    margin: 0 auto;
    display: block;
    max-width: fit-content;
    width: 100%;
    text-align: center;
    h3{
      color: #FFF;
      
      font-size: 30px;
      font-weight: 400;
    }
    p{
      margin: 0;
      color: #FFF;
      
      font-size: 16px;
      font-weight: 300;
    }
    .dash_img{
      padding: 20px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.72) 0%, #FFF 100%), #FFF;
      border-radius: 26px;
      box-shadow: 0px 0.7233213782px 5.424908638px 0px rgba(0, 0, 0, 0.05), -0.9041516185px -0.9041516185px 4.5207571983px 0px #BDC5D4 inset, 2.6128041744px 0.9041516185px 10.4512166977px 0px #EEE inset;
      max-width: 550px;
      max-height: 370px;
      margin: 0 auto;
      margin-top: 50px;
      width: 100%;
      height: auto;
      -webkit-animation: mover_log 1s infinite  alternate;
      animation: mover_log 1s infinite  alternate;
      box-shadow: 0px 0.7233213782310486px 5.424908638000488px 0px rgba(0, 0, 0, 0.05), -0.9041516184806824px -0.9041516184806824px 4.52075719833374px 0px #BDC5D4 inset, 2.6128041744232178px 0.9041516184806824px 10.451216697692871px 0px #EEE inset;
      img{
          width: 550px;
          height: 370px;
      }
      @keyframes mover_log {
          0% { transform: translateY(0); }
          100% { transform: translateY(-10px); }
      }
      img{
          width: 100%;
          height: 100%;
      }
    }
  }
  @media (max-width: 1200px){
    &{
      padding: 80px 30px;
    }
  }
  @media (max-width: 991px){
    &{
      display: none;
    }
  }
}
form{
  max-width: 415px;
  width: 100%;
  margin: 0 auto;
  display: block;
  padding-top: 120px;
  .top_part{
    position: relative;
    img{
      margin: 0 auto;
      display: block;
      width: 70px;
    }
    h1{
      color: #3F4448;
      text-align: center;
      
      font-size: 22px;
      font-weight: 600;
      margin-top: 20px;
      margin-bottom: 5px;
    }
    p{
      color: #A2ABA2;
      text-align: center;
      
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }
  }
  .btn_sign{
    border-radius: 10.695px;
    background: #5CC159;
    display: flex;
    padding: 12px 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 0;
    
    color: #FFF;
    font-weight: 400;
    font-size: 16px;
    user-select: none;
    margin-top: 25px;
    transition: all .2s;
    &:hover{
      background: #2AC533;
    }
    &:disabled {
      background: #6de173a3;
      cursor:not-allowed; 
    }
  }
  .qs_sign{
    margin-top: 15px;
    color: #869586;
    text-align: center;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    a{
      color: #5CC159;
      font-weight: 500;
      cursor: pointer;
    }
  }
  @media (max-width: 991px){
    &{
      padding-top: 70px;
    }
  }
}
.wrong{
  border-radius: 10.7px;
  background: #FFF2E9;
  position: relative;
  padding: 15px 10px 15px 40px;
  margin-bottom: 15px;
  p{
    margin: 0;
    color: #99A399;
    
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }
  svg{
    position: absolute;
    top: 12px;
    left: 9px;
  }
}
.show_pwd{
  position: absolute;
  right: 10px;
  bottom: 12px;
  cursor: pointer;
  &:hover svg path{
    fill: #5CC159;
  }
  svg path{
    transition: all .2s;
  }
}
.log_content{
  width: 50%;
  position: relative;
  @media (max-width: 991px){
    &{
      width: 100%;
    }
  }
}
.verify{
  border-radius: 10.7px;
  border: 2px solid #DAE7DA;
  background: #FFF;
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  user-select: none;
  p{
    position: relative;
    color: #5CC159;
    
    font-size: 14px;
    font-weight: 500;
    padding-left: 15px;
    margin: 0;
    transition: all .2s;
    &:not(:last-of-type){
      margin-bottom: 8px;
    }
    &:after{
      content: "•";
      position: absolute;
      left: 0;
      top: 0;
    }
    &.no{
      color: #E72E4F;
    }
  }
}
// Sign up & Sign In
.signup,.signin{
  width: 100%;
  min-height: 100vh;
  color: #000;
  display: flex;
  padding: 25px;
  background-color: #FFF !important;
  .log_content{
    form{
      .top_part{
        .sign_with{
          width: 100%;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 15px;
          margin-top: 30px;
          button{
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10.695px;
            border: 1.07px solid #DAE7DA;
            background: #FFF;
            padding: 9px 0;
            transition: all .2s;
            svg{
              width: 28px;
              height: 28px;
            }
            &:hover{
              border-color: #5CC159;
            }
          }
        }
        .or{
          width: 100%;
          position: relative;
          margin-top: 23px;
          p{
            text-transform: uppercase;
            color: #A2ABA2;
            text-align: center;
            
            font-size: 15px;
            font-weight: 400;
            background: #FFF;
            z-index: 5;
            position: relative;
            width: fit-content;
            margin: 0 auto;
            padding: 0 18px;
          }
          &:after{
            content: "";
            position: absolute;
            top: 49%;
            left: 0;
            transform: translateY(-50%);
            width: 100%;
            height: 1.07px;
            background: #DAE7DA;
          }
        }
      }
      .form_part{
        width: 100%;
        margin-top: 20px;
        .inpt{
          margin-bottom: 15px;
          position: relative;
          label{
            color: #000;
            
            font-size: 16px;
            font-weight: 600;
            display: block;
            text-align: left;
          }
          input{
            border-radius: 10.695px;
            border: 2px solid #DAE7DA;
            background: #FFF;
            display: flex;
            padding: 10px;
            align-items: center;
            width: 100%;
            color: #222;
            font-size: 15px;
            
            font-weight: 400;
            outline: none;
            height: 49px;
            margin-top: 8px;
            text-indent: 7px;
            transition: all .2s;
            &:focus{
              border-color: #5CC159;
            }
          }
        }
        .pr_tu{
          margin-top: 10px;
          color: #000;
          
          font-size: 15px;
          font-weight: 500;
          line-height: normal;
          padding-left: 30px;
          position: relative;
          width: fit-content;
          a{
            color: #5CC159;
            text-decoration-line: underline;
            margin-left: 3px;
            cursor: pointer;
            transition: all .2s;
            z-index: 70;
            width: fit-content;
            position: relative;
            &:hover{
              color: #2AC533;
            }
          }
          .box{
            content: "";
            position: absolute;
            top: 0;
            left: 3px;
            height: 19px;
            width: 19px;
            border-radius: 3.209px;
            border: 2.139px solid #DAE7DA;
            transition: all .2s;
            display: flex;
            justify-content: center;
            align-items: center;
            svg{
              width: 13px;
              height: 13px;
              transition: all .2s;
              opacity: 0;
            }
          }
          input{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 60;
            height: 100%;
            cursor: pointer;
            opacity: 0;
          }
          input[type="checkbox"]:checked + .box{
            border-color: #5CC159;
            svg{
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
.signin .log_content{
  .check_forget{
    overflow: hidden;
    margin-top: 10px;
    .pr_tu {
      width: fit-content !important;
      float: left !important;
      margin: 0 !important;
      input{
        height: 100%;
      }
    }
    a{
      color: #5CC159;
      text-align: center;
      
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      text-decoration-line: underline;
      float: right;
    }
  }
  
}
// OTP
.otp-check{
  background-color: #FFF !important;
  width: 100%;
  min-height: 100vh;
  color: #000;
  display: flex;
  padding: 25px;
  .otp-content{
    width: 50%;
    position: relative;
    form{
      .otp_section{
        width: 100%;
        margin-top: 50px;
        margin-bottom: 15px;
        &.error{
          div input{
            border-color: #E72E4F !important;
          }
        }
        div{
          justify-content: space-between;
          input{
            width: 60px !important;
            border-radius: 10.695px;
            border: 2.139px solid #DAE7DA;
            background: #FFF;
            height: 55px;
            
            font-weight: 500;
            font-size: 19px;
            transition: all .2s;
            outline: none;
            &::placeholder{
              color: #DAE7DA;
            }
            &:focus{
              border-color: #5CC159 !important;
            }
            @media (max-width: 500px){
              &{
                width: 50px !important;
                height: 50px !important;
              }
            }
          }
        }
      }
    }
    @media (max-width: 991px){
      &{
        width: 100%;
      }
    }
  }
}
// Password
.reset-password,.new-password{
  background-color: #FFF !important;
  width: 100%;
  min-height: 100vh;
  color: #000;
  display: flex;
  padding: 25px;
  .form_part {
    margin-top: 60px;
    .inpt{
      margin-bottom: 15px;
      position: relative;
      label{
        color: #000;
        
        font-size: 16px;
        font-weight: 600;
        display: block;
        text-align: left;
      }
      input{
        border-radius: 10.695px;
        border: 2px solid #DAE7DA;
        background: #FFF;
        display: flex;
        padding: 10px;
        align-items: center;
        width: 100%;
        color: #222;
        font-size: 15px;
        
        font-weight: 400;
        outline: none;
        height: 49px;
        margin-top: 8px;
        text-indent: 7px;
        transition: all .2s;
        &:focus{
          border-color: #5CC159;
        }
      }
    }
  }
}
