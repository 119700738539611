.darkMode{
  background: #092108;
  .dark{
    background: #092108;
    .dashboard_menu{
      background: #153414;
      .links li a {
        color: #FFF;
        svg path{
          fill: #FFF;
        }
      }
      .lst_prt h3 {
        color: #FFF;
      }
      .close svg path {
        stroke: #FFF;
      }
    }
    .dashboard_content-section{
      .dash_nav{
        background: #092108;
        .lef-sec{
          .open_menu svg path{
            fill: #FFF;
          }
          .welcome h2 {
            color: #FFF;
          }
        } 
        .righ_sec{
          .notification .top_prt {
            background: #092108;
            svg path{
              fill: #FFF;
            }
            &:after {
              border: 1px solid #092108;
            }
          }
          .user-profile{
            .top_prof{
              .down_icon path {
                fill: #FFF;
              }
              .use_info h3 {
                color: #FFF;
              }
            }
            .down_prof {
              border: 2.355px solid #153414;
              background: #153414;
              .user_info .use_info h3 {
                color: #FFF;
              }
              .mode_btns {
                border: 1.522px solid #425F42;
                background: #153414;
                button {
                  color: #FFF;
                  svg path{
                    fill: #FFF;
                  }
                }
              }
              .links li{
                &:last-of-type a svg path{
                  fill: #E72E4F;
                }
                .lang{
                  .top {
                    color: #FFF;
                    svg path{
                      fill: #FFF;
                    }
                  }
                  .down_list p {
                    color: #FFF;
                  }
                } 
                a {
                  color: #FFF;
                  svg path{
                    fill: #FFF;
                  }
                }
              } 
            }
          } 
        }     
      }
      .pages_content{
        padding: 40px;
        background: #092108;
      }
    }
    .title{
      margin-bottom: 45px;
      h1{
        color: #FFF;
      }
      .links{
        li a{
          color: #FFF;
          &.active, &.default-active{
            color: #2AC533;
            border-bottom-color: #2AC533;
          }
          &:hover{
            color: #2AC533;
          }
        }
      }
    }
    .title_sec {
      overflow: hidden;
      width: 100%;
      h2{
        color: #FFF !important;
      }
    }
    .select_filter {
      border: 1px solid #425F42;
      color: #FFF;
    }
    .table-bor .table{
      background: #092108;
      thead tr th {
        background: rgba(92, 193, 89, 0.15) !important;
        color: #5CC159 !important;
      }
      tbody tr td {
        color: #FFF !important;
        background: #092108;
      }
    }
    .congratulations-pop-up,.problem-pop-up{
      background: rgba(0, 0, 0, 0.3);
      .content{
        background: #153414;
        svg{
          margin: 0 auto;
          display: block;
          width: 100px;
        }
        h1{
          color: #FFF;
        }
        .close svg{
          path{
            transition: all .2s;
            stroke: #FFF;
          }
        }
      }
    }
    .problem-pop-up .btns{
      button, a{
        &:last-of-type{
          border: 2px solid #E72E4F;
          background: #153414;
          color: #E72E4F;
          &:hover{
            background-color: #E72E4F;
            color: #FFF;
          }
        }
        &:first-of-type{
          background: #5CC159;
          &:hover{
            background-color: #2AC533;
          }
        }
      }
    }
    .wrong-input{
      border: 1.25px solid #E72E4F;
      background: rgba(231, 46, 79, 0.15);
    }
    .pop-up .pop-up-content{
      border: 1px solid #153414;
      background: #153414;
      .pop-up-title{
        h1{
          color: #FFF;
        }
        .close{
          svg path{
            transition: all .2s;
            stroke: #FFF;
          }
        }
      }
      form{
        .inpt input {
          border: 2px solid #425F42;
          background: #153414;
        }
      } 
    }
    // Home
    .dashboard-home .trades_progress {
      border: 2px solid #DAE7DA;
      background: #092108;
      .title_sec .total_unrealized {
        color: #FFF;
      }
    }
    // Analytics
    .dashboard-analytics {
      border: 2px solid #425F42;
      background: #092108;
      .top_boxes .infos {
        border: 2px solid #425F42;
        background: #092108;
        p span:last-of-type {
          color: #FFF;
        }
      }
      .followers_progress {
        border: 2px solid #425F42;
        background: #092108;
        .recharts-responsive-container{
          line{
            stroke: #092108 !important;
          }
          text{
            fill: #FFF !important;
          }
        }
      }
      .stats {
        border: 2px solid #425F42;
        background: #092108;
        .recharts-responsive-container{
          line{
            stroke: rgba(237, 245, 237, 0.10) !important;
          }
          text{
            fill: #FFF !important;
          }
        }
      }
      .statistics {
        border: 2px solid #425F42;
        background: #092108;
        .table{
          background: #092108;
          thead tr th {
            background: #092108;
            color: #99A399;
          }
          tbody tr td {
            color: #FFF;
            background: #092108;
          }
        }
      }
      .trades_history {
        border: 2px solid #425F42;
        background: #092108;
      }
    }
    // Marketplace
    .dashboard-marketplace{
      .top_traders .item {
        border: 2px solid #425F42;
        background: #092108;
        .info{
          h1 {
            color: #FFF;
          }
          .nums .num{
            .txt p {
              color: #FFF;
            }
            .icon {
              background: #425F42;
            }
          } 
        }
        button.follow:after {
          background-color: rgb(0 0 0 / 49%);
        }
        
      }
      .pop-up .pop-up-content {
        border: 2px solid #153414;
        background: #153414;
        *{
          color: #FFF !important;
        }
        .prt_one,.prt_two{
          .inpt{
            label{
              color: #FFF;
            }
            .check_inpt p{
              margin: 0;
              [type="radio"]:checked + label:before,
              [type="radio"]:not(:checked) + label:before {
                background: #153414;
                border: 6px solid #425F42;
              }
            }
            .check_btns{
              border: 1.522px solid #425F42;
              background: #153414;
            }
            .items{
              .item{
                border: 2px solid #425F42;
                background: #153414;
                svg path{
                  fill: #FFF;
                }
              }
            }
          }
          .amount{
            overflow: hidden;
            p:first-of-type{
              color: #99A399 !important;
            }
          }
        }
      }
    } 
    // Subscription
    .dashboard-subscription {
      border: 2px solid #425F42;
      background: #092108;
      .subscription-pricing{
        h2 {
          color: #FFF;
        }
        .date_btns {
          border: 1.522px solid #425F42;
          background: #092108;
          button{
            color: #FFF;
          }
        }
        .plans .plan {
          border: 2px solid #425F42;
          background: #092108;
          &:nth-of-type(2) {
            border: 1.142px solid #5CC159 !important;
            background: #5CC159 !important;
          }
          .top_sec-plan h3 {
            color: #FFF;
          }
          .list li p {
            color: #FFF;
          }
        }
      } 
      .subscription-billing {
        .payment_methods{
          h2 {
            color: #FFF;
            font-size: 20px;
          }
          .add_box,.methods_added{
            .item{
              border: 2px solid #425F42;
              background: #092108;
              .icon svg path{
                fill: #FFF;
              }
              .info p{
                color: #FFF;
              }
              .add{
                border: 2px solid #425F42;
                background: #092108;
              }
            }
          } 
        } 
      }
      .payment-pop-up{
        .pop-up-content{
          *{
            color: #FFF !important;
          }
          form{
            .inpt{
              p label svg path{
                fill: #FFF;
              }
              .check_inpt{
                [type="radio"]:checked + label:before,
                [type="radio"]:not(:checked) + label:before {
                  background: #153414;
                  border: 6px solid #425F42;
                }
              }
            }
            .inpts{
              display: flex;
              grid-gap: 15px;
              .inpt{
                width: 50%;
              }
            }
            .submit{
              
              font-size: 15px;
              font-weight: 400;
              user-select: none;
              width: 100%;
              padding: 9px 15px;
              cursor: pointer;
              border-radius: 5.015px;
              background: #5CC159;
              text-align: center;
              color: #FFF;
              border: 0;
              margin-top: 30px;
              transition: all 0.2s;
              position: relative;
              &:hover{
                background: #2AC533;
              }
            }
            .item{
              max-width: 100%;
              border-radius: 5px;
              border: 2px solid #DAE7DA;
              background: #FFF;
              padding: 12px 65px 12px 15px;
              display: flex;
              position: relative;
              transition: all .2s;
              margin-top: 30px;
              margin-bottom: 60px;
              &:hover{
                border-color: #2AC533;
              }
              &:not(:last-of-type){
                margin-bottom: 15px;
              }
              .icon{
                svg{
                  width: 28px;
                  height: 28px;
                  margin: 10px 0;
                }
              }
              .info{
                padding-left: 10px;
                span{
                  color: #99A399;
                  text-align: left;
                  
                  font-size: 14px;
                  font-weight: 400;
                }
                p{
                  color: #000;
                  text-align: left;
                  
                  font-size: 16px;
                  font-weight: 500;
                  margin: 0;
                }
              }
              .options{
                position: absolute;
                right: 5px;
                top: 50%;
                transform: translateY(-50%);
                button{
                  border: 0;
                  background: none;
                  outline: none;
                  padding: 0 5px;
                  &:hover svg path{
                    fill: #5CC159;
                  }
                  svg{
                    width: 20px;
                    height: 20px;
                    path{
                      transition: all .2s;
                    }
                  }
                }
              }
              .add{
                padding: 9px 15px;
                border-radius: 5px;
                border: 2px solid #DAE7DA;
                background: #FFF;
                position: absolute;
                right: 5px;
                top: 50%;
                transform: translateY(-50%);
                color: #99A399;
                text-align: center;
                
                font-size: 12px;
                font-weight: 400;
                text-transform: uppercase;
                transition: all 0.2s;
                &:hover{
                  color: #2AC533;
                  border-color: #2AC533;
                }
              }
            }
            .btns{
              display: flex;
              margin-top: 35px;
              grid-gap: 10px;
              button{
                
                font-size: 15px;
                font-weight: 400;
                user-select: none;
                width: 100%;
                padding: 9px 15px;
                cursor: pointer;
                border-radius: 5.015px;
                background: #5CC159;
                text-align: center;
                color: #FFF;
                border: 0;
                margin-top: 30px;
                transition: all 0.2s;
                width: 50%;
                position: relative;
                &:hover{
                  background: #2AC533;
                }
                &:last-of-type{
                  border: 2px solid #E72E4F;
                  background: #FFF;
                  color: #E72E4F;
                  &:hover{
                    background-color: #E72E4F;
                    color: #FFF;
                  }
                }
                &:first-of-type{
                  background: #5CC159;
                  &:hover{
                    background-color: #2AC533;
                  }
                }
              }
            }
            .item{
              border: 2px solid #425F42;
              background: #153414;
              .icon svg path{
                fill: #FFF;
              }
              .info p{
                color: #FFF;
              }
              .add{
                border: 2px solid #425F42;
                background: #153414;
              }
            }
            .btns{
              button, a{
                &:last-of-type{
                  border: 2px solid #E72E4F;
                  background: #153414;
                  color: #E72E4F;
                  &:hover{
                    background-color: #E72E4F;
                    color: #FFF;
                  }
                }
                &:first-of-type{
                  background: #5CC159;
                  &:hover{
                    background-color: #2AC533;
                  }
                }
              }
            }
          }
        }
      }
      .subscription-promo-codes .code_btn{
        .inpt input {
          border: 2px solid #425F42;
          background: #153414;
          color: #FFF;
        }
        .done{
          background: rgba(92, 193, 89, 0.15);
        }
      } 
    }
    // Settings
    .dashboard-settings {
      border: 2px solid #425F42;
      background: #092108;
      .profile-settings, .security-settings{
        .settings_form{
          .inpt{
            label{
              color: #FFF;
            }
            input, textarea{
              border: 2px solid #425F42;
              background: #153414;
              color: #FFF;
            }
            .phone{
              display: flex;
              grid-template-columns: repeat(2, 1fr);
              grid-gap: 10px;
              .code{
                .code_country{
                  border: 2.139px solid #425F42;
                  background: #092108;
                  .MuiSelect-select{
                    color: #FFF;
                  }
                }
              }
            }
          }
          .profile_img{
            width: fit-content;
            .up_img{
              .up_box{
                border: 2px dashed #425F42;
                background: #092108;
              }
            }
          }
          .btns{
            button, a{
              &:last-of-type{
                border: 2px solid #E72E4F;
                background: #092108;
                color: #E72E4F;
                &:hover{
                  background-color: #E72E4F;
                  color: #FFF;
                }
              }
              &:first-of-type{
                background: #5CC159;
                &:hover{
                  background-color: #2AC533;
                }
              }
            }
          }
          .switch{
            .check{
              input[type="checkbox"] {
                background: #425F42;
              }
              input[type="checkbox"]::before {
                background: #99A399;
              }
              input[type="checkbox"]:checked::before {
                left: 43px;
                background: #fff;
              }
              input[type="checkbox"]:checked {
                background: #5CC159;
              }
              p{
                color: #FFF;
              }
            }
            .hide_box{
              margin-top: 30px;
              .check_inpt{
                user-select: none;
                display: flex;
                p{
                  [type="radio"]:checked + label,
                  [type="radio"]:not(:checked) + label
                  {
                    color: #FFF;
                  }
                  [type="radio"]:checked + label:before,
                  [type="radio"]:not(:checked) + label:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: -2px;
                    width: 25px;
                    height: 25px;
                    background: #092108;
                    border-radius: 100%;
                    border: 6px solid #425F42;
                  }
                }
              }
              .inpt{
                span{
                  color: #99A399;
                  background: #153414;
                }
              }
            }
            .qr_box p{
              color: #FFF;
            }
          }
          .select_filter{
            border: 2px solid #425F42;
            color: #FFF;
          }
        }
        .verify-number-popup{
          .pop-up-content{
            width: 525px;
            .pop-up-title{
              margin: 0;
            }
            form{
              margin: 0;
              padding: 0;
              h1{
                color: #FFF;
              }
              .otp_section{
                width: 100%;
                margin: 45px 0 0;
                input {
                  border: 2.139px solid #425F42 !important;
                  background: #092108 !important;
                }
              }
            }
            @media (max-width: 991px){
              &{
                width: 100%;
              }
            }
          }
        }
      }
    }
    // Exchange
    .dashboard-exchange {
      border: 2px solid #425F42;
      background: #092108;
      form .cont .inpt{
        label {
          color: #FFF;
        }
        .select_filter {
          border: 2px solid #425F42;
          color: #FFF;
        }
      } 
      .exchange-table{
        .table{
          tbody tr{
            td{
              text-align: left;
              .logo{
                background: rgba(92, 193, 89, 0.15);
                p{
                  margin: 0;
                  padding: 0;
                  transition: all 0.2s;
                  font-size: 14px;
                  color: #FFF;
                  text-align: center;
                  font-weight: 500;
                  margin-left: 6px;
                  position: relative;
                  top: 0px;
                }
              }
              .switch{
                input[type="checkbox"] {
                  background: rgba(92, 193, 89, 0.15);
                }
                input[type="checkbox"]::before {
                  background: #99A399;
                }
                input[type="checkbox"]:checked::before {
                  left: 37px;
                  background: #fff;
                }
                input[type="checkbox"]:checked {
                  background: #5CC159;
                }
              }
              p{
                color: #FFF;
              }
            }
          }
        }
      }
      .exchange-pop-up{
        .pop-up-content{
          .exchange_content{
            .links{
              li{
                color: #FFF;
                border-bottom: 2px solid transparent;
                &.active{
                  color: #5CC159;
                  border-bottom-color: #5CC159;
                }
                &:hover{
                  color: #5CC159;
                }
              }
            }
            .spot, .usdt-m{
              .item{
                input{
                  position: absolute;
                  top:0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  opacity: 0;
                  cursor: pointer;
                }
                .spot_item{
                  background: rgba(92, 193, 89, 0.15);
                  border: none;
                  p{
                    color: #FFF;
                  }
                }
              }
            }
            .key{
              p{
                color: #FFF;
              }
              .steps{
                border: 2px solid #425F42;
                background: #153414;
                .step{
                  &:not(:last-of-type){
                    margin-bottom: 15px;
                  }
                  .api{
                    background: rgba(92, 193, 89, 0.15);
                  }
                }
              }
            }
            form{
              .inpt{
                label{
                  color: #FFF;
                }
                input{
                  border: 2px solid #425F42;
                  background: #153414;
                  color: #FFF;
                }
              }
            }
          }
        }
      }
      .editAPI-pop-up{
        .pop-up-content{
          form{
            .inpt{
              label{
                color: #FFF;
              }
              input{
                border: 2px solid #425F42;
                background: #153414;
                color: #FFF;
              }
            }
            .btns{
              button, a{
                &:last-of-type{
                  border: 2px solid #E72E4F;
                  background: #153414;
                  color: #E72E4F;
                  &:hover{
                    background-color: #E72E4F;
                    color: #FFF;
                  }
                }
                &:first-of-type{
                  background: #5CC159;
                  &:hover{
                    background-color: #2AC533;
                  }
                }
              }
            }
          }
        }
      }
    }
    // Telegram Bot
    .dashboard-telegram-bot {
      // border: 2px solid #425F42;
      background: #092108;
      .links li{
        color: #FFF;
        &.active{
          color: #2AC533;
        }
      }
      .added_channels,.add_new-channel{
        background: #092108;
        border-color: #425F42;
      }
      .connected{
        color: #2AC533;
      }
      .search_box input{
        color: #FFF !important;
        background: #092108 !important;
        border-color: #425F42 !important;
      }
      table{
        background: #092108;
        thead tr th {
          background: rgba(92, 193, 89, 0.15) !important;
          color: #5CC159 !important;
        }
        tbody tr{
          &:hover td{
            background: rgba(92, 193, 89, 0.15) !important;
          }
          td {
            color: #FFF !important;
            background: #092108;
          }
        }
      }
      .progress_bot{
        li{
          &:not(:first-of-type){
            &:after{
              background: #99A399;
            }
          }
          p{
            color: #99A399;
            border: 3px solid #99A399;
          }
          span{
            color: #99A399;
          }
          &.prt_done{
            &:after{
              background: #5CC159;
            }
            p{
              color: #5CC159;
              border-color: #5CC159;
              background: #5CC159;
              svg{
                opacity: 1;
              }
            }
          }
          &.prt_now{
            &:after{
              background: #5CC159;
            }
            p{
              border-color: #5CC159;
              color: #5CC159;
            }
          }
        }
      }
      .bot_steps .step {
        border: 2px solid #425F42;
        background: #092108;
        &:hover{
          p{
            color: #5CC159;
          }
          .down path{
            stroke: #5CC159;
          }
          span{
            border-color: #5CC159;
            color: #5CC159;
          }
        }
        p {
          color: #FFF;
        }
        .down path {
          stroke: #FFF;
        }
      }
      .telegram-bot-pop-up{
        .pop-up-content{
          .close{
            svg path{
              transition: all .2s;
              stroke: #FFF;
            }
          }
          .pop-up-title{
            h1{
              color: #FFF;
            }
            p{
              color: #99A399;
            }
          }
          .step2{
            .group-list{
              h3{
                color: #FFF;
                font-size: 20px;
                font-weight: 500;
                margin-bottom: 20px;
              }
              .groups{
                .group{
                  border: 2px solid #425F42;
                  background: #153414;
                  .info{
                    .inf{
                      p:first-of-type{
                        color: #FFF;
                      }
                    }
                  }
                  .num{
                    .icon{
                      background: rgba(92, 193, 89, 0.15);
                    }
                    .txt{
                      p{
                        color: #FFF;
                      }
                    }
                  }
                }
              }
            }
          }
          .step3{
            .field{
              border: 2.139px solid #425F42;
              background: #153414;
              textarea{
                color: #FFF;
                background: #153414;
              }
              .msg{
                padding: 0 15px;
                font-size: 15px;
                font-weight: 400;
                &.valid{
                  color: #5CC159;
                  line-height: 27px;
                }
                &.notvalid{
                  color: #E72E4F;
                }
              }
            }
          }
          .step4{
            form{
              .inpt{
                label{
                  color: #FFF;
                }
                input{
                  border: 2px solid #425F42;
                  background: #153414;
                  color: #FFF;
                  &:focus{
                    border-color: #5CC159;
                  }
                }
                span{
                  background: #153414;
                }
                .check_btns{
                  border: 1.522px solid #425F42;
                  background: #153414;
                  label{
                    color: #FFF !important;
                  }
                }
                .check_inpt{
                  user-select: none;
                  display: flex;
                  p{
                    [type="radio"]:checked + label,
                    [type="radio"]:not(:checked) + label
                    {
                      color: #FFF;
                    }
                    [type="radio"]:checked + label:before,
                    [type="radio"]:not(:checked) + label:before {
                      content: "";
                      position: absolute;
                      left: 0;
                      top: -2px;
                      width: 25px;
                      height: 25px;
                      background: #153414;
                      border-radius: 100%;
                      border: 6px solid #425F42;
                    }
                  }
                }
              }
              button{
                margin-top: 20px;
              }
            }
          }
          .step5{
            .broker{
              .spot_item{
                background: rgba(92, 193, 89, 0.15);
                border: none;
                p{
                  color: #FFF;
                }
              }
            }
          }
        }
      }
      .problem-pop-up .content .btns{
        button{
          border: 2px solid #E72E4F;
          background: #153414;
          color: #E72E4F;
          &:hover{
            background-color: #E72E4F;
            color: #FFF;
          }
        }
        a{
          background: #5CC159;
          border-color: #5CC159;
          color: #FFF;
          &:hover{
            background-color: #2AC533;
          }
        }
      }
    }
    // Trading View
    .dashboard-trading-view-bot {
      border: 2px solid #425F42;
      background: #092108;
      .trading-view-table{
        .table{
          tbody tr{
            td{
              text-align: left;
              .logo{
                background: rgba(92, 193, 89, 0.15);
                p{
                  margin: 0;
                  padding: 0;
                  transition: all 0.2s;
                  font-size: 14px;
                  color: #FFF;
                  text-align: center;
                  font-weight: 500;
                  margin-left: 6px;
                  position: relative;
                  top: 0px;
                }
              }
              .supported {
                background: rgba(255, 163, 56, 0.15);
              }
              .instr {
                button, a {
                  background: #092108;
                }
              }
            }
          }
        }
      }
      .instructions-trading-view .inst_steps .inst_step h3 {
        color: #FFF;
      }
      .message-generator-bot-pop-up .pop-up-content{
        .JSON-msg {
          background: rgba(92, 193, 89, 0.15);
          pre{
            color: #FFF;
          }
        }
        .btns{
          button, a{
            &:last-of-type{
              border: 2px solid #E72E4F;
              background: #153414;
              color: #E72E4F;
              &:hover{
                background-color: #E72E4F;
                color: #FFF;
              }
            }
            &:first-of-type{
              background: #5CC159;
              &:hover{
                background-color: #2AC533;
              }
            }
          }
        }
      } 
      .problem-pop-up .content .btns{
        button{
          border: 2px solid #E72E4F;
          background: #153414;
          color: #E72E4F;
          &:hover{
            background-color: #E72E4F;
            color: #FFF;
          }
        }
        a{
          background: #5CC159;
          border-color: #5CC159;
          color: #FFF;
          &:hover{
            background-color: #2AC533;
          }
        }
      }
    }
    // Referral
    .dashboard-referral {
      .top_ref {
        border: 2px solid #425F42;
        background: #092108;
        .paid p {
          color: #FFF;
        }
        .referral-steps .ref-step{
          .icon {
            background: rgba(92, 193, 89, 0.15);
          }
          .info h2 {
            color: #FFF;
          }
        } 
        .field-copy {
          border: 2.741px solid #425F42;
          background: #092108;
          button.copied {
            background: #163914;
            pointer-events: none;
          }
        }
      }
      .referral-rules {
        border: 2px solid #425F42;
        background: #092108;
        h2 {
          color: #FFF;
        }
        .rules p {
          color: #FFF;
        }
      }
    }
    // Withdrawal
    .dashboard-withdrawal {
      .withdrawal-content {
        border: 2px solid #425F42;
        background: #092108;
        .balance {
          color: #FFF;
        }
        form{
          .inpt{
            label{
              color: #FFF;
            }
            input{
              border: 2px solid #425F42;
              background: #153414;
              color: #FFF;
              &::placeholder{
                font-weight: 400;
                font-size: 14px;
              }
              &:focus{
                border-color: #5CC159;
              }
            }
            span{
              background: #153414;
            }
          }
          .rules{
            p{
              color: #FFF;
            }
          }
        }
      }
      .withdrawal-table {
        border: 2px solid #425F42;
        background: #092108;
        .table tbody tr td .status.progress {
          color: #F58B10;
          background: rgba(245, 139, 16, 0.15);
        }
      }
    }
  }
  // Select
  .css-6hp17o-MuiList-root-MuiMenu-list {
    background: #153414 !important;
  }
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: rgb(92 193 89 / 27%) !important;
    color: #5cc159 !important;
  }
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    color: #FFF !important;
    transition: all .2s;
  }
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
    background-color: none !important;
    color: #5cc159 !important;
    transition: all .2s;
  }
}