@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&family=Cairo:wght@200;300;400;500;600;700;800;900;1000&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');
*{
  font-family: 'Roboto', sans-serif;
  // font-family: 'Poppins', sans-serif;
  // font-family: 'Cairo', sans-serif;
  // font-family: 'Almarai', sans-serif;
}
a{
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: rgb(92 193 89 / 20%) !important;
}
.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 2000ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

/***** Spacing *****/
/*TODO: CREATE SPACING CHART FOR MAKING CLASSES EASY*/
.mt-15 {
  margin-top: 15px;
}