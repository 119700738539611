.ar{
  direction: rtl !important;
  *{
    font-family: 'Almarai', sans-serif !important;
  }
  // Home
  .home,.waitlist{
    header nav .logo {
      float: right;
    }
    header nav .right_section {
      float: left;
    }
    header nav .right_section .links li {
      margin-right: 0;
      margin-left: 30px;
    }
    header nav .right_section .lang .top p {
      margin-left: 0;
      margin-right: 6px;
    }
    header nav .right_section .lang .top svg {
      margin-left: 0;
      margin-right: 6px;
    }
    header nav .right_section .lang .down_list a img {
      margin-right: 0;
      margin-left: 5px;
    }
    header .header_content .head_sec .lef-sec {
      margin-right: 0;
      margin-left: 70px;
    }
    header .header_content .head_sec .lef-sec p {
      line-height: 1.96;
    }
    header nav .menu {
      float: left;
      margin-right: 15px;
    }
    header nav .right_section .links {
      right: auto;
      left: -50px;
      top: 75px;
    }
    .supported_brokers .brokers .items .item p {
      padding: 7px 10px 9px 0;
    }
    .features .features_items .item a svg {
      margin-left: 0;
      margin-right: 8px;
    }
    .top_traders .items .item .info .nums .num .txt {
      margin-left: 0;
      margin-right: 10px;
    }
    .top_traders .items .item .info .nums .num:first-of-type {
      margin-right: 0;
      margin-left: 30px;
    }
    .top_traders .items .item .roi .recharts-responsive-container {
      transform: rotateY(180deg);
    }
    .subscriptions .plans .plan .list li p {
      padding-left: 0;
      padding-right: 27px;
    }
    .subscriptions .plans .plan .list li .icon {
      left: auto;
      right: 0;
    }
    .subscriptions .plans .plan:nth-of-type(2) .top_sec-plan h5 {
      left: auto;
      right: 185px;
    }
    .questions .questions_list .item .qs .icon {
      left: auto;
      right: 0;
    }
    .questions .questions_list .item .qs p {
      padding-left: 33px;
      padding-right: 46px;
    }
    .questions .questions_list .item .qs .arrow {
      left: 0;
      right: auto;
    }
    .testimonials .items .item .profile .info {
      margin-left: 0;
      margin-right: 10px;
    }
    .referral p {
      margin-top: 25px;
    }
    footer ul {
      padding-right: 0;
    }
    footer .lst_prt .social a:not(:last-of-type) {
      margin-left: 20px;
      margin-right: 0;
    }
  }
  // Login
  .signup .log_content form .form_part .inpt label, .signin .log_content form .form_part .inpt label {
    text-align: right;
  }
  .show_pwd {
    right: auto;
    left: 10px;
  }
  .signin .log_content .check_forget a {
    float: left;
  }
  .signup .log_content form .form_part .pr_tu, .signin .log_content form .form_part .pr_tu {
    float: right !important;
    padding-left: 0;
    padding-right: 30px;
    margin-bottom: 20px;
  }
  .signup .log_content form .form_part .pr_tu .box, .signin .log_content form .form_part .pr_tu .box {
    left: unset;
    right: 3px;
  }
  .log_content,.otp-content{
    .wrong {
      padding: 15px 40px 15px 10px;
      svg {
        left: unset;
        right: 9px;
      }
    }
    .back {
      left: unset;
      right: 0;
      transform: rotate(180deg);
    }
  } 
  .signup .log_content form .form_part .pr_tu a, .signin .log_content form .form_part .pr_tu a {
    margin-left: 0;
    margin-right: 3px;
  }
  .reset-password .form_part .inpt label, .new-password .form_part .inpt label {
    text-align: right;
  }
  .verify p{
    padding-left: 0;
    padding-right: 15px;
    &:after {
      right: 0;
      left: unset;
    }
  }
  // Dashboard
  .dashboard {
    .dashboard_content-section {
      float: left;
    }
    .dashboard_content-section .dash_nav .lef-sec {
      float: right;
    }
    .dashboard_menu.active {
      left: unset;
      right: 0;
    }
    @media (max-width: 1400px){
      .dashboard_menu {
        left: unset;
        right: -100%;
      }
    }
    .dashboard_menu .close {
      right: unset;
      left: 15px;
    }
    .dashboard_content-section .dash_nav .righ_sec {
      float: left;
    }
    .dashboard_content-section .dash_nav .righ_sec .notification {
      padding-left: 30px;
      padding-right: 0;
    }
    .dashboard_content-section .dash_nav .righ_sec .notification:after {
      right: auto;
      left: 0;
    }
    .dashboard_content-section .dash_nav .righ_sec .user-profile .top_prof .down_icon {
      right: auto;
      left: 0;
      top: 4px;
    }
    .dashboard_content-section .dash_nav .righ_sec .user-profile .top_prof .use_info {
      margin-left: 0;
      margin-right: 10px;
    }
    .dashboard_content-section .dash_nav .righ_sec .user-profile .down_prof .mode_btns button svg {
      margin-left: 3px;
      margin-right: 0;
    }
    .dashboard_content-section .dash_nav .righ_sec .user-profile .down_prof .links li a svg {
      margin-right: 0;
      margin-left: 6px;
    }
    .dashboard_content-section .dash_nav .righ_sec .user-profile .down_prof .links li .lang .top svg {
      margin-right: 0;
      margin-left: 6px;
    }
    .dashboard_content-section .dash_nav .righ_sec .user-profile .down_prof .links li .lang .top .arrow {
      margin-right: 7px;
      margin-left: 0;
    }
    .dashboard_content-section .dash_nav .righ_sec .user-profile .down_prof .links li .lang .down_list img {
      margin-left: 5px;
      margin-right: 0;
    }
    .dashboard_menu .links li a svg {
      margin-right: 0;
      margin-left: 15px;
    }
    .dashboard_content-section .dash_nav .lef-sec .open_menu {
      margin-left: 15px;
      margin-right: 0;
    }
    .dashboard_content-section .dash_nav .righ_sec .user-profile .top_prof {
      margin-right: 20px;
      margin-left: 0;
      padding-left: 25px;
      padding-right: 0;
    }
    @media (max-width: 767px){
      .dashboard_content-section .dash_nav .righ_sec .user-profile .down_prof {
        left: 0;
        right: auto;
      }
    }
    .table-bor .table thead tr th:first-of-type {
      border-radius: 0 8px 8px 0;
    }
    .table-bor .table thead tr th:last-of-type {
      border-radius: 8px 0 0 8px;
    }
    .table-bor .table tbody tr td {
      direction: ltr;
      font-family: "Poppins" !important;
    }
    .MuiInputBase-root {
      .MuiSelect-select{
        padding-right: 10px !important;
        padding-left: 30px !important;
      }
      .MuiSvgIcon-root {
        right: auto !important;
        left: 7px !important;
      }
    }
    .done {
      padding-left: 35px;
      padding-right: 40px;
    }
    .done svg, .dashboard .error svg {
      left: auto;
      right: 10px;
    }
    .done .close_alert, .dashboard .error .close_alert {
      right: auto !important;
      left: 10px !important;
    }
    .inpt label {
      text-align: right;
    }
    .pop-up .pop-up-content .pop-up-title .close {
      right: auto;
      left: 25px;
    }
    .pop-up .pop-up-content .exchange_content .key p {
      text-align: right;
    }
    .pop-up .pop-up-content .back_to {
      left: auto;
      right: 25px;
    }
    .pop-up .pop-up-content .back_to svg {
      transform: rotate(180deg);
    }
    .title .links {
      display: flex;
      margin: 0;
      padding-right: 0;
    }
    ul{
      padding-right: 0;
    }
    .done, .error {
      padding: 6px 45px;
    }
    .done svg, .error svg {
      right: 10px;
      left: unset;
    }
    .done .close_alert, .error .close_alert {
      right: unset !important;
      left: 10px !important;
    }
    .wrong-input p {
      margin-left: 0;
      margin-right: 6px;
    }
  }
  // Dashboard Home
  .dashboard .dashboard-home{
    .cards .card .top_sec .card_head {
      float: right;
    }
    .cards .card .top_sec .card_head .sec_title {
      margin-right: 10px;
      margin-left: 0;
    }
    .cards .card .top_sec .select_card {
      float: left;
    }
    .cards .card .btm_sec .num {
      float: right;
    }
    .cards .card .btm_sec .new_acc .btm_connect {
      float: left;
    }
    .cards .card .btm_sec .new_acc .btm_connect svg {
      margin-left: 0;
      margin-right: 8px;
    }
    .cards .card .btm_sec .statistics svg {
      float: left;
    }
    .cards .card .btm_sec .num {
      float: right;
      flex-direction: revert;
      display: flex;
    }
    .cards .card .btm_sec .statistics .num span {
      direction: ltr;
      margin: 0 10px 0 0;
      top: 7px;
    }
    .trades_progress .filter {
      left: 20px;
      right: unset;
    }
    .trades_progress .title_sec h2 {
      text-align: right;
    }
    .trades_progress .title_sec .total_unrealized span {
      margin-left: 0;
      margin-right: 10px;
    }
  }
  // Exchange
  .dashboard  .dashboard-exchange{
    form .cont button {
      margin-right: 15px;
      margin-left: 0;
    }
    .done {
      margin-left: 0;
      margin-right: 15px;
    }
    form .cont button svg {
      margin-left: 4px;
      margin-right: 0;
    }
    form .cont .select_filter .MuiSelect-select {
      padding-right: 15px !important;
    }
    .exchange-table .table thead tr th {
      text-align: right;
    }
    .exchange-table .table tbody tr td {
      text-align: right;
    }
    .exchange-table .table tbody tr td p {
      justify-content: end;
    }
    .exchange-table .table tbody tr td .logo {
      flex-direction: row-reverse;
      padding: 5px 5px 5px 20px;
      margin: 0 0 0 auto;
    }
    .exchange-table .table tbody tr td .logo p {
      margin-right: 6px;
      margin-left: 0;
    }
    .exchange-table .table tbody tr td .switch input[type=checkbox] {
      transform: rotate(180deg);
    }
    .exchange-table .table tbody tr td .options {
      direction: rtl;
    }
    .editAPI-pop-up .pop-up-content {
      padding-top: 55px;
    }
    .exchange-pop-up .pop-up-content .exchange_content .links {
      padding: 0;
    }
    .exchange-pop-up .pop-up-content .exchange_content .spot .item .spot_item p, .exchange-pop-up .pop-up-content .exchange_content .usdt-m .item .spot_item p {
      margin-left: 0;
      margin-right: 6px;
    }
    .exchange-pop-up .pop-up-content .exchange_content form .inpt label {
      text-align: right;
    }
    .exchange-pop-up .pop-up-content .exchange_content .key .steps .step .api {
      padding: 12px 23px 12px 50px;
    }
    .exchange-pop-up .pop-up-content .exchange_content .key .steps .step .api .copy {
      right: auto;
      left: 21px;
    }
  }
  // Analytics
  .dashboard-analytics {
    .title_sec .filter {
      float: left;
    }
    .title_sec h2 {
      float: right;
    }
    .top_boxes .followers_progress .recharts-responsive-container {
      direction: ltr;
    }
    .statistics .statistics_table .table thead tr th {
      text-align: right;
    }
    .statistics .statistics_table .table tbody tr td {
      text-align: right;
    }
    .top_boxes .infos p span:last-of-type {
      direction: ltr;
    }
  }
  // Trading View
  .dashboard-trading-view-bot{
    .webhook .txt {
      padding: 55px 25px 55px 0;
    }
    .webhook .img {
      position: absolute;
      top: -110px;
      left: -110px;
      right: unset;
    }
    .webhook .person {
      right: unset;
      left: 20px;
      transform: rotateY(180deg);
    }
    .trading-view-table .table thead tr th {
      text-align: right;
    }
    .trading-view-table .table tbody tr td .logo {
      padding: 5px 5px 5px 20px;
      margin: 0 0 0 auto;
      display: flex;
      flex-direction: row-reverse;
    }
    .trading-view-table .table tbody tr td .logo p {
      margin-left: 0;
      margin-right: 6px;
    }
    .trading-view-table .table tbody tr td .instr a, .trading-view-table .table tbody tr td .instr button {
      svg {
        margin-left: 0;
        margin-right: 8px;
      }
    }
    .table-bor .table tbody tr td {
      direction: rtl; 
    }
    .instructions-trading-view .back_to svg {
      transform: rotate(180deg);
    }
  }
  // Telegram Bot
  .dashboard-telegram-bot {
    .progress_bot {
      padding-right: 0;
    }
    .progress_bot li:not(:first-of-type):after {
      left: unset;
      right: -87px;
    }
    .bot_steps .step {
      padding: 10px 60px 10px 35px;
    }
    .bot_steps .step span {
      left: unset;
      right: 20px;
      margin-left: 15px;
      margin-right: 0;
    }
    .bot_steps .step .down {
      right: unset;
      left: 14px;
      transform: translateY(-50%) rotate(180deg);
    }
    .help .txt {
      padding: 55px 25px 55px 0;
    }
    .help .img {
      left: -165px;
      right: auto;
      img{
        transform: rotate(180deg);
      }
    }
    .help .person {
      left: 60px;
      right: unset;
    }
    .telegram-bot-pop-up .pop-up-content .progress_popup li:not(:first-of-type):after {
      left: unset;
      right: -36px;
    }
    .telegram-bot-pop-up .pop-up-content .step1 a {
      flex-direction: row-reverse;
    }
    .telegram-bot-pop-up .pop-up-content .step2 .group-list .groups .group .num {
      margin-left: 0;
      margin-right: 60px;
    }
    .telegram-bot-pop-up .pop-up-content .step2 .group-list .groups .group .num .icon {
      margin-right: 0;
      margin-left: 10px;
    }
    .telegram-bot-pop-up .pop-up-content .step2 .group-list .groups .group button {
      right: unset;
      left: 20px;
    }
    .telegram-bot-pop-up .pop-up-content .step2 .group-list .groups .group .info .inf svg, .telegram-bot-pop-up .pop-up-content .step2 .group-list .groups .group .info .inf img {
      margin-left: 7px;
      margin-right: 0;
    }
    .telegram-bot-pop-up .pop-up-content .step2 .group-list .groups .group .info .img {
      margin-left: 10px;
      margin-right: 0;
    }
    .telegram-bot-pop-up .pop-up-content .step2 .group-list .groups {
      padding-left: 10px;
      padding-right: 0;
    }
    .telegram-bot-pop-up .pop-up-content .step4 form .inpt label {
      text-align: right;
    }
    .telegram-bot-pop-up .pop-up-content .step4 form .inpt span {
      left: unset;
      right: 4px;
    }
    .telegram-bot-pop-up .pop-up-content .step4 form .inpt .check_inpt p [type=radio]:checked + label, .telegram-bot-pop-up .pop-up-content .step4 form .inpt .check_inpt p [type=radio]:not(:checked) + label {
      padding-left: 0;
      padding-right: 32px;
    }
    .telegram-bot-pop-up .pop-up-content .step4 form .inpt .check_inpt p [type=radio]:checked + label:before, .telegram-bot-pop-up .pop-up-content .step4 form .inpt .check_inpt p [type=radio]:not(:checked) + label:before {
      left: unset;
      right: 0;
    }
    .telegram-bot-pop-up .pop-up-content .step4 form .inpt .check_inpt p [type=radio]:checked + label:after, .telegram-bot-pop-up .pop-up-content .step4 form .inpt .check_inpt p [type=radio]:not(:checked) + label:after {
      right: 6px;
      left: unset;
    }
    .telegram-bot-pop-up .pop-up-content .step4 form .inpt .check_inpt p:first-of-type {
      margin-right: 0;
      margin-left: 25px;
    }
    .telegram-bot-pop-up .pop-up-content .step4 form .inpt .check_inpt p [type=radio]:checked, .telegram-bot-pop-up .pop-up-content .step4 form .inpt .check_inpt p [type=radio]:not(:checked) {
      position: absolute;
      right: -9999px;
      left: unset;
    }
    .telegram-bot-pop-up .pop-up-content .step5 .broker .item .spot_item p {
      margin-left: 0;
      margin-right: 6px;
    }
  }
  // Marketplace
  .dashboard-marketplace {
    .top_traders .item .info .nums .num:first-of-type {
      margin-left: 20px;
      margin-right: 0;
    }
    .top_traders .item .info .nums .num .txt {
      margin-left: 0;
      margin-right: 5px;
    }
    .top_traders .item .roi .recharts-responsive-container {
      right: unset;
      left: 0;
      transform: rotateY(180deg);
    }
    .pagination button:not(:last-of-type) {
      margin-left: 10px;
      margin-right: 0;
    }
    .payment-pop-up .pop-up-content .payment_content .prt_two.prt_two {
      padding-left: 0;
      padding-right: 25px;
    }
    .payment-pop-up .pop-up-content .payment_content .prt_one.prt_one {
      padding-right: 0;
      padding-left: 25px;
      border-right: 0;
      border-left: 1px solid #DAE7DA;
    }
    .payment-pop-up .pop-up-content .payment_content .prt_one .inpt label, .payment-pop-up .pop-up-content .payment_content .prt_two .inpt label {
      text-align: right;
    }
    .payment-pop-up .pop-up-content .payment_content .prt_two .btn_submit {
      padding-right: 25px;
      padding-left: 0;
    }
    .payment-pop-up .pop-up-content .payment_content .prt_one .inpt .check_inpt p:first-of-type, .payment-pop-up .pop-up-content .payment_content .prt_two .inpt .check_inpt p:first-of-type {
      margin-left: 25px;
      margin-right: 0;
    }
    .payment-pop-up .pop-up-content .payment_content .prt_one .inpt .check_inpt p [type=radio]:checked + label:before, .payment-pop-up .pop-up-content .payment_content .prt_one .inpt .check_inpt p [type=radio]:not(:checked) + label:before, .payment-pop-up .pop-up-content .payment_content .prt_two .inpt .check_inpt p [type=radio]:checked + label:before, .payment-pop-up .pop-up-content .payment_content .prt_two .inpt .check_inpt p [type=radio]:not(:checked) + label:before{
      right: 6px;
      left: unset;
    }
    .payment-pop-up .pop-up-content .payment_content .prt_one .inpt .check_inpt p [type=radio]:checked + label, .payment-pop-up .pop-up-content .payment_content .prt_one .inpt .check_inpt p [type=radio]:not(:checked) + label, .payment-pop-up .pop-up-content .payment_content .prt_two .inpt .check_inpt p [type=radio]:checked + label,.payment-pop-up .pop-up-content .payment_content .prt_two .inpt .check_inpt p [type=radio]:not(:checked) + label{
      padding-right: 40px;
      padding-left: 0;
    }
    .payment-pop-up .pop-up-content .payment_content .prt_one .inpt .check_inpt p [type=radio]:checked, .payment-pop-up .pop-up-content .payment_content .prt_one .inpt .check_inpt p [type=radio]:not(:checked), .payment-pop-up .pop-up-content .payment_content .prt_two .inpt .check_inpt p [type=radio]:checked, .payment-pop-up .pop-up-content .payment_content .prt_two .inpt .check_inpt p [type=radio]:not(:checked) {
      position: absolute;
      left: unset;
      right: -9999px;
    }
    .payment-pop-up .pop-up-content .payment_content .prt_one .inpt .check_inpt p [type=radio]:checked + label:after, .payment-pop-up .pop-up-content .payment_content .prt_one .inpt .check_inpt p [type=radio]:not(:checked) + label:after, .payment-pop-up .pop-up-content .payment_content .prt_two .inpt .check_inpt p [type=radio]:checked + label:after, .payment-pop-up .pop-up-content .payment_content .prt_two .inpt .check_inpt p [type=radio]:not(:checked) + label:after {
      right: 12px;
      left: unset;
    }
    .payment-pop-up .pop-up-content .payment_content .prt_one .inpt .items .item, .payment-pop-up .pop-up-content .payment_content .prt_two .inpt .items .item {
      padding: 15px 15px 15px 65px;
    }
    .payment-pop-up .pop-up-content .payment_content .prt_one .inpt .items .item .info, .payment-pop-up .pop-up-content .payment_content .prt_two .inpt .items .item .info {
      padding-right: 10px;
      padding-left: 0;
    }
    .payment-pop-up .pop-up-content .payment_content .prt_one .inpt .items .item .options, .payment-pop-up .pop-up-content .payment_content .prt_two .inpt .items .item .options {
      left: 5px;
      right: unset;
    }
    .payment-pop-up .pop-up-content .payment_content .prt_one .amount p:first-of-type, .payment-pop-up .pop-up-content .payment_content .prt_two .amount p:first-of-type {
      float: right;
    }
    .payment-pop-up .pop-up-content .payment_content .prt_one .amount p:last-of-type, .payment-pop-up .pop-up-content .payment_content .prt_two .amount p:last-of-type {
      float: left;
    }
    .payment-pop-up .pop-up-content .payment_content .prt_one .btn_submit .pr_tu a, .payment-pop-up .pop-up-content .payment_content .prt_two .btn_submit .pr_tu a {
      margin-left: 0;
      margin-right: 3px;
    }
    .payment-pop-up .pop-up-content .payment_content .prt_one .btn_submit .pr_tu, .payment-pop-up .pop-up-content .payment_content .prt_two .btn_submit .pr_tu {
      padding-left: 0;
      padding-right: 30px;
    }
    .payment-pop-up .pop-up-content .payment_content .prt_one .btn_submit .pr_tu .box, .payment-pop-up .pop-up-content .payment_content .prt_two .btn_submit .pr_tu .box {
      right: 3px;
      left: unset;
    }
  }
  // Referral
  .dashboard-referral {
    .top_ref .field-copy button {
      right: unset;
      left: 3px;
    }
    .top_ref .paid p span {
      margin-left: 0;
      margin-right: 5px;
    }
    .top_ref .referral-table .table thead tr th {
      text-align: right;
    }
    .table-bor .table tbody tr td {
      text-align: right;
    }
    .top_ref .referral-table .table tbody tr td:last-of-type {
      font-family: 'Almarai', sans-serif !important;
      padding-right: 25px;
    }
    .referral-rules .rules p {
      padding-right: 15px;
      padding-left: 0;
    }
    .referral-rules .rules p:after {
      right: 0;
      left: unset;
    }
  }
  // Subscription
  .dashboard-subscription{
    .plans .plan .list li p {
      padding-left: 0;
      padding-right: 27px;
    }
    .plans .plan .list li .icon {
      left: auto;
      right: 0;
    }
    .plans .plan:nth-of-type(2) .top_sec-plan h5 {
      left: auto;
      right: 185px;
    }
    .subscription-pricing .plans .plan .top_sec-plan h3 .discount {
      margin: 0 0 0 8px;
    }
    .subscription-pricing .plans .plan .top_sec-plan h3 span {
      margin-right: 5px;
      margin-left: 0;
    }
    .subscription-pricing .plans .plan:nth-of-type(2) .top_sec-plan h5 svg {
      margin-left: 5px;
      margin-right: 0;
    }
    .payment-pop-up .pop-up-content form .inpt label {
      text-align: right;
    }
    .payment-pop-up .pop-up-content form .inpt .check_inpt p [type=radio]:checked + label,
    .dashboard-subscription .payment-pop-up .pop-up-content form .inpt .check_inpt p [type=radio]:not(:checked) + label {
      padding-right: 35px;
      padding-left: 0;
    }
    .pop-up-content form .inpt .check_inpt p [type=radio]:checked + label, 
    .payment-pop-up .pop-up-content form .inpt .check_inpt p [type=radio]:not(:checked) + label {
      padding-left: 0;
      padding-right: 35px;
    }
    .payment-pop-up .pop-up-content form .inpt .check_inpt p svg {
      margin-right: 0;
      margin-left: 5px;
    }
    .payment-pop-up .pop-up-content form .inpt .check_inpt p [type=radio]:checked + label:before, .payment-pop-up .pop-up-content form .inpt .check_inpt p [type=radio]:not(:checked) + label:before {
      left: auto;
      right: 0;
    }
    .payment-pop-up .pop-up-content form .inpt .check_inpt p [type=radio]:checked, 
    .payment-pop-up .pop-up-content form .inpt .check_inpt p [type=radio]:not(:checked) {
      left: auto;
      right: -9999px;
    }
    .payment-pop-up .pop-up-content form .inpt .check_inpt p [type=radio]:checked + label:after, 
    .payment-pop-up .pop-up-content form .inpt .check_inpt p [type=radio]:not(:checked) + label:after {
      left: auto;
      right: 6px;
    }
    .billings_table .table thead tr th {
      text-align: right;
    }
    .billings_table .table tbody tr td {
      text-align: right;
    }
    .subscription-billing .billings_table .table tbody tr td .download {
      margin: 0 auto;
    }
    .subscription-billing .payment_methods .add_box .item .info, .dashboard-subscription .subscription-billing .payment_methods .methods_added .item .info {
      padding-right: 10px;
      padding-left: 0;
    }
    .subscription-billing .payment_methods .add_box .item, .dashboard-subscription .subscription-billing .payment_methods .methods_added .item {
      padding: 12px 15px 12px 65px;
    }
    .subscription-billing .payment_methods .add_box .item .add, .subscription-billing .payment_methods .methods_added .item .add {
      right: auto;
      left: 5px;
    }
    .subscription-billing .payment_methods .add_box .item,
    .subscription-billing .payment_methods .methods_added .item {
      padding: 12px 15px 12px 120px;
    }
    .subscription-billing .payment_methods .add_box .item, .subscription-billing .payment_methods .methods_added .item {
      max-width: 520px;
    }
    .subscription-billing .payment_methods .add_box .item .options, 
    .subscription-billing .payment_methods .methods_added .item .options {
      right: unset;
      left: 5px;
    }
    .payment-pop-up .pop-up-content form .item {
      padding: 12px 15px  12px 65px;
    }
    .payment-pop-up .pop-up-content form .item .info {
      padding-right: 10px;
      padding-left: 0;
    }
    .payment-pop-up .pop-up-content form .item .add {
      right: unset;
      left: 5px;
    }
    .table-bor .table tbody tr td {
      font-family: "Almarai", sans-serif !important;
    }
    .subscription-promo-codes .codes_table .table thead tr th {
      text-align: right;
    }
    .table-bor .table tbody tr td {
      text-align: right;
    }
    .subscription-promo-codes .code_btn .done, .subscription-promo-codes .code_btn .error {
      margin-left: 0;
      margin-right: 10px;
    }
    .subscription-promo-codes .code_btn .inpt button {
      right: unset;
      left: 5px;
    }
  }
  // Settings
  .dashboard-settings {
    .profile-settings .settings_form .inpt label, 
    .security-settings .settings_form .inpt label {
      text-align: right;
    }
    .profile-settings .settings_form .inpt .phone .field button, 
    .security-settings .settings_form .inpt .phone .field button {
      right: unset;
      left: 5px;
    }
    .profile-settings .settings_form .inpt .phone .code .code_country, .security-settings .settings_form .inpt .phone .code .code_country {
      direction: ltr;
    }
    .profile-settings .settings_form .inpt .phone .code .code_country .MuiSelect-select img, 
    .security-settings .settings_form .inpt .phone .code .code_country .MuiSelect-select img {
      margin-left: 0;
      margin-right: 4px;
    }
    .profile-settings .settings_form .profile_img .up_img .img, 
    .security-settings .settings_form .profile_img .up_img .img {
      margin-right: 0;
      margin-left: 25px;
    }
    .profile-settings .settings_form .select_filter .MuiSelect-select, 
    .security-settings .settings_form .select_filter .MuiSelect-select {
      padding-right: 9px !important;
      padding-left: 40px !important;
    }
    .profile-settings .settings_form .switch .check p, 
    .security-settings .settings_form .switch .check p {
      margin-right: 10px;
      margin-left: 0;
    }
    .profile-settings .settings_form .switch .hide_box .check_inpt p [type=radio]:checked + label, 
    .profile-settings .settings_form .switch .hide_box .check_inpt p [type=radio]:not(:checked) + label, 
    .security-settings .settings_form .switch .hide_box .check_inpt p [type=radio]:checked + label, 
    .security-settings .settings_form .switch .hide_box .check_inpt p [type=radio]:not(:checked) + label {
      padding-left: 0;
      padding-right: 32px;
    }
    .profile-settings .settings_form .switch .hide_box .check_inpt p [type=radio]:checked + label:before, 
    .profile-settings .settings_form .switch .hide_box .check_inpt p [type=radio]:not(:checked) + label:before, 
    .security-settings .settings_form .switch .hide_box .check_inpt p [type=radio]:checked + label:before, 
    .security-settings .settings_form .switch .hide_box .check_inpt p [type=radio]:not(:checked) + label:before {
      left: unset;
      right: 0;
    }
    .profile-settings .settings_form .switch .hide_box .check_inpt p [type=radio]:checked + label:after, 
    .profile-settings .settings_form .switch .hide_box .check_inpt p [type=radio]:not(:checked) + label:after, 
    .security-settings .settings_form .switch .hide_box .check_inpt p [type=radio]:checked + label:after, 
    .security-settings .settings_form .switch .hide_box .check_inpt p [type=radio]:not(:checked) + label:after {
      left: unset;
      right: 6px;
    }
    .profile-settings .settings_form .switch .hide_box .check_inpt p [type=radio]:checked, 
    .profile-settings .settings_form .switch .hide_box .check_inpt p [type=radio]:not(:checked), 
    .security-settings .settings_form .switch .hide_box .check_inpt p [type=radio]:checked, 
    .security-settings .settings_form .switch .hide_box .check_inpt p [type=radio]:not(:checked) {
      left: unset;
      right: -9999px;
    }
    .profile-settings .settings_form .switch .hide_box .check_inpt p:first-of-type, 
    .security-settings .settings_form .switch .hide_box .check_inpt p:first-of-type {
      margin-right: 0;
      margin-left: 25px;
    }
    .profile-settings .settings_form .switch .hide_box .inpt span, 
    .security-settings .settings_form .switch .hide_box .inpt span {
      left: unset;
      right: 4px;
    }
    .verify-number-popup form {
      padding-top: 0;
    }
  }
  // Withdrawal
  .dashboard-withdrawal {
    .withdrawal-content .balance span {
      margin-right: 15px;
      margin-left: 0;
    }
    .withdrawal-content form .inpt label {
      text-align: right;
    }
    .withdrawal-content form .inpt span {
      right: 4px;
      left: unset;
    }
    .withdrawal-content form .rules p {
      padding-left: 0;
      padding-right: 15px;
    }
    .withdrawal-content form .rules p:after {
      left: unset;
      right: 0;
    }
    .withdrawal-table .table thead tr th {
      text-align: right;
    }
    .withdrawal-table .table tbody tr td {
      text-align: right;
    }
    .withdrawal-table .table tbody tr td .status {
      margin: 0 0 0 auto;
    }
  }
  // Waitlist
  .waitlist{
    header .header_content .head_box .img1 {
      left: auto;
      right: -75px;
    }
    header .header_content .head_box .img2 {
      right: auto;
      left: 0;
    }
    header .header_content .head_box .content .mail_form .inpt svg {
      right: 11px;
      left: auto;
    }
    header .header_content .head_box .content .mail_form button {
      margin-left: 0;
      margin-right: 15px;
    }
    .features .features_items .item .btn img {
      right: auto;
      left: 0;
    }
    .aboutus .sections .sec .content {
      padding-left: 0;
      padding-right: 50px;
      text-align: right;
    }
    .features .features_items .item{
      p,h3 {
        text-align: right;
      }
    }
    .done-pop-up .content .btns {
      flex-direction: row-reverse;
    }
    .features .features_items .item .btns img {
      right: auto;
      left: 0;
    }
    @media (max-width: 991px){
      header .header_content .head_box .img2 {
        right: auto !important;
        left: 0 !important;
      }
      header .header_content .head_box .img1 {
        left: auto;
        right: -25px;
      }
    }
  }
}