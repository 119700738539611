.home{
    width: 100%;
    height: 100%;
    color: #000;
    background-color: #FFF !important;
    // All
    .top_sec{
        h1{
            text-align: center;
            color: #000;
            
            font-size: 45px;
            font-weight: bold;
            span{
                color: #5CC159;
            }
        }
        p{
            color: #737772;
            text-align: center;
            
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            width: 660px;
            margin: 0 auto;
            margin-top: 17px;
        }
    }
    // Header
    header{
        background: linear-gradient(170deg, #D4DBD4 0%, #CED6C9 100%);
        width: 100%;
        nav{
            // overflow: hidden;
            padding: 20px 0 15px 0;
            border-bottom: 1px solid #B1BFB1;
            height: 103px;
            .logo{
                float: left;
                user-select: none;
                a{}
            }
            .right_section{
                display: flex;
                float: right;
                position: relative;
                user-select: none;
                .links{
                    display: flex;
                    padding: 26px 0 15px 0;
                    margin: 0 80px 0 0;
                    width: fit-content;
                    li{
                        margin-right: 30px;
                        &:last-child, &:nth-of-type(7){
                            display: none;
                        }
                        a{
                            font-size: 16px;
                            cursor: pointer;
                            
                            font-weight: 500;
                            transition: all .2s;
                            &:hover{
                                color: #2AC533;
                            }
                        }
                    }
                }
                .lang{
                    float: right;
                    margin: 25px 40px 0 0;
                    height: fit-content;
                    position: relative;
                    .top{
                        display: flex;
                        cursor: pointer;
                        user-select: none;
                        position: relative;
                        &:after{
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                        }
                        &:hover {
                            p{
                                color: #2AC533;
                            }
                            svg path{
                                fill: #2AC533;
                            }
                        }
                        img{
                            width: 25px;
                            height: 25px;
                        }
                        p{
                            font-size: 16px;
                            margin: 0;
                            margin-left: 6px;
                            margin-top: 1px;
                            transition: all .2s;
                            
                            font-weight: 500;
                        }
                        svg{
                            width: 15px;
                            height: 15px;
                            position: relative;
                            top: 6px;
                            margin-left: 6px;
                            path{
                                transition: all .2s;
                            }
                        }
                    }
                    .down_list{
                        position: absolute;
                        top: 33px;
                        background: #FFF;
                        padding: 10px 9px;
                        border-radius: 3px;
                        box-shadow: 0 2px 5px 2px rgba(43, 82, 61, 0.2901960784);
                        width: 120px;
                        transition: all .2s;
                        a{
                            font-size: 16px;
                            margin: 0;
                            transition: all 0.2s;
                            
                            font-weight: 500;
                            display: flex;
                            width: 100%;
                            align-items: center;
                            transition: all .2s;
                            &:hover{
                                color: #2AC533;
                            }
                            img{
                                width: 25px;
                                height: 25px;
                                margin-right: 5px;
                            }
                        }
                    }
                }
                .btns{
                    padding: 26px 0 0;
                    a{
                        color: #000;
                        font-size: 16px;
                        margin-right: 20px;
                        cursor: pointer;
                        transition: all 0.2s;
                        text-decoration: none;
                        
                        font-weight: 500;
                        &:first-of-type{
                            &:hover{
                                color: #2AC533;
                            }
                        }
                        &:last-of-type{
                            color: #FFF;
                            border-radius: 6.044px;
                            background: #2AC533;
                            padding: 12px 30px;
                            font-size: 16px;
                            &:hover{
                                background: #5CC159;
                            }
                        }
                    }
                }
                .isAuthenticated-btns{
                    padding: 10px 0;
                    display: flex;
                    a{
                        color: #000;
                        font-size: 16px;
                        margin-right: 20px;
                        cursor: pointer;
                        transition: all 0.2s;
                        text-decoration: none;
                        font-weight: 500;
                        display: flex;
                        img{
                            height: 48px;
                        }
                    }
                }
            }
            .menu{
                margin: 20px 0 0;
                cursor: pointer;
                display: none;
                float: right;
                position: relative;
                &:after{
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
                &:hover{
                    svg path{
                        fill: #2AC533;
                    }
                }
                svg{
                    width: 35px;
                    height: 35px;
                    path{
                        transition: all .2s;
                    }
                }
                &.active{
                    svg path{
                        fill: #2AC533;
                    }
                }
            }
        }
        .header_content{
            width: 100%;
            padding-top: 70px;
            padding-bottom: 130px;
            .head_sec{
                width: 100%;
                display: flex;
                .lef-sec{
                    width: 610px;
                    margin-right: 70px;
                    h4{
                        color: #7C7D7C;
                        
                        font-size: 16px;
                        margin: 0 0 20px;
                    }
                    h1{
                        margin-bottom: 10px;
                        color: #000;
                        font-family: 'Poppins', sans-serif;
                        font-size: 65px;
                        line-height: normal;
                        font-weight: bold;
                        span{
                            display: block;
                            color: #2AC533;
                        }
                    }
                    p{
                        color: #7C7D7C;
                        
                        font-size: 16px;
                        font-weight: 400;
                        line-height: normal;
                        width: 440px;
                    }
                    a{
                        color: #FFF;
                        border-radius: 6.044px;
                        background: #2AC533;
                        padding: 17px 60px;
                        font-size: 15px;
                        text-decoration: none;
                        
                        font-style: normal;
                        line-height: normal;
                        margin-top: 40px;
                        display: flex;
                        width: fit-content;
                        transition: all .2s;
                        &:hover{
                            background: #5CC159;
                        }
                    }
                }
                .dash_img{
                    width: fit-content;
                    padding: 20px;
                    background: linear-gradient(180deg, rgba(255, 255, 255, 0.72) 0%, #FFF 100%), #FFF;
                    border-radius: 26px;
                    // transform: ;
                    height: fit-content;
                    margin-top: 10px;
                    -webkit-animation: mover 1s infinite  alternate;
                        animation: mover 1s infinite  alternate;
                    box-shadow: 0px 0.7233213782310486px 5.424908638000488px 0px rgba(0, 0, 0, 0.05), -0.9041516184806824px -0.9041516184806824px 4.52075719833374px 0px #BDC5D4 inset, 2.6128041744232178px 0.9041516184806824px 10.451216697692871px 0px #EEE inset;
                    img{
                        width: 550px;
                        height: 370px;
                    }
                    @keyframes mover {
                        0% { transform: rotate(-3.3deg) translateY(0); }
                        100% { transform: rotate(-3.3deg) translateY(-10px); }
                    }
                }
            }
        }
    }
    // Supported Brokers
    .supported_brokers{
        margin-top: 90px;
        padding-top: 50px;
        .brokers{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-top: 50px;
            .items{
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                justify-content: center;
                .item{
                    border-radius: 100px;
                    background: #F7F7F7;
                    display: flex;
                    padding: 10px 30px;
                    margin-bottom: 30px;
                    transition: all 0.2s;
                    cursor: pointer;
                    &:not(:last-of-type){
                        margin-right: 30px;
                    }
                    &:hover{
                        background: #5CC159;
                        box-shadow: 0px 30px 40px 0px rgba(92, 193, 89, 0.25);
                        p{
                            color: #FFF;
                        }
                    }
                    img{
                        width: 40px;
                        height: 40px;
                    }
                    p{
                        margin: 0;
                        padding: 7px 0 9px 10px;
                        color: #000;
                        transition: all .2s;
                        
                        font-size: 16px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
    // Features
    .features{
        margin-top: 90px;
        padding-top: 50px;
        .features_items{
            margin-top: 50px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 35px;
            .item{
                border-radius: 15px;
                border: 2px solid #DAE7DA;
                background: #FFF;
                padding: 25px 20px;
                position: relative;
                transition: all .2s;
                &:hover{
                    border-color: #2AC533;
                }
                .icon{
                    background: linear-gradient(86deg, #5CC159 0%, #85E682 100%);
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 auto;
                    svg{
                        width: 27px;
                        height: 27px;
                    }
                }
                h3{
                    color: #000;
                    text-align: center;
                    
                    font-size: 18px;
                    font-weight: 600;
                    margin-top: 30px;
                    margin-bottom: 10px;
                }
                p{
                    color: #99A399;
                    text-align: center;
                    
                    font-size: 13px;
                    font-weight: 400;
                    margin-bottom: 100px;
                }
                a{
                    border-radius: 5px;
                    border: 1px solid #5CC159;
                    color: #5CC159;
                    text-align: center;
                    
                    font-size: 15px;
                    font-weight: 400;
                    padding: 8px 0;
                    display: block;
                    transition: all 0.2s;
                    cursor: pointer;
                    position: absolute;
                    bottom: 25px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 210px;
                    &:hover{
                        background-color: #2AC533;
                        color: #FFF;
                        svg path{
                            stroke: #FFF;
                        }
                    }
                    svg{
                        width: 20px;
                        height: 19px;
                        margin-left: 8px;
                        path{
                            transition: all .2s;
                        }
                    }
                }
            }
        }
    }
    // Top Traders
    .top_traders{
        margin-top: 90px;
        padding-top: 50px;
        .items{
            margin-top: 50px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 35px;
            .item{
                border-radius: 15px;
                border: 2px solid #DAE7DA;
                background: #FFF;
                padding: 25px 20px;
                position: relative;
                transition: all .2s;
                &:hover{
                    border-color: #2AC533;
                }
                .picture{
                    position: relative;
                    width: fit-content;
                    margin: 0 auto;
                    img{
                        width: 65px;
                        height: 65px;
                    }
                    svg{
                        width: 26px;
                        position: absolute;
                        right: -3px;
                        bottom: -3px;
                    }
                }
                .info{
                    margin-top: 10px;
                    h1{
                        color: #000;
                        text-align: center;
                        
                        font-size: 21px;
                        font-weight: 600;
                    }
                    .nums{
                        display: flex;
                        width: 100%;
                        justify-content: center;
                        margin-top: 20px;
                        .num{
                            display: flex;
                            &:first-of-type{
                                margin-right: 30px;
                            }
                            .icon{
                                border-radius: 14px;
                                background: #EAF9EA;
                                display: flex;
                                width: 40px;
                                height: 40px;
                                justify-content: center;
                                align-items: center;
                            }
                            .txt{
                                margin-left: 10px;
                                p{
                                    margin: 0;
                                    color: #000;
                                    
                                    font-size: 17px;
                                    font-weight: 600;
                                    line-height: 0.9;
                                    padding-top: 4px;
                                }
                                span{
                                    color: #99A399;
                                    
                                    font-size: 14px;
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }
                .roi{
                    position: relative;
                    width: 220px;
                    margin: 0 auto;
                    display: block;
                    margin-top: 35px;
                    p{
                        color: #99A399;
                        
                        font-size: 15px;
                        font-weight: 400;
                        margin: 0;
                        text-transform: uppercase;
                    }
                    h3{
                        margin: 0;
                        color: #5CC159;
                        
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 700;
                    }
                    svg{
                        width: 220px;
                        height: fit-content;
                    }
                    .recharts-responsive-container{
                        height: 110px !important;
                        border-radius: 3px;
                        // overflow: hidden;
                        position: relative;
                        // &:after{
                        //     content: "";
                        //     position: absolute;
                        //     top: -1px;
                        //     right: -4px;
                        //     width: 12px;
                        //     height: 12px;
                        //     background-color: #5CC159;
                        //     border-radius: 50%;
                        // }
                    }
                }
            }
        }
    }
    // Subscriptions
    .subscriptions{
        margin-top: 90px;
        padding-top: 50px;
        .plans{
            margin-top: 50px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 35px;
            .plan{
                border-radius: 15px;
                border: 2px solid #DAE7DA;
                background: #FFF;
                padding: 25px;
                position: relative;
                overflow: hidden;
                transition: all .2s;
                &:hover{
                    border-color: #2AC533;
                }
                &:nth-of-type(2){
                    border: 1.142px solid #DAE7DA;
                    background: #5CC159;
                    color: #FFF;
                    .top_sec-plan{
                        h4{
                            color: #FFF;
                        }
                        h5{
                            margin: 0;
                            color: #FFF;
                            
                            font-size: 14px;
                            font-weight: 500;
                            width: fit-content;
                            position: absolute;
                            top: 28px;
                            left: 137px;
                            svg{
                                position: relative;
                                top: -1px;
                                margin-right: 5px;
                            }
                        }
                        h3{
                            color: #FFF;
                            span{
                                color: #FFF;
                            }
                        }
                    }
                    .list{
                        li{
                            position: relative;
                            .icon{
                                background: rgb(255 255 255 / 33%);
                                svg path{
                                    fill: #FFF;
                                }
                            }
                            p{
                                color: #FFF;
                            }
                        }
                    }
                    a{
                        color: #5CC159;
                        background-color: #FFF;
                        &:hover{
                            background-color: #FFF;
                            color: #5CC159;
                        }
                    }
                    img{
                        position: absolute;
                        top: -190px;
                        right: -190px;
                    }
                }
                .top_sec-plan{
                    border-bottom: 2px solid #EBF4EB;
                    padding-bottom: 20px;
                    h4{
                        color: #99A399;
                        
                        font-size: 20px;
                        font-weight: 500;
                    }
                    h3{
                        color: #000;
                        
                        font-size: 33px;
                        font-weight: 700;
                        margin-top: 15px;
                        margin-bottom: 0;
                        span{
                            color: #99A399;
                            font-size: 16px;
                            font-weight: 500;
                            margin-left: -5px;
                        }
                    }
                    p{
                        color: #99A399;
                        
                        font-size: 13px;
                        font-weight: 400;
                        margin-top: 10px;
                        margin-bottom: 0;
                    }
                }
                .list{
                    margin: 0;
                    padding: 30px 0 60px;
                    width: 100%;
                    li{
                        display: flex;
                        margin-bottom: 20px;
                        position: relative;
                        .icon{
                            position: absolute;
                            width: 21px;
                            height: 21px;
                            border-radius: 50%;
                            background: rgba(92, 193, 89, 0.4392156863);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            top: 1px;
                            left: 0;
                            svg{
                                width: 12px;
                                height: 13px;
                            }
                        }
                        p{
                            margin: 0;
                            color: #000;
                            
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 1.3;
                            padding-left: 27px;
                        }
                    }
                }
                a{
                    color: #FFF;
                    padding: 9px 0;
                    font-size: 16px;
                    width: 90%;
                    display: flex;
                    justify-content: center;
                    border-radius: 7px;
                    background: #5CC159;
                    
                    transition: all 0.2s;
                    position: absolute;
                    bottom: 25px;
                    left: 50%;
                    transform: translateX(-50%);
                    &:hover{
                        background-color: #2AC533;
                    }
                }
            }
        }
    }
    // Questions
    .questions{
        margin-top: 90px;
        padding-top: 50px;
        .questions_list{
            width: 900px;
            margin: 50px auto 0 auto;
            user-select: none;
            .item{
                width: 100%;
                padding: 15px;
                border-radius: 15px;
                border: 2px solid #5CC159;
                margin-bottom: 20px;
                &.open{
                    .qs{
                        .arrow{
                            background: rgba(92, 193, 89, 0.4392156863);
                            svg{
                                transform: rotate(0deg);
                                path{
                                    fill: #5CC159;
                                }
                            }
                        } 
                        p{
                            color: #2AC533;
                        }
                    }
                }
                .qs{
                    display: flex;
                    position: relative;
                    cursor: pointer;
                    &:hover{
                        .arrow{
                            background: rgba(92, 193, 89, 0.4392156863);
                            svg path{
                                fill: #5CC159;
                            }
                        } 
                        p{
                            color: #2AC533;
                        }
                    } 
                    .icon{
                        background: linear-gradient(93deg, #5CC159 0%, #80E67D 100%);
                        border-radius: 50%;
                        width: 35px;
                        height: 35px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        left: 0;
                        top: -4px;
                        svg{
                            width: 18px;
                            height: 18px;
                        }
                    }
                    p{
                        margin: 0;
                        color: #000;
                        
                        font-size: 18px;
                        font-weight: 600;
                        margin-top: 0;
                        transition: all 0.2s;
                        padding-right: 33px;
                        padding-left: 46px;
                    }
                    .arrow{
                        width: 27px;
                        height: 27px;
                        border-radius: 50%;
                        background-color: #b9cdb85e;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        right: 0;
                        top: 4px;
                        transition: all .2s;
                        svg{
                            width: 13px;
                            height: 13px;
                            transform: rotate(180deg);
                            position: relative;
                            transition: all .2s;
                            path{
                                fill: #B9CDB8;
                                transition: all .2s;
                            }
                        }
                    }
                }
                .answr{
                    margin: 21px 0 10px;
                    color: #949F94;
                    
                    font-size: 17px;
                    font-weight: 400;
                    display: none;
                }
                &.QsOpen{
                    .qs{
                        p{
                            color: #2AC533;
                        }
                        .arrow{
                            background: rgba(92, 193, 89, 0.4392156863);
                            svg{
                                transform: rotate(360deg);
                                path{
                                    fill: #5CC159;
                                }
                            } 
                        } 
                    }
                    .answr{
                        display: block;
                    }
                }
            }
        }
    }
    // Testimonials
    .testimonials{
        margin-top: 90px;
        padding-top: 50px;
        .items{
            margin-top: 50px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 35px;
            .item{
                border-radius: 15px;
                border: 2px solid #DAE7DA;
                background: #FFF;
                padding: 25px 20px;
                position: relative;
                transition: all .2s;
                &:hover{
                    border-color: #5CC159;
                }
                .profile{
                    display: flex;
                    img{
                        width: 65px;
                        height: 65px;
                    }
                    .info{
                        margin-left: 10px;
                        padding: 8px 0;
                        h3{
                            color: #000;
                            
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                            margin: 0 0px 5px;
                        }
                        .rating{
                            display: flex;
                            svg{
                                width: 15px;
                                height: 15px;
                                margin-right: 4px;
                            }
                        }
                    }
                }
                .text{
                    margin-top: 20px;
                    overflow: hidden;
                    p{
                        color: #616F61;
                        
                        font-size: 16px;
                        font-weight: 400;
                        line-height: normal;
                        margin: 0;
                    }
                    svg{
                        width: 30px;
                        height: 30px;
                        &:first-of-type{
                            margin-bottom: 10px;
                        }
                        &:last-of-type{
                            float: right;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
    // Referral
    .referral{
        width: 100%;
        padding: 50px 20px;
        border-radius: 30px;
        background: #E9EFE9;
        text-align: center;
        margin-top: 150px;
        position: relative;
        overflow: hidden;
        h1{
            text-align: center;
            color: #000;
            
            font-size: 35px;
            font-weight: bold;
            span{
                color: #5CC159;
            }
        }
        p{
            text-align: center;
            
            font-size: 16px;
            font-style: normal;
            margin: 0 auto;
            position: relative;
            z-index: 3;
            color: #687068;
            font-weight: 500;
        }
        a{
            padding: 15px 49px;
            border-radius: 4px;
            transition: all 0.2s;
            width: fit-content;
            color: #FFF;
            
            font-size: 15px;
            font-weight: 500;
            background: #2AC533;
            margin: 0 auto;
            position: relative;
            z-index: 3;
            display: block;
            margin-top: 35px;
            &:hover{
                background-color: #5CC159;
            }
        }
        img{
            position: absolute;
            right: 0;
            z-index: 2;
            bottom: 0;
        }
    }
    // Footer
    footer{
        width: 100%;
        margin-top: 150px;
        background: #5CC159;
        padding: 40px 0 20px 0;
        ul{
            padding-bottom: 40px;
            margin: 0;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            li{
                a{
                    color: #FFF;
                    text-align: center;
                    font-family: 'Inter';
                    font-size: 17px;
                    font-weight: 400;
                }
            }
        }
        .lst_prt{
            width: 100%;
            padding-top: 40px;
            position: relative;
            &:after{
                content: "";
                position: absolute;
                top: 0;
                left: 50%;
                width: 90%;
                transform: translateX(-50%);
                background: rgba(255, 255, 255, 0.20);
                height: 1px;
            }
            .social{
                display: flex;
                justify-content: center;
                a{
                    border-radius: 14.917px;
                    border: 2.131px solid rgba(255, 255, 255, 0.15);
                    background: rgba(255, 255, 255, 0.1);
                    backdrop-filter: blur(10.6548271179px);
                    width: 40px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: all .2s;
                    &:hover{
                        background-color: #2AC533;
                    }
                    &:not(:last-of-type){
                        margin-right: 20px;
                    }
                    svg{
                        width: 22px;
                        height: 22px;
                    }
                }
            }
            h3{
                text-align: center;
                margin: 0;
                color: #FFF;
                font-family: 'Inter';
                font-size: 18px;
                font-weight: 400;
                margin-top: 20px;
            }
        }
    }
    // Scroll To top
    .scroll_top{
        position: fixed;
        bottom: 20px;
        right: 20px;
        width: 60px;
        height: 60px;
        z-index: 9999999;
        background-color: #2AC533;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        display: none;
        border: 2px solid #FFF;
        &.active{
            display: flex;
        }
    }
    // Media
    @media (max-width: 1400px){
        header{
            nav .right_section .links {
                margin: 0 20px 0 0;
            }
            .header_content .head_sec{
                .lef-sec{
                    width: 570px;
                    p{
                        font-size: 15px;
                    }
                    h1 {
                        font-size: 55px;
                    }
                    h4{
                        font-size: 14px;
                    }
                    a{
                        padding: 15px 50px;
                        font-size: 14px;
                    }
                } 
                .dash_img{
                    padding: 15px;
                    img {
                        width: 460px;
                        height: 320px;
                    }
                } 
            } 
        } 
    }
    @media (max-width: 1200px){
        header{
            nav{
                .menu{
                    display: block;
                }
                .right_section{
                    .links{
                        background-color: #FFF;
                        position: absolute;
                        display: block;
                        width: 185px;
                        right: -35px;
                        margin: 0;
                        top: 65px;
                        padding: 7px 0;
                        border-radius: 3px;
                        box-shadow: 0 2px 5px 2px rgba(43, 82, 61, 0.2901960784);
                        visibility: hidden;
                        opacity: 0;
                        transition: all .2s;
                        &.active-menu{
                            visibility: visible;
                            opacity: 1;
                        }
                        li {
                            margin: 0 !important;
                            a{
                                display: flex;
                                padding: 5px 10px;
                            }
                        }
                    }
                    .lang{
                        margin: 26px 20px 0 0;
                        .top p{
                            display: none;
                        }
                    }
                }
            } 
            .header_content{
                padding-top: 50px;
                padding-bottom: 90px;
                .head_sec{
                    display: flex;
                    flex-wrap: wrap;
                    .lef-sec{
                        width: 100%;
                        margin: 0;
                        text-align: center;
                        p{
                            font-size: 15px;
                            margin: 0 auto;
                        }
                        h1 {
                            font-size: 55px;
                        }
                        h4{
                            font-size: 14px;
                        }
                        a{
                            padding: 15px 50px;
                            font-size: 14px;
                            margin: 40px auto;
                        }
                    } 
                    .dash_img{
                        padding: 15px;
                        position: relative;
                        margin: 0 auto;
                        margin-top: 40px;
                        img {
                            width: 460px;
                            height: 320px;
                            @keyframes mover {
                                0% { transform: rotate(0deg) translateY(0); }
                                100% { transform: rotate(0deg) translateY(-10px); }
                            }
                        }
                    } 
                }
            } 
        } 
    }
    @media (max-width: 991px){
        .top_sec {
            h1 {
                font-size: 35px;
            }
            p{
                font-size: 13px;
                width: 100%;
            }
        }
        .features .features_items {
            grid-template-columns: repeat(2, 1fr);
        }
        .top_traders .items {
            grid-template-columns: repeat(1, 1fr);
        }
        .subscriptions .plans {
            grid-template-columns: repeat(1, 1fr);
        }
        .questions .questions_list {
            width: 100%;
        }
        .testimonials .items {
            grid-template-columns: repeat(1, 1fr);
        }
    }
    @media (max-width: 767px){
        nav{
            .right_section{
                .links{
                    padding-bottom: 0 !important;
                    li {
                        &:last-child, &:nth-of-type(7){
                            display: block !important;
                        }
                        &:last-child{
                            a{
                                width: 100%;
                                color: #FFF;
                                background: #2AC533;
                                justify-content: center;
                                padding: 12px 0;
                                border-radius: 0;
                                font-size: 16px;
                                margin-top: 5px;
                                &:hover{
                                    background: #5CC159;
                                    color: #FFF;
                                }
                            }
                        }
                    }
                }
                .btns{
                    display: none;
                }
            }
        } 
        .top_sec {
            h1 {
                font-size: 35px;
            }
            p{
                padding: 0 15px;
            }
        }
        .features .features_items {
            grid-template-columns: repeat(1, 1fr);
        }
        header .header_content {
            padding-top: 40px;
            padding-bottom: 70px;
            .head_sec .dash_img img {
                width: 380px;
                height: 280px;
            }
            .head_sec .lef-sec h1 {
                font-size: 50px;
            }
            .head_sec .lef-sec p {
                font-size: 13px;
            }
        }
        footer ul {
            display: block;
            width: 130px;
            margin: 0 auto;
            li{
                width: 100%;
                &:not(:last-of-type){
                    margin-bottom: 5px;
                }
                a{
                    display: flex;
                    width: 100%;
                    justify-content: center;
                }
            }
        }
        nav .right_section .lang .down_list {
            left: -39px;
        }
        .scroll_top {
            width: 50px;
            height: 50px;
            svg{
                width: 20px;
                height: 20px;
            }
        }
    }
    @media (max-width: 530px){
        header .header_content .head_sec{
            .lef-sec p {
                width: 100%;
                padding: 0 20px;
            }
            .dash_img img {
                width: 300px;
                height: 215px;
            }
        } 
    }
}